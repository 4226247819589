import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";

import Buttons from "../../../../components/buttons";
import Badge from "../../../../components/badge";

import IconPrint from "../../../../assets/icons/Print.svg";
import Photo from "../../../../assets/images/Photo.png";
import IconEdit from "../../../../assets/icons/edit.svg";
import IconSMS from "../../../../assets/icons/sms.svg";
import IconWhatsapp from "../../../../assets/icons/whatsapp.svg";

import IconBulkSms from "assets/icons/bulk-sms.svg"
import IconBulkWA from "assets/icons/bulk-wa.svg"
import FollowUpSender from "assets/icons/followup-sender.svg";

import { handleSendFollowup } from "utils/api/booking";

import { handleGetDetailOrder } from 'stores/actions/booking'

import moment from "moment";
import Avatar from "components/avatar";
import { IDRFormater, toCapital, toCurrencyFormat } from "utils/helpers";
import { Edit, Eye, InfoCircle, Trash } from "iconsax-react";
import ArrowLeft from 'assets/icons/arrow-left.svg'
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import EditPesan from "pages/tagihan/components/EditPesan";
import Popup from "components/popup";

import IconSistemMaroon from 'assets/icons/icon-sistem-maroon.svg'
import IconAdminMaroon from 'assets/icons/icon-admin-maroon.svg'

const CardDetailTagihanHeader = ({
  handleUpdateDiscount,
  handleNotFound
}) => {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;


  const { detailData } = useSelector((state) => state.tagihan);

  const { bookingDetail } = useSelector((state) => state.booking);

  const [openCancelReason, setOpenCancelReason] = useState(false)
  const [cancelReasonInfo, setCancelReasonInfo] = useState("")

  const data = detailData.data;

  const [openPopupEditPesan, setOpenPopupEditPesan] = useState(false);

  const [sendingFollowup, setSendingFollowup] = useState({
    loading: false,
    key: null
  })

  const handleClickFollowup = async (key) => {
    setSendingFollowup({
      loading: true,
      key: key
    })
    const res = await handleSendFollowup(params.orderNumber, key)
    setSendingFollowup({
      loading: false,
      key: null
    })

    if (res.status === 200) {
      dispatch(handleSuccess(res))
    } else {
      dispatch(handleError(res.data))
    }

  }

  const orderDiscount = () => {
    if(bookingDetail?.jenis_diskon === 1) {
      return bookingDetail?.nominal_diskon
    }

    if(bookingDetail?.jenis_diskon === 2) {
      return (parseInt(bookingDetail?.nominal_diskon) / 100) * parseInt(bookingDetail?.total_price)
    }

    return 0;

  }

  useEffect(() => {
    dispatch(handleGetDetailOrder(params.orderNumber, () => handleNotFound()))
  }, [])


  
  return (
    <>
      <Popup
        open={openPopupEditPesan}
        handleClose={() => setOpenPopupEditPesan(false)}
        title="Edit Pesan Follow Up"
        width="1080px"
        children={<EditPesan setOpenPopupEditPesan={setOpenPopupEditPesan} />}
      />

      <Popup
        open={openCancelReason}
        handleClose={() => {
          setCancelReasonInfo('')
          setOpenCancelReason(false)
        }}
        title={`Alasan dibatalkan`}
        width="550px"
        containerClass=""
        children={
          <>
            <div className="p-4 border rounded-2xl">
              {cancelReasonInfo}
            </div>
          </>
        }
          
        />

    <div className="card-detail-tagihan-header">
      <Grid container spacing={2} alignItems="center">
        <Grid item md={10} className="flex flex-row items-center !text-base">
          <div className="card-detail-tagihan-header-code !text-base flex items-center gap-4">
            <div className="cursor-pointer" onClick={() => window.history.back()}>
              <img 
                src={ArrowLeft}
                />
            </div>
            <div>
              #{bookingDetail?.order_number}
            </div>
          </div>
          <div className="card-detail-tagihan-header-divider"></div>
          <div className="!text-base">
            {moment(bookingDetail?.created_at).format("dddd, DD MMMM YYYY")} <span>{moment(bookingDetail?.created_at).format('H:mm')} WIB</span>
          </div>
          <Buttons
            text="Lihat Data Booking"
            customClass="btn-outline btn-small ml-4"
            onClick={() => history.push(`/booking/detail/${params.orderNumber}`)}
            // onClick={() => setOpenPopupAdditional(true)}
          />
        </Grid>

        <Grid item md={2} className="flex justify-end">
          <>
            {bookingDetail.name_payment_status === 'Belum DP' && (
              <Badge 
                backgroundColor={'#F7A399'}
                color={"#FE0000"}
                label={bookingDetail.name_payment_status.toUpperCase()} />
            )}
            {bookingDetail.name_payment_status === 'Sudah DP' && (
              <Badge 
                backgroundColor={'#BBDEFB'}
                color={"#0000FE"}
                label={`SUDAH DP`} />
            )}
            {bookingDetail.name_payment_status === 'Belum Lunas' && (
              <Badge 
                backgroundColor={'#FEDCED'}
                color={"#F20089"}
                label={`BELUM LUNAS`} />
            )}
            {bookingDetail.name_payment_status === 'Lunas' && (
              <Badge 
                backgroundColor={'#92E6A7'}
                color={"#1A7431"}
                label={bookingDetail.name_payment_status.toUpperCase()} />
            )}
            {bookingDetail.name_payment_status === 'Di Batalkan' && (
              <>
                <Badge 
                  backgroundColor={'#CED4DA'}
                  color={"#6C757D"}
                  label={<>
                    <div className="flex justify-center items-center gap-2">
                      <span>
                        {`DIBATALKAN`}
                      </span>
                      <div>
                        {bookingDetail.cancel_by > 0 ? <img src={IconAdminMaroon} /> : <img src={IconSistemMaroon} />}
                      </div>
                    </div>
                  </>} />
                  
                  <Buttons 
                    text={
                      <>
                        <InfoCircle size={16} />
                      </>
                    } 
                    onClick={() => {
                      setOpenCancelReason(true)
                      setCancelReasonInfo(bookingDetail.cancel_reason)
                    }}
                    customClass="btn-secondary btn-small !py-[6px] !px-[10px] ml-2" />
              </>
            )}
          </>
        </Grid>

      </Grid>

      <div className="flex gap-3 mt-4">
          <div className="card-detail-tagihan-header-green !py-7 !px-4 w-[40%]">
              <div className="flex gap-3 items-center">
                <div>
                  <Avatar
                    value={bookingDetail?.pemesan}
                    containerClass={`items-center justify-center  w-[56px] h-[56px] rounded-[100%]  bg-slate-100`}
                    customClass="object-cover w-[56px] h-[56px] rounded-[100%]"
                    />
                </div>
                <div>
                  <div className="text-white text-xl mb-2">
                    {bookingDetail?.pemesan?.title ? `${toCapital(bookingDetail?.pemesan?.title)}. ` : ``}{toCapital(bookingDetail?.pemesan?.name)}
                  </div>
                  <div className="flex gap-2">
                    <Badge 
                      backgroundColor="rgba(255, 255, 255, 0.50)"
                      color={bookingDetail?.pemesan?.color_text_role}
                      label={bookingDetail?.pemesan?.nama_role ?? bookingDetail?.pemesan?.role_name} 
                      />
                    <Badge 
                      backgroundColor="rgba(255, 255, 255, 0.50)"
                      color={bookingDetail?.pemesan?.color_text_cabang}
                      label={bookingDetail?.pemesan?.cabang_name ? bookingDetail?.pemesan?.cabang_name?.toUpperCase() : bookingDetail?.pemesan?.nama_cabang?.toUpperCase()}
                      />
                  </div>
                </div>
              </div>
          </div>
          <div className="card-detail-tagihan-header-green !py-[30px] !px-4 w-[30%] text-white">
              <div className="text-sm mb-2">
                Paket
              </div>
              <div className="text-base font-semibold">
                {bookingDetail?.paket?.judul_paket}
              </div>
          </div>
          <div className="card-detail-tagihan-header-green !p-4 w-[30%] text-base text-white">
            <div className="font-semibold">
              Catatan :
            </div>
            <div>
              -
            </div>
          </div>
      </div>
      <div className="flex gap-3 mt-3">
        <div className="card-detail-tagihan-header-green !py-[30px] !px-4 w-[70%] text-white text-base mb-4">
          <div className="mb-4">
            Rincian Tagihan :
          </div>
          <div className="flex justify-between w-[90%]">
              <div>
                <div className="mb-1">Total Biaya :</div>
                <div className="text-xl font-semibold">
                  {toCurrencyFormat(bookingDetail?.total_price, bookingDetail?.currency, false)}
                </div>
              </div>
              <div>
                <div className="mb-1">Total Diskon :</div>
                <div className="text-xl font-semibold flex items-center gap-4">
                  <span>
                    {IDRFormater(bookingDetail?.total_discount)}
                  </span>
                  {permissions?.includes('update_bill') && bookingDetail?.payment_status !== 4 && bookingDetail?.with_discount_per_order == 1 && (
                    <div className="cursor-pointer" onClick={() => handleUpdateDiscount()}>
                      <Edit
                        size={16}
                        color="white"
                        />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="mb-1">Sudah Dibayarkan :</div>
                <div className="text-xl font-semibold">
                  {IDRFormater(bookingDetail?.paid_amount)}
                </div>
              </div>
              <div>
                <div className="mb-1">Kekurangan :</div>
                <div className="text-xl font-semibold ">
                  {toCurrencyFormat(bookingDetail?.remaining_payment_amount, bookingDetail?.currency, false)}
                </div>
              </div>
          </div>
        </div>
        <div className="card-detail-tagihan-header-green !py-[30px] !px-4 w-[30%] text-white text-base mb-4">
          <div className="flex justify-between">
            <div className="">
              <div className="text-sm">Follow Up</div>
              <div className="text-base font-semibold">
                {toCapital(bookingDetail?.pemesan?.name)}
              </div>
            </div>
            <div>
              {auth?.user?.access_data === 'all_branches' && (
                <>
                  {permissions.includes('update_bill') && bookingDetail?.payment_status !== 4 && (
                    <>
                      <div 
                        onClick={() => setOpenPopupEditPesan(true)}
                        className="bg-white shadow-hard p-2 rounded-lg cursor-pointer">
                        <Edit
                          size={16}
                          color="black"
                          // onClick={() => setOpenPopupEditPesan(true)}
                          />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="mt-3 flex gap-3 items-center">
            <div className="text-xs flex gap-2 items-center">
              <img src={IconBulkSms} />
              <span className="font-semibold">
                {bookingDetail?.pemesan?.email}
              </span>
            </div>
            <div className="text-xs flex gap-1 items-center">
              <img src={IconBulkWA} />
              <span className="font-semibold">
              {bookingDetail?.pemesan?.phone}
              </span>
            </div>
          </div>
          <div className="flex gap-1 mt-2">
              <Buttons
                text="1"
                customClass="btn-white-light btn-small !w-fit"
                disabled={(bookingDetail.payment_status !== 0 || bookingDetail.payment_status === 4) || !bookingDetail.payment_dp}
                icon={FollowUpSender}
                loadingIcon={true}
                onClick={() => handleClickFollowup('dp')}
                loading={sendingFollowup.loading && sendingFollowup.key === 'dp'}
              />
              <Buttons
                text="2"
                customClass="btn-white-light btn-small !w-fit ml-1"
                // disabled={bookingDetail.payment_status == 4 ? true : false}
                icon={FollowUpSender}
                loadingIcon={true}
                disabled={(bookingDetail.payment_status === 0 || bookingDetail.payment_status === 4 || bookingDetail.payment_status === 3)}
                onClick={() => handleClickFollowup('payment')}
                loading={sendingFollowup.loading && sendingFollowup.key === 'payment'}
              /> 
          </div>
        </div>
      </div>
      
    </div>
    </>
  );
};

export default CardDetailTagihanHeader;
