import React from "react";
import PropType from "prop-types";
import CurrencyInput from "react-currency-input-field";

import InputsCurrencyStyle from "./InputsCurrency.style";

import Close from "assets/icons/Close.svg";
import Check from "assets/icons/Check.svg";

const InputsCurrency = ({
  containerClass,
  customClass,
  id,
  name,
  label,
  placeholder,
  value,
  intlConfig,
  maxLength,
  success,
  error,
  disabled,
  onChange,
  onBlur,
  defaultValue,
  prefix,
  spanCustomeClass,
  customLabel,
  currency
}) => {
  const currencyPrefix = currency === "USD" ? "$" : "Rp";
  return (
    <InputsCurrencyStyle className={`${containerClass}`}>
      {label !== "" && (
        <div
          className={`input-label ${
            disabled && "input-label--disabled"
          } ${customLabel} !text-sm`}
        >
          {label}
        </div>
      )}

      <div className={`relative ${error && "border-[#e82320] border rounded-xl"}`}>
        <span
          className={`font-bold absolute left-[16px] top-[15px] text-gray-3 ${spanCustomeClass} `}
        >
          {currencyPrefix}
        </span>
        <CurrencyInput
          id={id}
          name={name}
          defaultValue={defaultValue}
          className={`custom-input-currency text-sm !pl-[50.81px] !ml-0 !py-[17.5px] !pr-4 ${customClass}`}
          placeholder={placeholder}
          decimalsLimit={currency === "USD" ? 2 : 0}
          value={value === "" ? 0 : value}
          onValueChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
          onBlur={onBlur}
          groupSeparator="."
          decimalSeparator=","

        />
      </div>

      {success !== "" && (
        <div className="flex flex-row items-center mt-1">
          <img src={Check} alt="check" />
          <div className="input-success-msg">{success}</div>
        </div>
      )}
      {error !== "" && (
        <div className="flex flex-row items-center mt-1">
          <img src={Close} alt="close" />
          <div className="input-error-msg">{error}</div>
        </div>
      )}
    </InputsCurrencyStyle>
  );
};

InputsCurrency.propTypes = {
  containerClass: PropType.string,
  customClass: PropType.string,
  id: PropType.string.isRequired,
  defaultValue: PropType.number,
  name: PropType.string,
  intlConfig: PropType.object,
  label: PropType.string,
  placeholder: PropType.string,
  maxLength: PropType.number,
  success: PropType.string,
  error: PropType.string,
  disabled: PropType.bool,
  onChange: PropType.func,
  onBlur: PropType.func,
  prefix: PropType.string,
  spanCustomeClass: PropType.string,
  customLabel: PropType.string,
  currency: PropType.string
};

InputsCurrency.defaultProps = {
  containerClass: "",
  customClass: "",
  id: "",
  name: "",
  intlConfig: { locale: "id-ID", currency: "IDR" },
  label: "",
  placeholder: "",
  maxLength: 20,
  success: "",
  error: "",
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
  defaultValue: 0,
  prefix: "Rp",
  spanCustomeClass: "",
  customLabel: "text-gray-3",
  currency: "IDR"
};

export default React.memo(InputsCurrency);
