import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import Radio from "components/form/radio";
import Popup from "components/popup";
import Inputs from "components/form/inputs";
import Buttons from "components/buttons";
import DateRangePicker from "components/datepicker/DateRangePicker";
import InputsCurrency from "components/form/inputsCurrency";

import { Eye, EyeSlash, Money, PercentageSquare } from "iconsax-react";
import IconBrush from "assets/icons/brush.svg";
import IconPercent from "assets/icons/Persen.svg";
import Close from "assets/icons/Close.svg";
import PaketCouponAction from "stores/actions/paket/coupon";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";

function Create({ show, close, edit, getAllPaketCoupon }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoadingAction, setIsloadingAction] = useState(false);

  const packageData = useSelector(({ generalInfo }) => generalInfo);

  const [type, setType] = useState([
    {
      value: (
        <div className="flex items-center">
          <Eye variant="Bold" className="mr-2" /> <p>Public</p>
        </div>
      ),
      label: "Public",
      key: 1,
    },
    {
      value: (
        <div className="flex items-center">
          <EyeSlash variant="Bold" className="mr-2" /> <p>Private</p>
        </div>
      ),
      label: "Private",
      key: 2,
    },
  ]);
  const [discount, setDiscount] = useState([
    {
      value: (
        <div className="flex items-center">
          <Money variant="Bold" className="mr-2" /> <p>Nominal (Rp)</p>
        </div>
      ),
      label: "Nominal",
      key: 1,
    },
    {
      value: (
        <div className="flex items-center">
          <PercentageSquare variant="Bold" className="mr-2" /> <p>Persen (%)</p>
        </div>
      ),
      label: "Persen",
      key: 2,
    },
  ]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setValues,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      code_coupon: "",
      tipe_coupon: 1,
      jenis_diskon: 1,
      nominal_diskon: "",
      date: [],
    },
    validationSchema: Yup.object().shape({
      code_coupon: Yup.string().required("Kode Coupon Wajib diisi"),
      nominal_diskon: Yup.string().required("Tipe Wajib Diisi"),
      date: Yup.array()
        .of(Yup.string())
        .min(1, "Jangka Waktu Wajib dipilih.")
        .required("Jangka Waktu Wajib dipilih."),
    }),
    onSubmit: async (val, action) => {
      setIsloadingAction(true);
      let payload = {
        paket_id: id,
        code_coupon: val.code_coupon,
        jenis_diskon: val.jenis_diskon,
        nominal_diskon: val.nominal_diskon,
        tipe_coupon: val.tipe_coupon,
        tanggal_berlaku: val.date[0],
        tanggal_berakhir: val.date[1],
        edit: edit ? true : false,
      };
      if (edit) {
        payload.id = edit.id_coupon;
      }

      dispatch(
        PaketCouponAction.postData(
          payload,
          (message) => {
            setIsloadingAction(false);
            action.resetForm();
            close({
              show: false,
              edit: null,
            });

            getAllPaketCoupon();
            dispatch(handleSuccess(message));
          },
          (error) => {
            setIsloadingAction(false);
            dispatch(handleErrorBE(error));
          }
        )
      );
    },
  });

  useEffect(() => {
    if (edit !== null) {
      setValues({
        code_coupon: edit?.code_coupon,
        jenis_diskon: edit?.jenis_diskon,
        nominal_diskon: edit?.nominal_diskon,
        tipe_coupon: edit?.tipe_coupon,
        date: [edit?.tanggal_berlaku, edit?.tanggal_berakhir],
      });
    } else {
      setValues({
        code_coupon: "",
        jenis_diskon: 1,
        nominal_diskon: "",
        tipe_coupon: 1,
        date: [null, null],
      });
    }
  }, [edit]);

  return (
    <Popup
      open={show}
      handleClose={close}
      title={` ${edit ? "Edit Coupun" : "Tambah Coupon"}`}
      width="1072px"
    >
      <div className="border border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <div className="flex flex-col gap-2">
            <div className="text-[#4F4F4F] text-sm font-semibold">Tipe</div>
            <Radio
              options={type}
              icon={IconBrush}
              defaultValue={
                edit ? type[edit?.tipe_coupon - 1].value : type[0].value
              }
              onChange={(event) => {
                const selectedType = type.find(
                  (option) => option.value === event.target.value
                );
                
                if (selectedType) {
                  setFieldValue("tipe_coupon", selectedType.key);
                }
              }}
            />
          </div>
          <div className="flex w-full gap-4 items-center">
            <Inputs
              containerClass="mt-4 !w-1/2"
              label="Kode Kupon"
              placeholder="Buat kode"
              id="code_coupon"
              name="code_coupon"
              value={values.code_coupon}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.code_coupon && errors.code_coupon && errors.code_coupon
              }
              customClass={
                touched.code_coupon && errors.code_coupon && "input-error"
              }
            />
            <div className="mt-[16px] flex flex-col gap-[8px] w-1/2">
              <div className="text-[#4F4F4F] text-sm font-semibold">Jangka Waktu</div>
              <DateRangePicker
                  containerClass="!w-full"
                  customClass="!w-full"
                  value={values.date}
                  placeholder="Dari"
                  placeholder2={"Sampai"}
                  onChange={(date) => {
                    setFieldValue("date", date);
                  }}
                  onBlur={(e) => {
                    setFieldTouched({
                      ...touched,
                      date: true,
                    })
                  }}
                  errors={
                    touched.date && errors.date}
                />
            </div>
          </div>
          <div className="flex flex-col gap-2 border-2 border-dashed border-[#E0E0E0] rounded-2xl p-4 mt-4">
            <div className="text-[#4F4F4F] text-sm font-semibold">Tipe</div>
            <Radio
              options={discount}
              icon={IconBrush}
              defaultValue={
                edit
                  ? discount[edit?.jenis_diskon - 1].value
                  : discount[0].value
              }
              onChange={(event) => {
                const selectedDiscount = discount.find(
                  (option) => option.value === event.target.value
                );
                if (selectedDiscount) {
                  setFieldValue("jenis_diskon", selectedDiscount.key);
                  setFieldValue("nominal_diskon", "");
                }
              }}
            />
            {values.jenis_diskon === 2 ? (
              <Inputs
                id="nominal_diskon"
                name="nominal_diskon"
                containerClass="mt-1"
                placeholder="Masukan Persen"
                value={values.nominal_diskon}
                onChange={handleChange}
                icon={IconPercent}
                iconPosition="right"
                onBlur={handleBlur}
                error={
                  touched.nominal_diskon &&
                  errors.nominal_diskon &&
                  errors.nominal_diskon
                }
                customClass={
                  touched.nominal_diskon &&
                  errors.nominal_diskon &&
                  "input-error"
                }
              />
            ) : (
              <InputsCurrency
                containerClass="mt-1"
                currency={packageData?.data?.currency}
                placeholder="Masukan Nominal"
                id="nominal_diskon"
                name="nominal_diskon"
                value={values.nominal_diskon}
                onChange={(e) => {
                  setFieldValue("nominal_diskon", e);
                }}
                onBlur={handleBlur}
                error={
                  touched.nominal_diskon &&
                  errors.nominal_diskon &&
                  errors.nominal_diskon
                }
                // customClass={
                //   touched.nominal_diskon &&
                //   errors.nominal_diskon &&
                //   "input-error"
                // }
              />
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end p-4">
          <Buttons
            text={`Simpan`}
            loading={isLoadingAction}
            customClass={`btn-primary btn-large !w-fit ${
              isSubmitting && "loading"
            }`}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Popup>
  );
}

Create.propTypes = {
  show: PropType.bool,
  close: PropType.func,
  edit: PropType.object,
  getAllPaketCoupon: PropType.func,
};

export default React.memo(Create);
