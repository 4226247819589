import { useFormik } from "formik";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom";

import * as Yup from "yup";

import Selects from "components/form/selects";
import Switch from "components/form/switch";
import InputsCurrency from "components/form/inputsCurrency";
import Buttons from "components/buttons";

import { saveAdditionalPaket } from "stores/actions/paket/additional";
import { IDRToUSD, toCurrencyFormat } from "utils/helpers";

const CreateEdit = ({ data, additionalOptions, open, handleSuccess }) => {
    
    const dispatch = useDispatch()
    const params = useParams()
    const [isLoading, setIsLoading] = useState(false)

    const store = useSelector(({ generalInfo }) => generalInfo);
    const { exchangeRate } = useSelector(({ exchangeRateUSD }) => exchangeRateUSD);

    const formik = useFormik({
        initialValues: {
            id_additional: '',
            price: '',
            is_wajib: "0",
            description: ''
        },
        validationSchema: Yup.object().shape({
            id_additional: Yup.string().required("Wajib memilih additional"),
            price: Yup.string().required("Harga wajib diisi"),
        })
    })
    
    useEffect(() => {
        formik.setFieldValue('id_additional', data?.id_additional)
        formik.setFieldValue('price', data?.price)
        formik.setFieldValue('is_wajib', data?.is_wajib ?? '0')
    }, [])

    useEffect(() => {
      if(!open) {
        setTimeout(() => {
          formik.resetForm()
        }, 500)
      }

    }, [open])

    const handleChangeSwitch = (e) => {
      formik.setFieldValue("is_wajib", e ? "1" : "0");
      formik.setFieldTouched('is_wajib',1,1)
    };

    const handleSave = () => {
      
      let payload = {
        ...formik.values,
        id_paket: params.id
      }
      console.log(payload);
      if(data?.id) {
        payload = {
          ...payload,
          id: data?.id
        }
      }

      setIsLoading(true)
      dispatch(saveAdditionalPaket(payload, 
        (dataSuccess) => {
          setIsLoading(false)
          handleSuccess()
          setTimeout(() => {
            formik.resetForm()
          }, 500)
        },
        (dataError) => {
          setIsLoading(false)
          Object.keys(dataError).forEach((field) => {
            formik.setFieldError(field, dataError[field][0]);
          });
        }
       ))
    }

    return (
        <>
            <div className="border rounded-tl-2xl rounded-tr-2xl border-gray-4 p-4">
                <div>
                    <Selects 
                      label="Pilih Additonal"
                      placeholder="Pilih Additional"
                      id="additonal"
                      disabled={data?.id}
                      options={additionalOptions}
                      values={formik.values?.id_additional ? additionalOptions.filter(
                          (item) => item.value === formik.values?.id_additional
                      ) : []}
                      searchable
                      itemRenderer={({ state, item, methods }) => {
                          return (
                              <div
                                className={`react-dropdown-select-item ${
                                  state.values.length !== 0 &&
                                  state.values[0].value === item.value &&
                                  "react-dropdown-select-item-selected"
                              }`}
                              onClick={() => {
                                  methods.addItem(item);
                                  console.log("ITEM", item)
                                  formik.setFieldValue("id_additional", item.value);
                                  formik.setFieldValue("price", store?.data?.currency === "USD" ? item.default_price_usd : item.default_price_idr);
                              }}
                              >
                              {item.label}
                              </div>
                          );
                      }}
                      error={
                          formik.touched.id_additional &&
                          formik.errors.id_additional 
                      }
                    />
                </div>
                <div className="mt-4 flex gap-2 items-center">
                  <Switch
                    containerClass=""
                    onChange={handleChangeSwitch}
                    checked={formik.values?.is_wajib == 1 ? true : false}
                  />
                  <label className="text-sm">Additional Wajib</label>
                </div>
                <div className="mt-4">
                  <InputsCurrency
                    id="price"
                    label="Harga"
                    placeholder="Masukkan Harga"
                    name="price"
                    currency={store.data?.currency}
                    value={formik.values.price}
                    onBlur={formik.handleBlur}
                    error={formik.touched.price && formik.errors.price}
                    onChange={(val) => {
                      formik.setFieldValue("price", val)
                      formik.setFieldTouched('price',1,1)
                    }}
                  />
                </div>
            </div>
            <div className="border flex justify-end rounded-br-2xl rounded-bl-2xl border-gray-4 border-x border-b border-t-0 p-4">
              <Buttons
                text="Simpan"
                customClass="btn-primary btn-large"
                disabled={!(formik.isValid && formik.dirty)}
                onClick={handleSave}
                loading={isLoading}
                />
            </div>
        </>
    )
}

export default CreateEdit