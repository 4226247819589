import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AntdSelect from "components/form/antdSelect";
import InputsCurrency from "components/form/inputsCurrency";
import Buttons from "components/buttons";

import { Select } from "antd";

// import { getWithdrawBankAccountService } from 'stores/actions/indonesianBanks'

import { withdrawFe, getWithdrawBankAccountService, getWithdrawPackages, getTotalWithdrawFeeService } from 'utils/api/fee'

import Inputs from "components/form/inputs";
import { useFormik } from "formik";

import * as Yup from 'yup'
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import './style.css'
import Badge from "components/badge";
import InputNumber from "components/form/inputNumber";

import moment from "moment";

import { IDRFormater } from "utils/helpers";

import IcFee from "assets/icons/Fee-red.svg";
import Spinner from "components/spinner";

const PopupWithdrawFee = ({
  onSuccess,
  feeType
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState([]);

  const [step, setStep] = useState(1);

  const [packageData, setPackageData] = useState({})
  const [packageOptions, setPackageOptions] = useState([]);
  const [isLoadingPackage, setIsLoadingPackage] = useState(false);

  const [maxTotalPax, setMaxTotalPax] = useState(0);

  const [packagePayload, setPackagePayload] = useState({
    keyword: null,
    per_page: 1000,
    cursor: null
  })

  const [totalWithdrawFee, setTotalWithdrawFee] = useState(0);
  const [isLoadingWithdrawFee, setIsLoadingWithdrawFee] = useState(false);

  const { Option } = Select

  const [optionsCurrency, setOptionsCurrency] = useState([
    {
      value: "idr",
      label: "IDR",
    },
    {
      value: "dollar",
      label: "USD",
    },
  ]);

  const formik = useFormik({
    initialValues: {
      account_bank_id: null,
      package_id: null,
      total_pax: 0,
      is_requiring_fee: false,
      fee_type: feeType
    },
    validationSchema: Yup.object().shape({
      account_bank_id: Yup.string().required("Account Bank wajib diisi."),
      package_id: Yup.string().required("Paket wajib dipilih."),
      total_pax: Yup.number().required("Total Pax wajib diisi."),
      is_requiring_fee: Yup.boolean().required("Is Requiring Fee wajib dipilih."),
      fee_type: Yup.string().required("Fee Type wajib dipilih."),
    })
  });

  const handleWithdrawSubmit = async () => {
    // console.log(formik.values);
    setIsLoading(true);

    const res = await withdrawFe(formik.values);
    if (res.status === 200) {
      setIsLoading(false);
      onSuccess();
      dispatch(handleSuccess(res));
    } else {
      setIsLoading(false);
      dispatch(handleError(res?.data));
    }
  }

  const handleGetAccountBanks = async () => {
    const res = await getWithdrawBankAccountService();
    // console.log(res);
    if (res.status == 200) {
        let _tempAccountBank = []

        _tempAccountBank = res.data.data.map((account) => {
          return { value: account.id, label: `${account.account_bank_name}`, bank_name: account.bank.name, account_name: `${account.account_bank_name}`, account_number: `${account.account_bank_number}` };
        })

        // console.log(_tempAccountBank);

        setOptions(_tempAccountBank)
    }
  }

  const handleParsePackageOptions = (packages) => {
    let _tempPackageOptions = []

    _tempPackageOptions = packages?.map((item) => {
      return {
        value: item.id_paket,
        label: item.judul_paket,
        ...item
      }
    })

    setPackageOptions(_tempPackageOptions)

  }

  const handleGetWithdrawPackages = async () => {
    setIsLoadingPackage(true)
    const res = await getWithdrawPackages(packagePayload, feeType);
    setIsLoadingPackage(false)
    if (res.status == 200) {
      setPackageData(res.data);
      handleParsePackageOptions(res.data.data)
    } else {
      dispatch(handleError(res?.data));
    }
  }

  const handleGetTotalWithdrawFee = async () => {
    const payload = {
      package_id: formik.values.package_id,
      is_requiring_fee: formik.values.is_requiring_fee,
      total_pax: formik.values.total_pax
    }

    setIsLoadingWithdrawFee(true)
    const res = await getTotalWithdrawFeeService(payload, feeType);
    setIsLoadingWithdrawFee(false)
    if (res.status == 200) {
      setTotalWithdrawFee(res.data.data.total_withdraw_fee);
    } else {
      dispatch(handleError(res?.data));
    }
  }

  const handleNextStep = () => {
    setStep(step + 1)
  }

  const handlePrevStep = () => {
    setStep(step - 1)
  }

  useEffect(() => {
    // dispatch(handleGetIndonesianBanks())
    handleGetAccountBanks()
    handleGetWithdrawPackages()
  }, [])

  useEffect(() => {
    if(step == 2) {    
      handleGetTotalWithdrawFee()
      formik.setFieldValue('total_pax', 1)
    }
  }, [step])

  useEffect(() => {
    if(step == 2) {    
      handleGetTotalWithdrawFee()
    }
  }, [formik.values.total_pax])

  return (
    <>
        {step === 1 && (
          <>
            <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="mb-4">
                    <AntdSelect
                      label="Account Bank"
                      placeholder="Pilih Bank"
                      options={options}
                      type={'custom'}
                      defaultValue={formik.values.account_bank_id}
                      onChange={(e) => {
                        formik.setFieldValue("account_bank_id", e?.value)
                      }}
                    >
                      {options.map((option, idx) => (
                        <>
                          <Option key={idx} value={option.value}>
                            <div className="flex justify-between items-center my-[14px] select-account">
                              <div className="text-sm bank-info">
                                <div className="!font-normal">{option.bank_name}</div>
                                <div className="font-semibold mt-1">{option.account_number}</div>
                              </div>
                              <div className="font-semibold label !text-base">
                                {option.account_name}
                              </div>
                            </div>
                          </Option>
                        </>
                      ))}
                    </AntdSelect>
                  </div>
                  <div>
                    <AntdSelect
                      label="Paket"
                      placeholder="Pilih Paket"
                      options={packageOptions}
                      type={'custom'}
                      defaultValue={formik.values.package_id}
                      onChange={(e) => {
                        formik.setFieldValue("package_id", e?.value)
                        formik.setFieldValue("is_requiring_fee", packageOptions.find(option => option.value === e?.value).requiring_fee)
                        setMaxTotalPax(packageOptions.find(option => option.value === e?.value).count_pax)
                      }}
                    >
                      {packageOptions.map((option, idx) => (
                        <>
                          <Option key={idx} value={option.value}>
                              <div className="select-package">
                                <div className="content-dropdown">
                                  <div className="flex border rounded-2xl p-3 gap-4">
                                    <div>
                                        <div>
                                          <img 
                                            className="w-24 h-24 object-cover rounded-2xl"
                                            src={option.image_thumbnail} />
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col gap-2">
                                      <div className="flex justify-between w-full items-center">
                                        <div>
                                          {option?.tipe_paket == 1 && <Badge backgroundColor={"#BBDEFB"} color={"#0000FE"} label="Haji" customClass="mt-[6px]" />}
                                          {option?.tipe_paket == 2 && <Badge backgroundColor="#92E6A7" color="#1B662E" label="Umroh" customClass="mt-[6px]" />}
                                          {option?.tipe_paket == 3 && <Badge backgroundColor={"#F7A399"} color="#FE0000" label="Tour" customClass="mt-[6px]" />}
                                          {option?.tipe_paket == 4 && <Badge label="Tabungan" customClass="mt-[6px]" />}
                                          {option?.tipe_paket == 5 && <Badge label="Jasa Badal" customClass="mt-[6px]" />}
                                        </div>
                                        <div className="font-semibold">
                                          {option.count_pax} Pax
                                        </div>
                                      </div>
                                      <div className="text-lg font-semibold title">
                                        {option.judul_paket}
                                      </div>
                                      <div className="text-sm flex justify-between w-full items-center">
                                        <div>{moment(option.jadwal_keberangkatan).format('LL')}</div>
                                        <div 
                                          className="flex items-center gap-1 bg-white shadow-hard py-[6px] px-2 rounded-[10px] text-xs font-semibold text-red-3">
                                          <img src={IcFee} className="w-4 h-4" />
                                          <div>
                                            {option.requiring_fee ? 'Requiring Fee' : 'Fee'} {IDRFormater(option.fee)}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="content-label">
                                    {option.judul_paket} - {option.count_pax} Pax - {IDRFormater(option.fee)}
                                </div>
                              </div>
                          </Option>
                        </>
                      ))}
                    </AntdSelect>
                  </div>
                  <Buttons 
                    disabled={formik.values.account_bank_id == null || formik.values.package_id == null}
                    text="Withdraw" 
                    customClass="btn-primary btn-large mt-4" 
                    onClick={handleNextStep}
                    loading={isLoading}
                    />
              </div>
          </>
        )}
        {step === 2 && (
          <>
            <div 
              className="py-3 text-sm px-4 bg-gradient-to-r from-[rgba(232,35,32,0.1)] to-[rgba(250,80,77,0.1)] rounded-xl text-red-3">
              Anda dapat mengatur berapa banyak Pax yang akan diambil feenya
            </div>
            <div className="bg-white soft-shadow rounded-2xl p-4 mt-4">
              <div className="flex justify-between w-full items-center mb-6">
                <span className="text-base font-semibold">
                  Jumlah Pax
                </span>
                <div>
                  <InputNumber 
                    min={1}
                    max={maxTotalPax}
                    value={formik.values.total_pax}
                    onChange={(val) => {
                      formik.setFieldValue("total_pax", val)
                    }}
                    />
                </div>
              </div>
              <div className="flex justify-between w-full items-center mb-6">
                <span className="text-base font-semibold">
                  Total Nominal
                </span>
                <span className="text-xl font-semibold green-linear-text">
                    {isLoadingWithdrawFee ? <Spinner /> : IDRFormater(totalWithdrawFee)}
                </span>
              </div>
              <div className="flex w-full gap-4 items-center">
                <Buttons
                  containerClass="w-full"
                  // disabled={formik.values.account_bank_id == null || formik.values.package_id == null}
                  text="Kembali" 
                  customClass="btn-primary btn-outline btn-large mt-4" 
                  onClick={handlePrevStep}
                  />
                <Buttons
                  containerClass="w-full"
                  disabled={!(formik.isValid && formik.dirty) || isLoadingWithdrawFee}
                  text="Withdraw" 
                  customClass="btn-primary btn-large mt-4" 
                  onClick={handleWithdrawSubmit}
                  loading={isLoading}
                  />
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default React.memo(PopupWithdrawFee);
