import React from "react";
import PropTypes from "prop-types";
import { Segmented } from "antd";

import SegmentStyle from "./Segment.Style";

const Segment = ({
  containerClass,
  customClass,
  label,
  options,
  value,
  disabled,
  onChange,
  size
}) => {
  return (
    <SegmentStyle className={`${containerClass}`}>
      {label !== "" && <div className="custom-segment-label !font-semibold !text-sm">{label}</div>}
      <Segmented
        className={`custom-segment ${customClass} !bg-gray-5 !rounded-2xl`}
        options={options}
        disabled={disabled}
        value={value}
        onChange={onChange}
        size={size}
      />
    </SegmentStyle>
  );
};

Segment.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string
};

Segment.defaultProps = {
  containerClass: "",
  customClass: "",
  label: "",
  options: [],
  disabled: false,
  onChange: () => {},
  size: "middle"
};

export default React.memo(Segment);
