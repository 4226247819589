import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Buttons from "components/buttons";
import MoslemeTable from "components/Table";
import Dropdowns, { closeDropdown } from "components/dropdowns";
import Segment from "components/segment";
import PenarikanHistory from "./components/History";
import Checkbox from "components/checkbox";
import Popup from "components/popup";
import DaftarRequest from "./components/DaftarRequest";

import { getWithdrawRequestService, withdrawConfirmationService } from "utils/api/fee";

import { useSelector } from "react-redux";

import AvatarRed from "assets/images/Avatar-ikhwan-red.svg";
import IconEye from "assets/icons/eye.svg";
import BNI from "assets/images/bni-bank.png";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import Avatar from "components/avatar";
import { IDRFormater, formatRekening, toCapital } from "utils/helpers";
import moment from "moment";
import Scroll from "components/scroll";

import { getRekeningApi } from "utils/api/datamaster/adminRekening";

import './style.css'
import InputRadio from "components/form/inputRadio";
import { useFormik } from "formik";
import Alert from "components/popup/alert";

const PenarikanAgen = ({
  setTotalWithdraw,
}) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("pending");
  const [openPopup, setOpenPopup] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [history, setHistory] = useState(false);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  // Loading
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGet, setIsLoadingGet] = useState(false);

  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(true);

  const [withdrawSelected, setWithdrawSelected] = useState(null);
  const [withdrawConfirmationStatus, setWithdrawConfirmationStatus] = useState('pending');

  const [listRekening, setListRekening] = useState([]);
  const [rekeningSelected, setRekeningSelected] = useState(null);

  const [withdrawFeeRequests, setWithdrawFeeRequests] = useState([]);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  const formik = useFormik({
    initialValues: {
      status: 'pending',
      sender_account_id: null
    }
  })

  const handleGetWithdrawFeeRequests = async () => {
    setIsLoadingGet(true)
    const response = await getWithdrawRequestService({ page: currentpage, per_page: size, recipient: 'agent' })
    setIsLoadingGet(false)
    if(response.status ===  200) {
      setTotal(response.data.total)
      setTotalWithdraw(response.data.total)
      setWithdrawFeeRequests(response.data.data)
    } else {
      dispatch(handleError(response.data))
    }
  };

  const handleConfirmation = (withdrawID, status) => {
    setWithdrawSelected(withdrawID)
    formik.setFieldValue('status', status)
    if(status === 'success') {
      formik.setFieldValue('sender_account_id', null)
    }
    if(status === 'cancel') {
      setOpenCancel(true)
    }
  }

  const handleSubmitConfirmation = async () => {
    // console.log(formik.values)
    setIsLoading(true)
    const response = await withdrawConfirmationService(withdrawSelected, formik.values)
    setIsLoading(false)
    if(response.status ===  200) {
      dispatch(handleSuccess(response))
      handleGetWithdrawFeeRequests()
      setOpenPopup(false)
      setOpenCancel(false)
    } else {
      dispatch(handleError(response.data))
    }
  }

  const handleGetRekening = async () => {
    const payload = {
      page: 1,
      per_page: 1000
    }
    const response = await getRekeningApi(payload)
    if(response.status ===  200) {
      setListRekening(response.data.data)
    } else {
      dispatch(handleError(response.data))
    }
  }

  const columns = [
    {
      title: "Foto",
      dataIndex: "agent",
      render: (record) => (
        <>
          <Avatar value={record} />
        </>
      ),
    },
    {
      title: "Nama",
      dataIndex: "agent",
      render: (record) => (
        <div>
          {record?.title ? toCapital(record?.title) + ". " : ""} {record?.name}
        </div>
      ),
    },
    {
      title: "Tanggal Penarikan",
      dataIndex: "created_at",
      render: (date) => <div>{moment(date).format("dddd, DD MMMM YYYY H:mm")} WIB</div>,
    },
    {
      title: "Nominal Request",
      dataIndex: "nominal",
      render: (record) => <div>{IDRFormater(record)}</div>,
    },
    {
      title: "Rekening Penerima",
      dataIndex: "account_bank_withdraw",
      render: (bank) => (
        <Dropdowns
          labelDropdown={
            <>
              <div className="flex flex-row justify-between items-center border border-[#4048521a] rounded-xl bg-transparent w-[116px] px-2 py-[10px]">
                <img src={bank.bank.logo} className="!h-[20px] object-cover" alt="bank" />
                <img src={IconEye} alt="eye" width={"16px"} height={"16px"} />
              </div>
            </> 
          }
        >
          <div className="soft-shadow rounded-2xl bg-white flex flex-col gap-4 p-4 !w-[210px]">
            <div className="text-sm flex flex-col gap-1">
              <div className="text-gray-500">
                Nama Akun Bank
              </div>
              <div>
                {bank.account_bank_name}
              </div>
            </div>
            <div className="text-sm">
              <div className="text-gray-500">
                Nomor Rekening
              </div>
              <div>
                {formatRekening(bank.account_bank_number)}
              </div>
            </div>
          </div>
        </Dropdowns>
        // <Dropdowns
        //   placement="bottom"
        //   dropdownHeader={
        //     <>
        //       <div className="flex flex-row justify-between items-center border border-[#4048521a] rounded-xl bg-transparent w-[116px] px-2 py-[10px]">
        //         <img src={bank.bank.logo} alt="bank" />
        //         <img src={IconEye} alt="eye" />
        //       </div>
        //     </>
        //   }
        // >
        //   {status === "berhasil" ? (
        //     <div className="bg-white soft-shadow rounded-2xl w-[472px] border border-[#E0E0E0] overflow-auto">
        //       <div className="w-full p-4">
        //         <div className="font-semibold">Pilih Bank Pengirim</div>
        //       </div>
        //       <div className="divider"></div>
        //       <div className="flex flex-col gap-4 p-4">
        //         <div className="flex flex-row items-center gap-4">
        //           <Checkbox
        //             checked={checked}
        //             onChange={(e) => {
        //               setChecked(e.target.checked);
        //             }}
        //           ></Checkbox>
        //           <div className="flex flex-row justify-between items-center border border-[#E0E0E0] rounded-lg p-4 w-full">
        //             <div className="flex flex-col gap-2">
        //               <div>Nama Akun</div>
        //               <div>Abdul Wahid Ngatono</div>
        //             </div>
        //             <div className="flex flex-col gap-2">
        //               <div>Nomer Rekening</div>
        //               <div>2415 6410 2351 </div>
        //             </div>
        //             <img src={BNI} alt="bank" />
        //           </div>
        //         </div>
        //       </div>
        //       <div className="divider"></div>
        //       <div className="flex flex-col gap-4 p-4">
        //         <div className="flex flex-row items-center gap-4">
        //           <Checkbox
        //             checked={checked}
        //             onChange={(e) => {
        //               setChecked(e.target.checked);
        //             }}
        //           ></Checkbox>
        //           <div className="flex flex-row justify-between items-center border border-[#E0E0E0] rounded-lg p-4 w-full">
        //             <div className="flex flex-col gap-2">
        //               <div>Nama Akun</div>
        //               <div>Abdul Wahid Ngatono</div>
        //             </div>
        //             <div className="flex flex-col gap-2">
        //               <div>Nomer Rekening</div>
        //               <div>2415 6410 2351 </div>
        //             </div>
        //             <img src={BNI} alt="bank" />
        //           </div>
        //         </div>
        //       </div>
        //       <div className="divider"></div>

        //       <div className="flex flex-row gap-2 p-4">
        //         <Buttons
        //           text="Batal"
        //           containerClass="w-full"
        //           customClass="btn-secondary btn-medium w-full"
        //           onClick={() => closeDropdown()}
        //         />
        //         <Buttons
        //           text="Pilih Pengirim"
        //           containerClass="w-full"
        //           customClass="btn-primary btn-medium w-full"
        //           onClick={() => {
        //             closeDropdown();
        //             setOpenPopup(true);
        //           }}
        //         />
        //       </div>
        //     </div>
        //   ) : (
        //     <div className="bg-white soft-shadow rounded-2xl w-[240px]">
        //       <div className="p-4">
        //         <div className="flex flex-col gap-1">
        //           <div className="text-[#828282]">Nama Akun Bank</div>
        //           <div>Dimas Andreyan Pradana Putra</div>
        //         </div>
        //         <div className="flex flex-col gap-1 mt-4">
        //           <div className="text-[#828282]">Nomer Rekening</div>
        //           <div>2415 6410 2351 </div>
        //         </div>
        //         <img className="mt-4" src={BNI} alt="bank" />
        //       </div>
        //     </div>
        //   )}
        // </Dropdowns>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "flex",
      render: (desc, list) => {
        return (
          <div className="flex flex-col relative">
            <div>
              <Segment
                disabled={!permissions?.includes("update_fee-management")}
                containerClass="flex flex-row justify-start border border-[#E0E0E0] rounded-2xl basis-[1]"
                customClass="!bg-transparent !p-1"
                value={(list.id === withdrawSelected) ? formik.values.status : "pending"}
                onChange={(e) => {
                  handleConfirmation(list.id, e);
                }}
                options={[
                  {
                    label: <div className="segment-label-blue">Pending</div>,
                    value: "pending",
                  },
                  {
                    label: <div>Berhasil</div>,
                    value: "success",
                  },
                  {
                    label: <div className="segment-label-error">Batalkan</div>,
                    value: "cancel",
                  },
                ]}
              />
            </div>
            <div>
              <Dropdowns
                placement="bottom"
                labelDropdown=""
                onClose={() => {
                  // setWithdrawConfirmationStatus("pending");
                  if(!openPopup) {
                    formik.setFieldValue("status", "pending");
                  }
                }}
                forceOpen={(list.id === withdrawSelected && formik.values.status === "success") ? true : false}
                >
                  <div
                    className="bg-white border shadow-soft rounded-2xl !top-11"
                    >
                      <div className="p-4 font-semibold border-b">
                        Pilih Bank Pengirim
                      </div>
                      <Scroll
                        height="350px"
                        >
                          {listRekening.map((item, index) => (
                            <div className="flex items-center gap-4 p-4">
                              <div>
                                <InputRadio 
                                  value={item.id_rekening} 
                                  onChange={(e) => {
                                      formik.setFieldValue("sender_account_id", e.target.value);
                                      setRekeningSelected(item);
                                  }}
                                  checked={formik.values.sender_account_id == item.id_rekening}
                                />
                              </div>
                              <div className="flex justify-between items-center border rounded-lg p-4 w-[400px] text-xs">
                                <div className="flex flex-col gap-1">
                                  <div>Nama Akun</div>
                                  <div>{item.account_owner}</div>
                                </div>
                                <div className="flex flex-col gap-1">
                                  <div>Nomer Rekening</div>
                                  <div>{formatRekening(item.account_number)}</div>
                                </div>
                                <img src={item.bank_logo} className="h-[20px] object-cover" alt="bank" />
                              </div>
                            </div>
                            ))}
                      </Scroll>
                      <div className="flex flex-row gap-2 p-4 border-t">
                        <Buttons
                          text="Batal"
                          containerClass="w-full"
                          customClass="btn-secondary btn-medium w-full"
                          onClick={() => closeDropdown()}
                          />
                        <Buttons
                          disabled={!formik.values.sender_account_id}
                          text="Pilih Pengirim"
                          containerClass="w-full"
                          customClass="btn-primary btn-medium w-full"
                          onClick={() => {
                            closeDropdown();
                            setOpenPopup(true);
                          }}
                          />
                      </div>
                  </div>
              </Dropdowns>
            </div>
          </div>
        );
      },
    },
  ];

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  useEffect(() => {
    handleGetRekening()
  }, []);

  useEffect(() => {
    handleGetWithdrawFeeRequests()
  }, [dispatch, currentpage, size]);

  useEffect(() => {
    if(openPopup) {
      // handleGetRekening()
      formik.setFieldValue("status", "success");
    } else {
      formik.setFieldValue("status", "pending");
    }
  }, [openPopup])

  return (
    <>
      <Alert
        title="Yakin untuk membatalkan request penarikan fee ini?"
        subtitle="Penarikan fee yang sudah dibatalkan tidak bisa dirubah statusnya"
        text_accept="Batalkan"
        open={openCancel}
        loading={isLoading}
        handleClose={() => {
          setOpenCancel(false)
          formik.setFieldValue("status", "pending");
        }}
        handleContinue={() => {
          handleSubmitConfirmation()
        }}
      />
      <Popup
        open={openPopup}
        handleClose={() => {
          setOpenPopup(false)
        }}
        title="Daftar Request Paket"
        width="982px"
        children={
          <DaftarRequest 
            rekeningSelected={rekeningSelected} 
            setOpenPopup={setOpenPopup} 
            handleSubmit={handleSubmitConfirmation}
            loading={isLoading}
            />}
      />
      {history ? (
        <PenarikanHistory setHistory={setHistory} />
      ) : (
        <>
          <div className="flex flex-row justify-between items-center py-4">
            <div className="text-xl font-semibold">
              Daftar Request Penarikan Agen
            </div>
            <Buttons
              text="History"
              customClass="btn-outline btn-small"
              onClick={() => setHistory(true)}
            />
          </div>
          <div className="mx-[-1rem] border-t border-[##E0E0E0]">
            <MoslemeTable
              loading={isLoadingGet}
              columns={columns}
              data={withdrawFeeRequests}
              withSelection={withSelection}
              selectionType={selectionType}
              pagination={pagination}
              customClass="min-h-[600px]"
              onPageChange={(page) => setCurrentpage(page)}
              onSizeChange={(currentpage, pageSize) =>
                onSizeChange(currentpage, pageSize)
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(PenarikanAgen);
