
import { useEffect, useState } from 'react'

import IconAdditional from 'assets/icons/Additional1.svg'
import { Edit, Trash } from 'iconsax-react'
import { IDRFormater, toCapital, toCurrencyFormat } from 'utils/helpers'
const Step3AdditonalCard = ({ additionalData, handleRemoveAdditional, handleEdit, packageSelected }) => {

  const [quantityTotal, setQuantityTotal] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)

  const handleCountQuantity = () => {
    const total = additionalData.jamaah?.reduce((acc, participant) => acc + participant.quantity, 0);

    setQuantityTotal(total)
    setTotalPrice(total * additionalData.price)
  }

  useEffect(() => {
    handleCountQuantity()
  }, [additionalData])

  return (
    <>
      <div className="bg-white p-4 mt-4 rounded-xl">
        <div className='flex justify-between'>
          <div className="flex gap-4 items-start">
            <img 
              className='w-10 h-10'
              src={IconAdditional}
              />
            <div>
              <h3 className='font-semibold text-lg mb-1'>
                {additionalData?.name}
              </h3>
              <div className='text-sm'>
                {additionalData?.is_wajib === '1' ? 'Additional Wajib' : 'Additional Tidak Wajib'}
              </div>
            </div>
          </div>
          <div className='flex gap-3'>
            <Edit
                className="cursor-pointer"
                onClick={handleEdit}
                size="24"
                color="#141414"
              />
            {additionalData?.is_wajib !== '1' && (
              <Trash
                className="cursor-pointer"
                onClick={handleRemoveAdditional}
                size="24"
                color="#141414"
              />
            )}
          </div>
        </div>


        <div className='flex justify-between items-center mt-4'>
          <div className='text-sm'>
            {quantityTotal} x {toCurrencyFormat(additionalData?.price, packageSelected?.currency, false)}
          </div>
          <div className='text-xl font-semibold'>
            {toCurrencyFormat(totalPrice, packageSelected?.currency, false)}
          </div>
        </div>

        <div className='border rounded-xl px-4 py-2 mt-4'>
          {additionalData?.jamaah?.map((jamaah, index) => (
            <>
              <div className='py-2'>
                <div className='flex justify-between items-center'>
                  <h3 className='font-semibold text-sm mb-1'>
                    {jamaah?.title ? `${toCapital(jamaah?.title)}.` : ''} {jamaah?.name?.toUpperCase()}
                  </h3>
                  <div>
                    {jamaah?.quantity}x
                  </div>
                </div>
                <div className='text-sm font-thin border-dotted border rounded-lg mt-2 p-2'>
                  {jamaah?.note ? jamaah?.note : '-'}
                </div>
              </div>
            </>
          ))}
        </div>
        
      </div>
    </>
  )
}

export default Step3AdditonalCard