import React, { useRef } from "react";
import { useSelector } from "react-redux";

import Buttons from "components/buttons";
import Dropdowns, { closeDropdown } from "components/dropdowns";
import Inputs from "components/form/inputs";
import InputsCurrency from "components/form/inputsCurrency";
import Radio from "components/form/radio";
import Scroll from "components/scroll";
import { Eye, EyeSlash } from "iconsax-react";
import CouponForm from "pages/tagihan/components/CouponForm";
import DiscountAgentForm from "pages/tagihan/components/DiscountAgentForm";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { handleError } from "stores/actions/errorGeneral";

import { IDRFormater } from "utils/helpers";
import AddCouponForm from "./components/AddCoupons/AddCouponForm";

import IconArrowRight from "assets/icons/arrow-right.svg";
import Popup from "components/popup";
import Step4SelectingPaymentMethods from "./components/Step4SelectingPaymentMethods";


import {
  getMasterRekening,
} from "stores/actions/datamaster/adminRekening";

const Step4 = ({ packageID, bookingForm, additionalsFormik, handleSetTotalPrice, couponList, setCouponList }) => {
  const dispatch = useDispatch()
  const scrollRef = useRef(null)
  const [couponSelectorLabel, setCouponSelectorLabel] = useState("Pakai Kupon")

  const [couponPrivateList, setCouponPrivateList] = useState([])
  const [isLoadingCheckCoupon, setIsloadingCheckCoupon] = useState(false)
  const [selectedCoupon, setSelectedCoupon] = useState({})

  const [couponSelected, setCouponSelected] = useState({})

  const [typeCoupon, setTypeCoupon] = useState(1)
  const [codeCoupon, setCodeCoupon] = useState(null)

  const [priceOrderPax, setPriceOrderPax] = useState(0)
  const [priceAdditionalTotal, setPriceAdditionalTotal] = useState(0)
  const [discountPrice, setDiscountPrice] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)

  const [couponJamaahOrders, setCouponJamaahOrders] = useState([])
  const [couponBranch, setCouponBranch] = useState({})

  const [discountCupon, setDiscountCupon] = useState(0)

  const [popupPaymentMethod, setPopupPaymentMethod] = useState(false)

  const [typeOptions, setType] = useState([
    {
      value: (
        <div className="flex items-center">
          <Eye variant="Bold" className="mr-2" /> <p>Public</p>
        </div>
      ),
      label: "Pilih Kupon",
      key: 1,
    },
    {
      value: (
        <div className="flex items-center">
          <EyeSlash variant="Bold" className="mr-2" /> <p>Private</p>
        </div>
      ),
      label: "Masukan Kupon",
      key: 2,
    },
  ]);

  const { allRekening, isLoading } = useSelector(
    (state) => state.adminRekening
  );

  const rekeningOptions = allRekening?.data?.data;



  // const handleGetCouponPrivate = async () => {
  //   setCouponPrivateList([])
  //   if(codeCoupon != '') {
  //     const params = {
  //       code_coupon: codeCoupon,
  //       package_id: packageID
  //     }
  //     setIsloadingCheckCoupon(true)
  //     const response = await PaketCouponAPI.getCouponForOrder(params)
  //     const { status, data } = response
  //     setIsloadingCheckCoupon(false)
  //     if(status === 200) {
  //       setCouponPrivateList(data.data)
  //     } else {
  //       dispatch(handleError(data))
  //     }  
  //   }
  // }

  const handleResetCoupon = () => {
    bookingForm.setFieldValue('id_coupon', null)
    bookingForm.setFieldValue('coupon', null)

    setTimeout(() => {
      bookingForm.setFieldTouched('id_coupon', 1)
      bookingForm.setFieldTouched('coupon', 1)
    })

    const label = `Pakai Kupon`
    setCouponSelectorLabel(label)

  }

  const handleCountOrderJamaah = () => {
    let totalHargaOrder = 0;

    for(const order of bookingForm.values.order_kamars) {
      const paketKamar = order.paket_kamar;
      const price = paketKamar ? paketKamar.price : 0; // Menggunakan harga paket kamar jika ada, 0 jika tidak

      // Menambahkan total harga paket kamar
      totalHargaOrder += price;
    }

    setPriceOrderPax(totalHargaOrder)
  }

  const handleCountAdditionalPrice = () => {

    const additionals = additionalsFormik.values.paket_additionals
    
    let additionalPrice = 0;

    for(const additional of additionals) {
      const price = additional ? additional.price : 0; // Menggunakan harga paket kamar jika ada, 0 jika tidak
      let quantity = 0;

      for (const jamaah of additional.jamaah) {
        quantity += jamaah.quantity
      }

      // Menambahkan total harga paket kamar
      additionalPrice += (price * quantity);
    }

    setPriceAdditionalTotal(additionalPrice)
  }

  const handleCountTotalCutPrice = () => {
    let discount = discountCupon;
    
    const agentDiscount = parseInt(bookingForm.values?.agent_discount_amount ?? 0) 
    
    setDiscountPrice(discount + (agentDiscount * bookingForm.values.order_kamars.length))
  }

  const handleSelectCoupon = (dataCoupons) => {

    let totalDiscountCoupon = 0
    let bookingJamaahOrders = [...bookingForm.values.order_kamars]

    bookingJamaahOrders.map(jamaah => {
      jamaah.coupons = []
    })

    if(dataCoupons.length > 0) {
      dataCoupons.forEach(coupon => {
        totalDiscountCoupon += coupon.total_amount;

        coupon.jamaah_orders.forEach(jamaahOrder => {
          const jamaahIndex = bookingJamaahOrders.findIndex(jamaah => jamaah.jamaah_identity_number == jamaahOrder.nik)
          let bookingJamaahOrder = bookingJamaahOrders[jamaahIndex]

          if(!bookingJamaahOrder.coupons.includes(coupon.id)) {
            bookingJamaahOrder.coupons.push(coupon.id)
          }

        })

      });
    } 

    // bookingForm.setFieldValue('order_kamars', bookingJamaahOrders)
    setDiscountCupon(totalDiscountCoupon)

  }

  const handleCountTotalPrice = () => {
    let countTotalPrice = priceOrderPax + priceAdditionalTotal - discountPrice;

    handleSetTotalPrice(countTotalPrice)
    setTotalPrice(countTotalPrice)
  }

  const handleScrollToElement = (elementToScroll) => {
    if(elementToScroll) {
      scrollRef.current.scrollTo({
        top: elementToScroll.offsetTop - 170,
        behavior: 'smooth'
      });
    }
  }

  const handleSelectPayment = (accountId, paymentMethod) => {
    bookingForm.setFieldValue('payment.payment_method', paymentMethod)
    bookingForm.setFieldValue('payment.account_id', accountId)
    setPopupPaymentMethod(false)
  }

  useEffect(() => {
    handleCountTotalCutPrice()
    handleCountTotalPrice()
  }, [bookingForm.values.id_coupon, bookingForm.values.agent_discount_amount, priceAdditionalTotal, priceOrderPax, discountPrice, selectedCoupon, discountCupon])

  useEffect(() => {
    // setSelectedCoupon({})
    // handleGetCoupons()

    handleCountOrderJamaah()
    handleCountAdditionalPrice()
    handleCountTotalPrice()

    if(bookingForm.values.id_coupon) {
      const coupon = bookingForm.values.coupon
      const label = `${coupon.code_coupon} - Diskon ${coupon.jenis_diskon === 1 ? toCurrencyFormat(coupon.nominal_diskon, packageSelected.currency, false) : `${coupon.nominal_diskon}%`}`
      setCouponSelectorLabel(label)
    }

  }, [])

  useEffect(() => {
    let couponJamaah = [];

    bookingForm.values.order_kamars.forEach((jamaah, index) => {
      let jamaahData = jamaah

      jamaahData = {
        ...jamaahData,
        id: index,
        name_package_starting: jamaah.starting.name,
        package_starting: jamaah.starting
      }

      couponJamaah.push(jamaahData)
    });

    setCouponJamaahOrders(couponJamaah)

  }, [bookingForm.values.order_kamars])

  useEffect(() => {
    let _tempCouponBranch = bookingForm.values.pemesan.branch
    
    _tempCouponBranch = {
      ..._tempCouponBranch,
      color_text: _tempCouponBranch.text_color,
      color: _tempCouponBranch.bg_color
    }

    setCouponBranch(_tempCouponBranch)

  }, [bookingForm.values.pemesan])


  useEffect(() => {
    dispatch(
      getMasterRekening({
        page_number: 1,
        per_page: 10000,
        keyword: "",
        sort_column: "",
        sort_order: "DESC",
      })
    );
  }, [])

  return (
      <>
      <Popup
        open={popupPaymentMethod}
        handleClose={() => setPopupPaymentMethod(false)}
        title="Metode Pembayaran"
        width="672px"
        containerClass=""
        children={<Step4SelectingPaymentMethods onChange={handleSelectPayment} rekeningOptions={rekeningOptions} />}
      />
        <Scroll 
          scollRef={scrollRef}
          height="422px" customClass="m-[-16px] p-4">   
          {bookingForm.values.pemesan.guard_type === 'agent' && (
            <>
              <div className="mb-4">
                <DiscountAgentForm 
                  defaultValue={bookingForm.values.agent_discount_amount}
                  totalPax={bookingForm.values.order_kamars.length}
                  onChange={(val) => {
                    bookingForm.setFieldValue('agent_discount_amount', val)
                  }}
                  />
              </div>
            </>
          )}

          <AddCouponForm 
            packageID={packageID}
            jamaahOrders={couponJamaahOrders}
            branch={couponBranch}
            couponList={couponList}
            setCouponList={setCouponList}
            additionals={additionalsFormik.values.paket_additionals}
            bookingForm={bookingForm}
            onChange={(val) => {
              handleSelectCoupon(val)
            }}
            onAddingCouponScroll={(element) => {
              handleScrollToElement(element)
            }}
            />

          <div className="mt-4 border rounded-2xl p-4">
            <h3 className="text-base font-semibold mb-4">Pilih Metode Pembayaran</h3>
            <div 
              onClick={() => setPopupPaymentMethod(true)}
              className="flex justify-between items-center mt-4 cursor-pointer">
              <div className="font-semibold ">
                {bookingForm.values.payment['account_id'] === null ? '-' : (
                  <>
                    <div className="flex gap-[9.5px] items-center">
                      <img 
                        className="h-6"
                        src={rekeningOptions?.find((item) => item.id_rekening === bookingForm.values.payment['account_id'])?.bank_logo} />
                      <div className="text-sm font-normal">
                        {rekeningOptions?.find((item) => item.id_rekening === bookingForm.values.payment['account_id'])?.bank_name}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div>
                <img src={IconArrowRight} alt="icon" />
              </div>
            </div>
          </div>

          <div className="mt-4 border rounded-2xl p-4">
            <h3 className="text-base font-semibold mb-4">Rincian Booking</h3>

            <div className=" text-sm">
              <div className="flex justify-between py-1">
                <div>
                  Harga Total Pax
                </div>
                <div className="font-semibold text-base">
                  {toCurrencyFormat(priceOrderPax, packageSelected.currency, false)}
                </div>
              </div>
              <div className="flex justify-between py-1">
                <div>
                  Total Additional
                </div>
                <div className="font-semibold text-base">
                  {toCurrencyFormat(priceAdditionalTotal, packageSelected.currency, false)}
                </div>
              </div>
              <div className="flex justify-between py-1">
                <div>
                  Potongan Harga
                </div>
                <div className="font-semibold text-base text-red-1">
                  - {toCurrencyFormat(discountPrice, packageSelected.currency, false)}
                </div>
              </div>
            </div>

          </div>
        </Scroll>
      </>
  );
};

export default Step4;