import Buttons from "components/buttons"
import { useEffect, useState } from "react"

import { useDispatch } from "react-redux";

import { handleError } from "stores/actions/errorGeneral";

import Step3AdditionalForm from "./components/Step3AdditionalForm";
import Step3AdditonalCard from "./components/Step3AdditionalCard";

import { getAdditionalByPaket } from "utils/api/paket/additional";

import * as Yup from 'yup';
import { useFormik } from "formik";
import Alert from "components/popup/alert";

const Step3 = ({ bookingForm, packageID, additionalsFormik, packageSelected }) => {
  const dispatch = useDispatch()

  const [keyword, setKeyword] = useState('')
  const [additionals, setAdditionals] = useState([])

  const [isAdditionalSelected, setIsAdditionalSelected] = useState(false)
  const [additionalSelectedIndex, setAdditionalSelectedIndex] = useState(0)
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false)
  const [isRemoveAdditional, setIsRemoveAdditional] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  

  const handleGetAdditionalPackage = async () => {
    const payload = {
      id_paket: packageID,
      keyword,
      per_page: 100,
      page_number: 1,
      sort_column: 'name',
      sort_order: 'ASC'
    }
    const response = await getAdditionalByPaket(payload)
    const { status, data } = response

    if(status == 200) {
      setAdditionals(data.data)
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleFetchOrderAdditionals = () => {
    if(additionalsFormik.values.paket_additionals?.lpackageSelectedength == 0) {
      let newAdditionals = [];

      additionals?.map((item, idx) => {
        if(item.is_wajib === '1') {
          
          let  newItem = {
            id: item.id,
            name: item.name,
            price: item.price,
            is_wajib: item.is_wajib,
            jamaah: [],
          }

          bookingForm.values.order_kamars?.map((item, idx) => {
            newItem.jamaah.push({
              quantity: 1,
              name: item.jamaah_name,
              title: item.jamaah_title,
              ktp_nik: item.jamaah_identity_number,
              note: null
            })
          })

          newAdditionals.push(newItem)

          additionalsFormik.setFieldValue('paket_additionals', newAdditionals)
          
          setTimeout(() => {
            additionalsFormik.setFieldTouched('paket_additionals', 1)
          })

        }
      })

    }
  }

  const handleNewOAdditional = () => {
    setIsAdditionalSelected(1)

    let newAdditionals = [{
      id: null,
      name: null,
      price: null,
      is_wajib: null,
      jamaah: [],
    }];

    let currentAdditionals = additionalsFormik.values.paket_additionals

    additionalsFormik.setFieldValue('paket_additionals', [...currentAdditionals, ...newAdditionals])

    const newIndex = additionalsFormik.values.paket_additionals.length
    setAdditionalSelectedIndex(newIndex)
  }

  const handleAddAdditional = () => {
    setIsAdditionalSelected(false)
  }

  const handleRemoveAdditional = (index) => {
    setIsRemoveAdditional(true)
    setAdditionalSelectedIndex(index)
  }

  const handleRemovingAdditional = () => {
    let currentAdditionals = additionalsFormik.values.paket_additionals

    currentAdditionals.splice(additionalSelectedIndex, 1)

    additionalsFormik.setFieldValue('paket_additionals', currentAdditionals)
    setIsRemoveAdditional(false)
  }

  const handleEditAdditional = (index) => {
    setAdditionalSelectedIndex(index)
    setIsAdditionalSelected(1)
  }

  useEffect(() => {
    handleGetAdditionalPackage()
    if(isAdditionalSelected) {
      setIsAddButtonDisabled(true)
    }
  }, [])

  useEffect(() => {
    handleFetchOrderAdditionals()
  }, [additionals])

  useEffect(() => {

    console.log(additionalsFormik.values);

  }, [additionalsFormik.values])

  useEffect(() => {

    if(isAdditionalSelected) {
      setIsAddButtonDisabled(true)
    } else {
      setIsAddButtonDisabled(false)
    }

  }, [isAdditionalSelected])

  return (
      <>
        <Alert 
          open={isRemoveAdditional}
          title="Hapus Additional?"
          subtitle="Anda yakin ingin menghapus additional ini?"
          handleClose={() => {
            setIsRemoveAdditional(false)
          }}
          handleContinue={handleRemovingAdditional}
          />

        <div className="flex gap-2">
          <div className="w-full">
            <div className="bg-[#FBF3F2] rounded-2xl p-4">
              <h3 className="text-sm flex justify-between items-center">
              <span className="font-semibold">Additional</span>
                <Buttons 
                  text="Tambah Additional"
                  customClass="btn btn-primary btn-small"
                  disabled={isAddButtonDisabled}
                  onClick={handleNewOAdditional}
                  />
              </h3> 

              {additionalsFormik.values.paket_additionals?.map((additional, idx) => (
                <>
                  {(isAdditionalSelected && idx === additionalSelectedIndex) ? (
                    <>
                      <Step3AdditionalForm 
                        additionals={additionals}
                        additionalData={additional}
                        orderJamaahList={bookingForm.values.order_kamars}
                        additionalsFormik={additionalsFormik}
                        index={idx}
                        handleTriggerAddAdditional={handleAddAdditional}
                        packageSelected={packageSelected}
                        />
                    </>
                  ) : (
                    <Step3AdditonalCard 
                      additionalData={additional}
                      handleRemoveAdditional={() => handleRemoveAdditional(idx)}
                      handleEdit={() => handleEditAdditional(idx)}
                      packageSelected={packageSelected}
                      />
                  )}
                </>
              ))}

            </div>
          </div>
        </div>
      </>
  )
}

export default Step3