import React from "react"
import PropTypes from "prop-types";

import CheckedGrayIcon from "assets/icons/checked-gray.svg";
import CheckedGreenIcon from "assets/icons/checked-green.svg";

import './index.css'

const RadioIcon = ({
  value, 
  label,
  onChange,
  checked
}) => { 
  return (
      <>
        <div className="inline-flex justify-start items-center gap-2 cursor-pointer" onClick={() => {
          onChange(value)
        }}>
          <div>
            {checked ? (
              <>
                <img src={CheckedGreenIcon} alt="checked" />
              </>
            ) : (
              <>
                <img src={CheckedGrayIcon} alt="unchecked" />
              </>
            )}
          </div>
          <div>
            <label className="text-sm cursor-pointer">{label}</label>
          </div>
        </div>
      </>
  )
}

RadioIcon.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
};

RadioIcon.defaultProps = {
  value: "",
  label: "",
  name: "",
  checked: false,
};

export default React.memo(RadioIcon)