import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import Badge from "components/badge";
import Buttons from "components/buttons";
import Popup from "components/popup";
import EditPesan from "./components/EditPesan";

import { handleSendFollowup } from "utils/api/booking";

import Photo from "assets/images/Photo.png";
import IconEdit from "assets/icons/edit.svg";
import IconSMS from "assets/icons/sms.svg";
import IconWhatsapp from "assets/icons/whatsapp.svg";
import IconReceipt from "assets/icons/Receipt.svg";
import ImageCard from "assets/images/image-card.jpg";
import Tanggal from "assets/icons/Tanggal.svg";
import {
  IcSeatTerisi,
  IcSeatTersisa,
  IcTotalSeat,
} from "assets/icons/dashboard";
import IconJangkaHariMaroon from "assets/icons/icon-jangka-hari-maroon.svg";
import IconMaskapai from "assets/icons/icon-maskapai.svg";
import SudahBerangkat from "assets/icons/colored/sudah-berangkat.svg";

import FollowUpSender from "assets/icons/followup-sender.svg";

import { handleGetDetailOrder } from "stores/actions/booking";
import { IDRFormater, toCapital, toCurrencyFormat } from "utils/helpers";
import Avatar from "components/avatar";
import moment from "moment";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import { Edit } from "iconsax-react";
import UpdateChosePackage from "./components/UpdateChosePackage";

const BookingDetailHeader = ( {
  handleSetOrder,
  handleNotFound
} ) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;

  const [sendingFollowup, setSendingFollowup] = useState({
    loading: false,
    key: null
  })

  
  const { bookingDetail } = useSelector((state) => state.booking);

  const [openPopupEditPesan, setOpenPopupEditPesan] = useState(false);
  const [openPopupUpdateChosePackage, setOpenPopupUpdateChosePackage] = useState(false);

  const handleClickFollowup = async (key) => {
    setSendingFollowup({
      loading: true,
      key: key
    })
    const res = await handleSendFollowup(params.orderNumber, key)
    setSendingFollowup({
      loading: false,
      key: null
    })

    if (res.status === 200) {
      dispatch(handleSuccess(res))
    } else {
      dispatch(handleError(res.data))
    }

  }

  useEffect(() => {
    dispatch(handleGetDetailOrder(params.orderNumber, () => {
      handleNotFound()
    }));
  }, []);

  useEffect(() => {
    handleSetOrder(bookingDetail)
  }, [bookingDetail]);

  return (
    <>
      <Popup
        open={openPopupEditPesan}
        handleClose={() => setOpenPopupEditPesan(false)}
        title="Edit Pesan Follow Up"
        width="1080px"
        children={<EditPesan setOpenPopupEditPesan={setOpenPopupEditPesan} />}
      />
      <Popup
        open={openPopupUpdateChosePackage}
        handleClose={() => setOpenPopupUpdateChosePackage(false)}
        title="Ganti Pilihan Paket"
        width="653px"
        children={<UpdateChosePackage onSubmited={() => setOpenPopupUpdateChosePackage(false)} packageSelected={bookingDetail?.paket} />}
      />
      <div className="card-detail-booking-header">
        <Grid container spacing={4}>
          <Grid item md={8}>
            <div className="card-detail-booking-header-pink flex flex-row !py-[22px] !px-4">
              <div className="flex flex-row w-[70%] items-center">
                <Avatar 
                  value={bookingDetail?.pemesan}
                  containerClass="items-center justify-center border border-white w-[56px] h-[56px] rounded-[100%]  bg-slate-100"
                  customClass="w-[56px] h-[56px] rounded-[100%]"
                  />
                <div className="ml-3">
                  <div className="flex flex-row gap-2 items-center">
                    <div className="font-semibold text-[#FFFFFF] text-[20px]">
                      {bookingDetail?.pemesan?.title ?  `${toCapital(bookingDetail?.pemesan?.title)}. ` : ''} {toCapital(bookingDetail?.pemesan?.name)}
                    </div>
                    {/* {bookingDetail?.payment_status != 4 && (
                      <>
                        <div className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer">
                          <img
                            className="w-[16px] h-[16px]"
                            src={IconEdit}
                            alt="user-img"
                          />
                        </div>
                      </>
                    )} */}
                  </div>
                  <div className="flex flex-row gap-2 mt-2">
                    <Badge 
                      label={bookingDetail?.pemesan?.nama_role ?? bookingDetail?.pemesan?.role_name} 
                      backgroundColor='rgba(255, 255, 255, 0.5)'
                      color={bookingDetail?.pemesan?.color_text_role}
                      />
                    <Badge 
                      label={bookingDetail?.pemesan?.cabang_name ? bookingDetail?.pemesan?.cabang_name.toUpperCase() : bookingDetail?.pemesan?.nama_cabang.toUpperCase()}
                      backgroundColor='rgba(255, 255, 255, 0.5)'
                      color={bookingDetail?.pemesan?.color_text_cabang}
                    />
                  </div>
                </div>
              </div>
              <div className="card-detail-booking-header-pink-transparent w-[35%]">
                <div className="flex flex-row justify-between">
                  <div className="text-[#FFFFFF]">Waktu Booking:</div>
                </div>
                <div className="text-[16px] text-[#FFFFFF] font-semibold mt-2">
                  {moment(bookingDetail?.ordered_at).format("dddd, DD MMMM YYYY H:mm")} WIB
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-4">
              <div className="card-detail-booking-header-pink mt-2 w-[25%] !p-4">
                <div className="flex flex-col !justify-between h-full">
                  <div className="flex flex-row justify-between">
                    <div className="text-[#FFFFFF]">Follow Up:</div>
                    {bookingDetail?.payment_status != 4 && (
                      <>
                        {auth.user?.access_data === 'all_branches' && (
                          <>
                            {permissions?.includes('update_booking-data') && (
                              <>
                                <div className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer">
                                  <img
                                    className="w-[16px] h-[16px]"
                                    src={IconEdit}
                                    alt="user-img"
                                    onClick={() => setOpenPopupEditPesan(true)}
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex flex-row">
                    <Buttons
                      text="1"
                      customClass="btn-white-light btn-small !w-fit"
                      disabled={(bookingDetail.payment_status !== 0 || bookingDetail.payment_status === 4) || !bookingDetail.payment_dp}
                      icon={FollowUpSender}
                      loadingIcon={true}
                      onClick={() => handleClickFollowup('dp')}
                      loading={sendingFollowup.loading && sendingFollowup.key === 'dp'}
                    />
                    <Buttons
                      text="2"
                      customClass="btn-white-light btn-small !w-fit ml-1"
                      disabled={(bookingDetail.payment_status === 0 || bookingDetail.payment_status === 4) || bookingDetail.count_manifest_data_not_filled === 0}
                      icon={FollowUpSender}
                      loadingIcon={true}
                      onClick={() => handleClickFollowup('manifest')}
                      loading={sendingFollowup.loading && sendingFollowup.key === 'manifest'}
                    />
                    <Buttons
                      text="3"
                      customClass="btn-white-light btn-small !w-fit ml-1"
                      // disabled={bookingDetail.payment_status == 4 ? true : false}
                      icon={FollowUpSender}
                      loadingIcon={true}
                      disabled={(bookingDetail.payment_status === 0 || bookingDetail.payment_status === 4 || bookingDetail.payment_status === 3)}
                      onClick={() => handleClickFollowup('payment')}
                      loading={sendingFollowup.loading && sendingFollowup.key === 'payment'}
                    />
                    {/* <Buttons
                      text="4"
                      customClass="btn-white-light btn-small !w-fit ml-1"
                      disabled={bookingDetail.payment_status == 4 ? true : false}
                      icon={FollowUpSender}
                    /> */}
                  </div>
                </div>
              </div>

              <div className="card-detail-booking-header-pink mt-2 w-[75%] !p-4">
                <div className="flex flex-row justify-between">
                  <div className="text-[#FFFFFF]">Tagihan:</div>
                  <div 
                    onClick={() => history.push(`/tagihan/detail/${params.orderNumber}`)}
                    className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer">
                    <img
                      className="w-[16px] h-[16px]"
                      src={IconReceipt}
                      alt="user-img"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-4">
                  <div>
                    <div className="text-[16px] text-[#ffffff]">
                      Total Biaya :
                    </div>
                    <div className="text-[20px] text-[#ffffff] font-semibold">
                      {toCurrencyFormat(bookingDetail.total_price, bookingDetail?.currency, false)}
                    </div>
                  </div>
                  <div>
                    <div className="text-[16px] text-[#ffffff]">
                      Sudah Dibayarkan :
                    </div>
                    <div className="text-[20px] text-[#ffffff] font-semibold">
                      {toCurrencyFormat(bookingDetail.paid_amount, bookingDetail?.currency, false)}
                    </div>
                  </div>
                  <div>
                    <div className="text-[16px] text-[#ffffff]">Diskon :</div>
                    <div className="text-[20px] text-[#ffffff] font-semibold">
                      {IDRFormater(parseInt(bookingDetail.total_discount))}
                    </div>
                  </div>
                  <div>
                    <div className="text-[16px] text-[#ffffff]">
                      Kekurangan :
                    </div>
                    <div className="text-[20px] text-[#ffffff] font-semibold">
                      {toCurrencyFormat(bookingDetail.remaining_payment_amount, bookingDetail?.currency, false)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="card-detail-booking-paket">
              <div
                className="card-detail-booking-image relative"
                style={{ backgroundImage: `url(${bookingDetail?.paket?.image_thumbnail})` }}
              >
                {bookingDetail?.payment_status !== 4 && (
                  <div 
                    onClick={() => {
                      setOpenPopupUpdateChosePackage(true)
                    }}
                    className="absolute bottom-4 left-4 bg-white p-2 rounded-lg cursor-pointer">
                    <Edit
                      size={16}
                    />
                  </div>
                )}
              </div>
              <div className="p-4">
                <div className="flex flex-row gap-2 justify-between">
                  <div 
                    onClick={() => history.push(`/paket/detail/${bookingDetail?.paket?.id_paket}`)}
                    className="font-semibold text-[16px] cursor-pointer line-clamp-1">
                    {bookingDetail?.paket?.judul_paket}
                  </div>
                  {/* <div className="bg-[#FFFFFF] rounded-lg "> */}
                    {/* <img
                      className="w-[20px] h-[20px]"
                      src={IconEdit}
                      alt="user-img"
                    /> */}
                  {/* </div> */}
                </div>
                <div className="flex flex-row my-3 gap-2 items-center">
                  <img src={Tanggal} alt="tanggal" />
                  <div className="flex items-center gap-3">
                    {moment(bookingDetail?.paket?.jadwal_keberangkatan).format(
                      "dddd, DD MMMM YYYY"
                    )}
                    <div className="bg-[#CBF3F0] rounded-lg p-2 flex flex-row gap-8 w-fit">
                      <div className="flex flex-row items-center gap-1">
                        <img src={IcTotalSeat} alt="" />
                        <div>{bookingDetail?.paket?.kuota}</div>
                      </div>
                      <div className="flex flex-row items-center gap-1">
                        <img src={IcSeatTerisi} alt="" />
                        <div>{bookingDetail?.paket?.jumlah_jamaah}</div>
                      </div>
                      <div className="flex flex-row items-center gap-1">
                        <img src={IcSeatTersisa} alt="" />
                        <div>{bookingDetail?.paket?.kuota_tersedia}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 flex gap-3">
                  <div className="flex gap-2 items-center">
                    <img src={IconJangkaHariMaroon} />
                    <span>
                      {bookingDetail?.paket?.jumlah_hari} Hari
                    </span>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img src={IconMaskapai} className="w-4 h-4" />
                    <span className="flex gap-1 items-center">
                      {bookingDetail?.paket?.maskapai?.name}
                    </span>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img src={SudahBerangkat} className="w-4 h-4" />
                    <span className="flex gap-1 items-center">
                      Landing {bookingDetail?.paket?.mendarat_di}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default React.memo(BookingDetailHeader);
