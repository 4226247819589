import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const InputNumber = ({ min, max, value, onChange }) => {
  const [val, setVal] = useState(value);

  const handleIncrease = () => {
    let newValue = val;
    if (val < max) {
      newValue = val + 1;
      setVal(newValue);
      onChange(newValue);
    }
  };

  const handleDecrease = () => {
    let newValue = val;
    if (val > min) {
      newValue = val - 1;
      setVal(newValue);
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (value < min) {
      setVal(min);
    } else if (value > max) {
      setVal(max);
    } else {
      setVal(value);
    }
  }, [value, min, max]);

  return (
    <div className="flex gap-[15px] items-center">
      <button
        onClick={handleDecrease}
        className="flex justify-center items-center w-6 h-6 rounded-full text-white bg-red-3"
        disabled={val <= min}
      >
        -
      </button>

      <span className="font-semibold">{val}</span>

      <button
        onClick={handleIncrease}
        className="flex justify-center items-center w-6 h-6 rounded-full text-white bg-red-3"
        disabled={val >= max}
      >
        +
      </button>
    </div>
  );
};

InputNumber.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func
};

InputNumber.defaultProps = {
  min: 0,
  max: 2,
  value: 0,
  onChange: () => {}
};

export default InputNumber;
