import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useFormik } from "formik";
import { useDispatch } from 'react-redux'

import { PaketCouponAPI } from "utils/api/paket/coupon";

import Popup from "components/popup";
import Steppers from "components/steps/Steppers";
import Scroll from "components/scroll";
import Buttons from "components/buttons";
import Step1 from "./Step1";

import * as Yup from 'yup';
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

import { handleStoreBookingAPI } from "utils/api/booking";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import { IDRFormater, toCurrencyFormat } from "utils/helpers";

const CreateNewBooking = ({ open, handleClose }) => {
  const router = useHistory();
  const dispatch = useDispatch()
  const [currentStep, setCurrentStep] = useState(0)
  const [isMergeAdditional, setIsMergeAdditional] = useState(false)
  const [couponList, setCouponList] = useState([])

  const [isLoadingStoreBooking, setIsLoadingStoreBooking] = useState(false)

  const [totalPrice, setTotalPrice] = useState(0)

  const [packageSelected, setPackageSelected] = useState({})

  const stepItems = [
    {
      title: "Paket & Pemesan",
    },
    {
      title: "Order Jamaah",
    },
    {
      title: "Additional",
    },
    {
      title: "Kupon & Rincian",
    },
  ]

  const bookingForm = useFormik({
    initialValues: {
      "paket" : {
        id: null
      },
      pemesan: {
        id: null,
        guard_type: 'admin',
        branch: {
          name: null,
          bg_color: null,
          text_color: null
        }
      },
      id_coupon: null,
      agent_discount_amount: 0,
      order_kamars: [],
      payment: {
          payment_method: null,
          account_id: null
      }
    },
    validationSchema: Yup.object({
      "paket" : Yup.object({
        id: Yup.number().required("Harus plih paket"),
      }),
      pemesan: Yup.object({
        id: Yup.number().required("Harus pilih pemesan"),
        guard_type: Yup.string().required("Harus pilih pemesan"),
      }),
      order_kamars: Yup.array().of(
        Yup.object({
          "jamaah_title": Yup.string().required("Harus pilih salah satu title"),
          "jamaah_name": Yup.string().required("Nama Jaamaah harus diisi"),
          "jamaah_identity_number": Yup.string().required("NIK harus diisi")
                                                .matches(/^[0-9]+$/, "Harus berupa angka")
                                                .max(16, "Maksimal 16 digit"),
          "paket_kamar": Yup.object({
              "id": Yup.number().required("Harus pilih starting & Tipekamar"),
              "kamar_name": Yup.string().required("Harus pilih starting & Tipekamar"),
          }),
          "coupons": Yup.array()
              .of(Yup.number().integer("Id Kupon harus berupa angka"))
              .required("Kupon harus tersedia")
          })
      ),
    })
  })

  const formPemesanPaket = useFormik({
    initialValues: {
      paket: {
        id: null
      },
      pemesan: {
        id: null,
        guard_type: 'admin',
        branch: {
          name: null,
          bg_color: null,
          text_color: null
        }
      }
    },
    validationSchema: Yup.object().shape({
      paket: Yup.object().shape({
        id: Yup.number().required('ID Paket harus diisi'),
      }),
      pemesan: Yup.object().shape({
        id: Yup.number().required('ID Pemesan harus diisi'),
        guard_type: Yup.string().required('Tipe Guard harus diisi'),
        branch: Yup.object().shape({
          name: Yup.string().required('Nama Cabang harus diisi'),
          bg_color: Yup.string().required('Warna Latar harus diisi'),
          text_color: Yup.string().required('Warna Teks harus diisi'),
        }),
      }),
    }),
    isValid: (values) => {
      return values.pemesan.id !== null && values.paket.id !== null;
    }
  })

  const orderJamaahForm = useFormik({
    initialValues: {
      order_kamars: []
    },
    validationSchema: Yup.object({
      order_kamars: Yup.array().of(
        Yup.object({
          "jamaah_title": Yup.string().required("Harus pilih salah satu title"),
          "jamaah_name": Yup.string().required("Nama Jaamaah harus diisi"),
          "jamaah_identity_number": Yup.string().required("NIK harus diisi")
                                                .matches(/^[0-9]+$/, "Harus berupa angka")
                                                .max(16, "Maksimal 16 digit"),
          "paket_kamar": Yup.object({
              "id": Yup.number().required("Harus pilih starting & Tipekamar"),
              "kamar_name": Yup.string().required("Harus pilih starting & Tipekamar"),
          }),
        })
      )
    }),
    enableReinitialize: true
  })

  const additionalsFormik = useFormik({
    initialValues: {
      paket_additionals: []
    },
    validationSchema: Yup.object({
      paket_additionals: Yup.array().of(
        Yup.object({
          id: Yup.number().required("Harus pilih additional"),
          jamaah: Yup.array().of(
            Yup.object({
              name: Yup.string().required("Harus pilih jamaah"),          
              ktp_nik: Yup.string().required("Harus pilih jamaah"),
              quantity: Yup.number().required("Harus tentukan banyaknya additional"),
            })
          )
        })
      )
    })
  })


  const handleClosePopup = () => {
    handleClose()
    setTimeout(() => {
      formPemesanPaket.resetForm()
      bookingForm.resetForm()
      orderJamaahForm.resetForm()
      additionalsFormik.resetForm()
      setCurrentStep(0)
    }, 500)
  }

  const handleNextStep = () => {
    
    if(currentStep !== 3) {
      setCurrentStep(currentStep + 1)
    }

    if(currentStep === 0) {
      bookingForm.setFieldValue('paket', formPemesanPaket.values.paket)
      bookingForm.setFieldValue('pemesan', formPemesanPaket.values.pemesan)

      setTimeout(() => {
        bookingForm.setFieldTouched('paket', 1)
        bookingForm.setFieldTouched('pemesan', 1)
      })

    }

    if(currentStep === 1) {
      bookingForm.setFieldValue('order_kamars', orderJamaahForm.values.order_kamars)

      setTimeout(() => {
        bookingForm.setFieldTouched('order_kamars', 1)
      })

    }

    if(currentStep === 2) {
      handleMergeOrderJamaahAndAdditional()
      setIsMergeAdditional(true)
    }

    if(currentStep === 3) {
      // console.log(bookingForm.errors, bookingForm.values)
      handleStoreBooking()
    }

  }

  const handleMergeOrderJamaahAndAdditional = () => {
    const additionals = additionalsFormik.values.paket_additionals;

    orderJamaahForm.setFieldValue("order_kamars", orderJamaahForm.values.order_kamars?.map(jamaah => {
      const nikToCheck = jamaah?.jamaah_identity_number;

      if (nikToCheck) {
        const matchingAdditionals = additionals.filter(additional =>
          additional.jamaah.some(jamaahAdditional => jamaahAdditional.ktp_nik === nikToCheck)
        );
        
        if (matchingAdditionals.length > 0) {
          // Menyatukan semua additional yang cocok ke orderJamaah
          return {
            ...jamaah,
            paket_additionals: matchingAdditionals.map(additionalData => {
              return {
                id: additionalData.id,
                quantity: additionalData.jamaah.find(jamaahAdditional => jamaahAdditional.ktp_nik === nikToCheck)?.quantity,
                note: additionalData.jamaah.find(jamaahAdditional => jamaahAdditional.ktp_nik === nikToCheck)?.note
              }
            }),
          };
        }
      }

      return jamaah;
    }));

    orderJamaahForm.setFieldTouched("order_kamars", 1)
  }

  
  const handleStoreBooking = async () => {
    console.log(bookingForm.values)
    setIsLoadingStoreBooking(true)
    const response = await handleStoreBookingAPI(bookingForm.values)
    const { status, data } = response
    setIsLoadingStoreBooking(false)

    if(status === 200 || status === 201) {
      dispatch(handleSuccess(response))
      handleClosePopup()
      router.push(`/booking/detail/${data.data.order_number}`)
    } else {
      dispatch(handleError(data))
    }

  }


  const handleGetCoupons = async () => {
    const params = {
      code_coupon: null,
      package_id: formPemesanPaket.values.paket.id
    }
    const response = await PaketCouponAPI.getCouponForOrder(params)
    const { status, data } = response

    if(status === 200) {
      setCouponList(data.data)
    } else {
      dispatch(handleError(data))
    }

  }

  useEffect(() => {
    if(isMergeAdditional) {
      bookingForm.setFieldValue('order_kamars', orderJamaahForm.values.order_kamars)

      setTimeout(() => {
        bookingForm.setFieldTouched('order_kamars', 1)
      })

      setIsMergeAdditional(false)
    }
  }, [orderJamaahForm.values])

  useEffect(() => {

    if(formPemesanPaket.values.paket.id != null) {
      handleGetCoupons()
    }

  }, [formPemesanPaket.values.paket.id])

  return (
    <>
      <Popup
        open={open}
        handleClose={() => {
          handleClosePopup();
        }}
        title="Tambah Booking"
        height="650px"
        width="1000px"
      >
        
          <div className="flex gap-5">
            <div className="border rounded-2xl py-6 px-4">
              <Steppers 
                items={stepItems}
                current={currentStep}
                labelPlacement={"vertical"}
                direction="vertical"
                />
            </div>
            <div className="border rounded-2xl w-full h-full">

              <div className="p-4">
                {currentStep === 0 && (
                  <>
                    <Scroll height="422px">
                      <Step1
                        formData={formPemesanPaket}
                        setPackageSelected={setPackageSelected}
                        />
                    </Scroll>
                  </>
                )}
                {currentStep === 1 && (
                  <>
                    <Step2
                      packageID={formPemesanPaket.values.paket.id}
                      bookingForm={bookingForm}
                      formData={orderJamaahForm}
                      additionalsFormik={additionalsFormik}
                      packageSelected={packageSelected}
                      />
                  </>
                )}
                {currentStep === 2 && (
                  <>
                    <Scroll height="422px">
                      <Step3 
                        bookingForm={bookingForm}
                        packageID={formPemesanPaket.values.paket.id}
                        additionalsFormik={additionalsFormik}
                        packageSelected={packageSelected}
                        />
                    </Scroll>
                  </>
                )}
                {currentStep === 3 && (
                  <>
                    <Scroll height="422px">
                      <Step4 
                        bookingForm={bookingForm}
                        packageID={formPemesanPaket.values.paket.id}
                        additionalsFormik={additionalsFormik}
                        handleSetTotalPrice={(totalPrice) => setTotalPrice(totalPrice)}
                        couponList={couponList}
                        setCouponList={setCouponList}
                        />
                    </Scroll>
                  </>
                )}
              </div>
              
              <div className="border-t flex justify-between p-4 gap-4">
                {/* {currentStep !== 0 && (
                  <>
                    <div></div>
                    <Buttons
                      text="Kembali"
                      customClass="btn-primary btn-outline btn-large"
                      onClick={() => {
                        setCurrentStep(currentStep - 1)
                      }}  
                      />
                  </>
                )} */}
                {currentStep === 0 && (
                  <>
                    <div></div>
                    <div className="flex gap-4">
                      <Buttons
                        text="Selanjutnya"
                        customClass="btn-primary btn-large"
                        onClick={handleNextStep}  
                        disabled={!(formPemesanPaket.isValid && formPemesanPaket.dirty)}
                        />
                    </div>
                  </>
                )}
                {currentStep === 1 && (
                  <>
                    <div></div>
                    <div className="flex gap-4">
                      <Buttons
                        text="Kembali"
                        customClass="btn-primary btn-outline btn-large"
                        onClick={() => {
                          setCurrentStep(currentStep - 1)
                        }}  
                        />
                      <Buttons
                        text="Selanjutnya"
                        customClass="btn-primary btn-large"
                        onClick={handleNextStep}  
                        disabled={!(orderJamaahForm.isValid && orderJamaahForm.dirty)}
                        />
                    </div>
                  </>
                )}
                {currentStep === 2 && (
                  <>
                    <div></div>
                    <div className="flex gap-4">
                      <Buttons
                        text="Kembali"
                        customClass="btn-primary btn-outline btn-large"
                        onClick={() => {
                          setCurrentStep(currentStep - 1)
                        }}  
                        />
                      <Buttons
                        text="Selanjutnya"
                        customClass="btn-primary btn-large"
                        onClick={handleNextStep}  
                        // disabled={!(additionalsFormik.isValid && additionalsFormik.dirty)}
                        />
                    </div>
                  </>
                )}
                {currentStep === 3 && (
                  <>
                    <div className="flex flex-col mb-1">
                      <div className="font-semibold">
                        Total
                      </div>
                      <div className="green_bold_text text-xl">
                        {toCurrencyFormat(totalPrice, packageSelected?.currency, false)}
                      </div>

                    </div>
                    <div className="flex gap-4">
                    <Buttons
                        text="Kembali"
                        customClass="btn-primary btn-outline btn-large"
                        onClick={() => {
                          setCurrentStep(currentStep - 1)
                        }}  
                        />
                    <Buttons
                      text="Booking"
                      customClass="btn-primary btn-large"
                      onClick={handleNextStep}  
                      loading={isLoadingStoreBooking}
                      disabled={!(bookingForm.isValid && bookingForm.dirty)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

      </Popup>
    </>
  )
};

export default CreateNewBooking;