import React, { useState } from "react";
import "react-credit-cards/es/styles-compiled.css";
import { makeStyles } from "@material-ui/core/styles";
import Checklist from "../../assets/icons/Checklist-card.svg";
import BackgroundBottom from "../../assets/icons/Background-bottom-card.svg";
import BackgroundTop from "../../assets/icons/Background-top-card.svg";
import IconEdit from 'assets/icons/icon-edit-maroon.svg'

import PropTypes from "prop-types";

import { formatRekening } from "utils/helpers";
import Buttons from "components/buttons";

const RekeningCard = ({ rekening, name, bank, handleEdit, account }) => {
  const [date, SetDate] = useState("11/25");
  const [cvc, SetCvc] = useState("");
  const [focus, SetFocus] = useState("");
  const [expiry, setExpiry] = useState("11/25");
  const [pemegangKartu, setPemegangKartu] = useState("Pemegang Kartu");

  const useStyles = makeStyles({
    card_container: {
      "& .rccs": {
        width: "414px",
        height: "249px",
      },

      "& .rccs__card--front": {
        width: "414px",
        height: "249px",
        background:
          "linear-gradient(71.03deg, #E82320 23.31%, #FA504D 135.49%)",
        borderRadius: "16",
      },

      "& .rccs__number": {
        height: "32px",
        left: "24px",
        top: "120px",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "24px",
        lineHeight: "32px",
        color: "#FFFFFF",
      },

      "& .rccs__name": {
        left: "24px",
        top: "76px",
        fontFamily: '"General Sans", sans-serif',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "24px",
        color: "#fff !important",
      },

      "& .rccs--filled": {
        opacity: "100% !important",
      },

      "& .rccs__chip": {
        left: "2.67px",
        top: "2.67px",
        backgroundImage: "none",
        width: "26.67px",
        height: "26.67px",
        borderRadius: "50%",
      },
      image: {
        alignItems: "center",
        left: "27.08%",
        right: "27.08%",
        top: "33.33%",
        bottom: "33.34%",
        display: "static",
      },
    },

    bank: {
      position: "absolute",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      top: "169px",
      right: "24px",
      fontFamily: '"General Sans", sans-serif',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
    },

    pemegang_kartu: {
      position: "absolute",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      top: "58px",
      left: "24px",
      fontFamily: '"General Sans", sans-serif',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
    },

    bankName: {
      position: "absolute",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      top: "193px",
      right: "24px",
      fontFamily: '"General Sans", sans-serif',
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "20px",
      color: "#ffff",
    },

    card_chip: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "20px",
      marginLeft: "24px",
      marginRight: "24px",

      "& .rccs__chip": {
        position: "static !important",
      },

      "& .card_text": {
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "20px",
      },
    },

    backgroundBottom: {
      width: "321.54px",
      height: "298.34px",
      left: "35.54px",
      top: "135.21px",
      zIndex: "-1",
      position: "static",
    },

    backgroundTop: {
      width: "305.13px",
      height: "325.49px",
      left: "134.63px",
      top: "-93px",
      zIndex: "-1",
      transform: "matrix(1, 0, -0.08, 1, 0, 0)",
      position: "inherit",
    },
  });
  const classes = useStyles();

  return (
    <>
      <div className={classes.card_container}>
        <div className="rccs">
          <div className="rccs__card--front">
            <div className={classes.card_chip}>
              <div className="rccs__chip">
                <img
                  src={Checklist}
                  alt="checklist"
                  className={classes.image}
                />
              </div>
              <div className="card_text">
                <img 
                  className="cursor-pointer" 
                  src={IconEdit}  
                  onClick={() => handleEdit(account)}
                  />
              </div>
            </div>
            <div>
              <img
                src={BackgroundBottom}
                alt="background bottom"
                className={classes.backgroundBottom}
              />
            </div>
            <img
              src={BackgroundTop}
              alt="background Top"
              className={classes.backgroundTop}
            />
            <div>
              <div className={classes.pemegang_kartu}>Atas Nama</div>
              <div className="rccs__name rccs--filled">{name}</div>
            </div>
            <div className="rccs__number rccs--filled">{formatRekening(rekening)}</div>
            <div className={classes.bank}>Bank</div>
            {/* <div className={classes.pemegang_kartu}>Pemegang Kartu</div> */}
            <div className={classes.bankName}>{bank}</div>
            {/* <div className="rccs__name  rccs--filled">{name}</div> */}
          </div>
        </div>
      </div>
    </>
  );
};

RekeningCard.propTypes = {
  rekening: PropTypes.string,
  name: PropTypes.string,
  bank: PropTypes.string,
  account: PropTypes.object,
};

RekeningCard.defaultProps = {
  rekening: "3303 2323 1233 1523",
  name: "Dimas Andreyan Prana Putra",
  bank: "Mandiri",
  account: {},
};

export default RekeningCard;
