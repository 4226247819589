import { getExchangeRateService } from "utils/api/exchangeRateAPI";
import { handleError } from "../errorGeneral";

export const SET_LOADING = "fee/SET_LOADING";
export const SET_FETCH_EXCHANGE_RATE = "fee/SET_FETCH_EXCHANGE_RATE"

export const setLoading = (payload) => ({
    type: SET_LOADING,
    payload,
});

export const setExchangeRate = (payload) => ({
    type: SET_FETCH_EXCHANGE_RATE,
    payload,
});


export const handleGetExchangeRate = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const res = await getExchangeRateService();
            dispatch(setLoading(false));
            
            if (res.status === 200) {
                dispatch(setExchangeRate(res.data.data));
            } else {
                dispatch(handleError(res.data));
            }
        } catch (error) {
            dispatch(handleError(error));
        }
    }
}