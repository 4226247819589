import {
    SET_FETCH_EXCHANGE_RATE
} from "../../actions/exchangeRateUSD";

const initialState = {
    isLoading: false,
    exchangeRate: {},
};
  
export default (state = initialState, { payload, type }) => {
    switch (type) {
        case SET_FETCH_EXCHANGE_RATE:
            return {
                ...state,
                exchangeRate: payload
            };
        default:
            return state;
    }
}