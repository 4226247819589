import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Table } from "antd";

import MoslemeTable from "../../../components/Table";
import Badge from "../../../components/badge";
import Buttons from "../../../components/buttons";

import AvatarRed from "../../../assets/images/Avatar-ikhwan-red.svg";

import { handleSendFollowup } from "utils/api/booking";

import IconWhatsapp from "../../../assets/icons/whatsapp.svg";
import IconVisible from "../../../assets/icons/visible.svg";

import IconSistemMaroon from 'assets/icons/icon-sistem-maroon.svg'
import IconAdminMaroon from 'assets/icons/icon-admin-maroon.svg'

import { Eye, Trash, EmptyWalletRemove, CloseSquare, InfoCircle } from "iconsax-react";
import BookingUmrohAction from "stores/actions/booking/umroh";

import { handleGetBookingList } from "utils/api/booking";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import Avatar from "components/avatar";
import { IDRFormater, USDFormater, toCapital } from "utils/helpers";
import Popup from "components/popup";
const BookingTable = ({ 
  keyword, 
  searchDebounce, 
  tabActiv,
  bookingList, 
  totalBookingList,
  currentPage,
  pageSize,
  isLoading,
  handleChangePage,
  handleChangePageSize,
  handleCancelOrder
}) => {
  const router = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;

  const [openCancelReason, setOpenCancelReason] = useState(false)
  const [cancelReason, setCancelReason] = useState('')

  const [sendingFollowup, setSendingFollowup] = useState({
    loading: false,
    orderNumber: null,
    key: null
  })

  // Expaneble
  const [isExpand, setIsExpand] = useState(true);

  const handleClickFollowup = async (orderNumber, key) => {
    setSendingFollowup({
      loading: true,
      orderNumber: orderNumber,
      key: key
    })
    const res = await handleSendFollowup(orderNumber, key)
    setSendingFollowup({
      loading: false,
      orderNumber: null,
      key: null
    })

    if (res.status === 200) {
      dispatch(handleSuccess(res))
    } else {
      dispatch(handleError(res.data))
    }

  }

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: totalBookingList,
    current: currentPage,
    pageSize: pageSize,
    defaultPageSize: pageSize,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  const columns = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    
    {
      title: "Foto",
      width: '32px',
      render: (order) => (
          <>
            {order.pemesan ? (
              <>
                <Avatar 
                  value={order.pemesan}
                  />
              </>
            ) :'-'}
          </>
      ) 
    },
    {
      title: "Nama Pemesan",
      render: (order) => (
        <>
          {order.pemesan ? (
            <>
              <div className="mb-1">
               {order.pemesan.title ? `${toCapital(order.pemesan.title.toUpperCase())}.` : ''} {order.pemesan.name.toUpperCase()}
              </div>
              <div className="flex gap-1">
                {order.guard_type !== 'admin' &&(
                  <>
                  
                    <Badge 
                      label={order.pemesan.role_name?.toUpperCase()}
                      backgroundColor={order.pemesan.color_bg_role}
                      color={order.pemesan.color_text_role}
                      />
                    <Badge 
                      label={order.pemesan.cabang_name?.toUpperCase()}
                      backgroundColor={order.pemesan.color_bg_cabang}
                      color={order.pemesan.color_text_cabang}
                      />
                  </>
                )}
                {order.guard_type === 'admin' &&(
                  <>

                    <Badge 
                    label={order.pemesan.nama_role?.toUpperCase()}
                    backgroundColor={order.pemesan.color_bg_role}
                    color={order.pemesan.color_text_role}
                    />
                    <Badge 
                    label={order.pemesan.nama_cabang?.toUpperCase()}
                    backgroundColor={order.pemesan.color_bg_cabang}
                    color={order.pemesan.color_text_cabang}
                    />
                  </>
                )}
              </div>
            </>
          ) : '-'}
        </>
      )
    },
    {
      title: "Kode Tagihan",
      dataIndex: 'order_number',
      render: (record) => (
        <>
          {record}
        </>
      )
    },
    {
      title: "Status",
      // dataIndex: 'name_payment_status',
      render: (record) => (
        <>
          {record.name_payment_status === 'Belum DP' && (
            <Badge 
              backgroundColor={'#F7A399'}
              color={"#FE0000"}
              label={record.name_payment_status.toUpperCase()} />
          )}
          {record.name_payment_status === 'Sudah DP' && (
            <Badge 
              backgroundColor={'#BBDEFB'}
              color={"#0000FE"}
              label={`SUDAH DP`} />
          )}
          {record.name_payment_status === 'Belum Lunas' && (
            <Badge 
              backgroundColor={'#FEDCED'}
              color={"#F20089"}
              label={`BELUM LUNAS`} />
          )}
          {record.name_payment_status === 'Lunas' && (
            <Badge 
              backgroundColor={'#92E6A7'}
              color={"#1A7431"}
              label={record.name_payment_status.toUpperCase()} />
          )}
          {record.name_payment_status === 'Di Batalkan' && (
            <Badge 
              backgroundColor={'#CED4DA'}
              color={"#6C757D"}
              label={<>
                <div className="flex justify-center items-center gap-2">
                  <span>
                    {`DIBATALKAN`}
                  </span>
                  <div>
                    {record.cancel_by > 0 ? <img src={IconAdminMaroon} /> : <img src={IconSistemMaroon} />}
                  </div>
                </div>
              </>} />
          )}
        </>
      )
    },
    {
      title: "Follow Up",
      render: (order) => (
        <>
          <div className="flex flex-row gap-1">
            <Buttons
              text="1"
              customClass="btn-brown-light btn-small !w-fit"
              icon={IconWhatsapp}
              onClick={() => handleClickFollowup(order.order_number, 'dp')}
              loading={sendingFollowup.loading && sendingFollowup.key === 'dp' && sendingFollowup.orderNumber === order.order_number}
              loadingIcon={true}
              disabled={(order.payment_status !== 0 || order.payment_status === 4) || !order.payment_dp}
            />
            <Buttons
              text="2"
              customClass="btn-brown-light btn-small !w-fit"
              icon={IconWhatsapp}
              onClick={() => handleClickFollowup(order.order_number, 'payment')}
              loading={sendingFollowup.loading && sendingFollowup.key === 'payment' && sendingFollowup.orderNumber === order.order_number}
              disabled={(order.payment_status === 0 || order.payment_status === 4 || order.payment_status === 3)}
            />
          </div>
        </>
      ),
    },
    {
      title: "Tanggal Booking",
      dataIndex: "created_at",
      render: (order) => (
        <>
          {moment(order).format("dddd, DD MMMM YYYY HH:mm")} WIB
        </>
      )
    },
    {
      title: "Paket",
      dataIndex: "paket",
      render: (paket, order) => (
        <>
          <div>
            <div className="font-semibold">{paket.judul_paket}</div>
            <div>Keberangkatan <span className="font-semibold">{moment(paket.jadwal_keberangkatan).format("DD MMMM YYYY")}</span></div>
          </div>
          <div className="action">
            <div className="action-content">
              <div className="action-content-item">
                <Eye
                  className="cursor-pointer"
                  size="15"
                  color="#141414"
                  onClick={() => router.push(`/tagihan/detail/${order.order_number}`)}
                />
              </div>
              {order.name_payment_status === 'Di Batalkan' && (
                <div className="action-content-item">
                  <InfoCircle 
                    size="15"
                    color="#141414"
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenCancelReason(true)
                      setCancelReason(order.cancel_reason)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      ),
    },
];

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => {
      return (
        <div>
          <ul className="w-full flex py-3 border-l">
            <li className="w-[120px]">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">
                  Total Tagihan
                </li>
              </ul>
            </li>
            <li className="w-[120px]">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">
                  Kekurangan
                </li>
              </ul>
            </li>
            <li className="w-[120px]">
              <ul>
                <li className="border-b pb-3 font-bold">
                  Jumlah Jamaah
                </li>
              </ul>
            </li>
            <li className="w-[34rem]">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">Tipe Kamar</li>
              </ul>
            </li>
          </ul>
          <ul className="w-full flex items-center pb-3 border-l">
            <li className="w-[120px]">
              <ul>
                <li className="pl-5 pt-2">
                  <div>
                    {record.paket.currency === 'IDR' ? (
                      <>
                        {IDRFormater(record.total_price)}
                      </>
                    ) : (
                      <>
                        {USDFormater(record.total_price)}
                      </>
                    )}
                  </div>
                </li>
              </ul>
            </li>
            <li className="w-[120px]">
              <ul>
                <li className="pl-5 pt-2">
                  <div>
                    {record.paket.currency === 'IDR' ? (
                      <>
                        {IDRFormater(record.remaining_payment_amount)}
                      </>
                    ) : (
                      <>
                        {USDFormater(record.remaining_payment_amount)}
                      </>
                    )}
                  </div>
                </li>
              </ul>
            </li>
            <li  className="w-[120px]">
              <ul>
                <li>
                  <>
                    <div className="inline-flex w-full gap-2 border rounded-lg border-gray-4 py-1.5 px-3">
                      <img src={IconVisible}/>
                      <span>{record.jumlah_pax} Pax</span>
                    </div>
                  </>
                </li>
              </ul>
            </li>
            <li className="w-[34rem]">
              <ul>
                <li className="pl-5 pt-2 flex gap-2">
                  {
                    !!record.jumlah_quad_terpakai ? 
                    <Badge
                      backgroundColor="#92E6A7"
                      color="#141414"
                      label={
                        <div className="flex items-center">
                          <div className="text-[#1B662E] font-semibold">
                            Quad <span className="font-normal">({record.jumlah_quad_terpakai} Pax)</span>
                          </div>
                        </div>
                      }
                    />
                    : ''
                  }
                  {
                    !!record.jumlah_triple_terpakai ? 
                    <Badge
                      backgroundColor="#92E6A7"
                      color="#141414"
                      label={
                        <div className="flex items-center">
                          <div className="text-[#1B662E] font-semibold">
                            Triple <span className="font-normal">({record.jumlah_triple_terpakai} Pax)</span>
                          </div>
                        </div>
                      }
                    />
                    : ''
                  }
                  {
                    !!record.jumlah_double_terpakai ? 
                    <Badge
                      backgroundColor="#92E6A7"
                      color="#141414"
                      label={
                        <div className="flex items-center">
                          <div className="text-[#1B662E] font-semibold">
                            Double <span className="font-normal">({record.jumlah_double_terpakai} Pax)</span>
                          </div>
                        </div>
                      }
                    />
                    : ''
                  }
                  {
                    !!record.jumlah_single_terpakai ? 
                    <Badge
                      backgroundColor="#92E6A7"
                      color="#141414"
                      label={
                        <div className="flex items-center">
                          <div className="text-[#1B662E] font-semibold">
                            Single <span className="font-normal">({record.jumlah_single_terpakai} Pax)</span>
                          </div>
                        </div>
                      }
                    />
                    : ''
                  }
                </li>
              </ul>
            </li>
          </ul>
        </div>
      );
    },
    rowExpandable: (record) => record.booking_code !== "Not Expandable",
  };


  return (
    <div className="mx-[-14px]">
      <Popup
        open={openCancelReason}
        handleClose={() => {
          setCancelReason('')
          setOpenCancelReason(false)
        }}
        title={`Alasan dibatalkan`}
        width="550px"
        containerClass=""
        children={
          <>
            <div className="p-4 border rounded-2xl">
              {cancelReason}
            </div>
          </>
        }
        
      />
      {/* Table */}
      <MoslemeTable
        loading={isLoading}
        expandable={isExpand && expandable}
        columns={columns}
        data={bookingList}
        withSelection={true}
        selectionType={'checkbox'}
        pagination={pagination}
        customPaginationClass="pr-4"
        customClass="mt-4"
        onPageChange={(page) => handleChangePage(page)}
        onSizeChange={(currentpage, pageSize) => {
          handleChangePage(currentpage);
          handleChangePageSize(pageSize);
        }}
      />
    </div>
  );
};

export default React.memo(BookingTable);
