import React, { useEffect, useState } from "react";

import AgenCabangDaerah from "./components/AgenCabangDaerah";
import AgenFreelance from "./components/AgenFreelance";
import AgenPusat from "./components/AgenPusat";
import CabangDaerah from "./components/CabangDaerah";
import "./styles/Fee.scss";
import { useDispatch, useSelector } from "react-redux";

import IconEdit from "assets/icons/edit.svg";
import {
  getPaketBesicFee,
  updatedPaketBesicFee,
} from "stores/actions/paket/fee";
import { useParams } from "react-router-dom";
import Inputs from "components/form/inputs";
import Buttons from "components/buttons";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import InputsCurrency from "components/form/inputsCurrency";
import Tab from "components/tab";

const JamaahAlumni = () => {
  const [clickedButton, setClickedButton] = useState("");
  const { data_besic } = useSelector((state) => state.paketFee);
  const [dataBesicState, setDataBesicState] = useState({});
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const disptach = useDispatch();

  const [activeTab, setActiveTab] = useState(1);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const { id } = useParams();

  const handleButtonClick = (buttonText) => {
    setClickedButton(buttonText);
  };

  useEffect(() => {
    if (data_besic) {
      let _temp = {
        ...data_besic.data,
        is_fee_agent_cabang_alumni_dp: false,
        is_fee_agent_cabang_alumni_full: false,
        is_fee_agent_freelancer: false,
        is_fee_agent_pusat_alumni_dp: false,
        is_fee_agent_pusat_alumni_full: false,
        is_fee_cabang_alumni_dp: false,
        is_fee_cabang_alumni_full: false
      };

      setDataBesicState(_temp);
    }
  }, [data_besic]);

  useEffect(() => {
    disptach(getPaketBesicFee(id));
  }, []);
  const moneyFormatter = (val) => {
    return new Intl.NumberFormat("en-DE").format(val);
  };

  useEffect(() => {
    if(auth.user?.access_data === 'all_branches') {
      setClickedButton('CabangDaerah')
    } else {
      setClickedButton('AgenCabangDaerah')
    }
  }, [])

  const getContent = () => {
    switch (clickedButton) {
      case "AgenPusat":
        return <AgenPusat />;
      case "AgenFreelancer":
        return <AgenFreelance />;
      case "CabangDaerah":
        return <CabangDaerah />;
      case "AgenCabangDaerah":
        return <AgenCabangDaerah />;
      default:
        return null;
    }
  };

  const handleEdit = (val) => {
    let _temp = { ...dataBesicState };
    if (val === "fee_agent_freelancer") {
      _temp["is_fee_agent_freelancer"] = !_temp["is_fee_agent_freelancer"];
      setDataBesicState(_temp);
    }

    if (val === "fee_agent_pusat_alumni_dp") {
      _temp["is_fee_agent_pusat_alumni_dp"] = !_temp["is_fee_agent_pusat_alumni_dp"];
      setDataBesicState(_temp);
    }

    if (val === "fee_agent_pusat_alumni_full") {
      _temp["is_fee_agent_pusat_alumni_full"] = !_temp["is_fee_agent_pusat_alumni_full"];
      setDataBesicState(_temp);
    }

    if (val === "fee_cabang_alumni_dp") {
      _temp["is_fee_cabang_alumni_dp"] = !_temp["is_fee_cabang_alumni_dp"];
      setDataBesicState(_temp);
    }

    if (val === "fee_cabang_alumni_full") {
      _temp["is_fee_cabang_alumni_full"] = !_temp["is_fee_cabang_alumni_full"];
      setDataBesicState(_temp);
    }

    if (val === "fee_agent_cabang_alumni_dp") {
      _temp["is_fee_agent_cabang_alumni_dp"] = !_temp["is_fee_agent_cabang_alumni_dp"];
      setDataBesicState(_temp);
    }

    if (val === "fee_agent_cabang_alumni_full") {
      _temp["is_fee_agent_cabang_alumni_full"] = !_temp["is_fee_agent_cabang_alumni_full"];
      setDataBesicState(_temp);
    }
  };

  const handleUpdatedBesic = (data) => {
    setIsLoadingAction(true);

    const {
      is_fee_agent_cabang_alumni_dp,
      is_fee_agent_cabang_alumni_full,
      is_fee_agent_freelancer,
      is_fee_agent_pusat_alumni_dp,
      is_fee_agent_pusat_alumni_full,
      is_fee_cabang_alumni_dp,
      is_fee_cabang_alumni_full,
      ...rest
    } = data;

    disptach(
      updatedPaketBesicFee(
        id,
        rest,
        (message) => {
          setIsLoadingAction(false);
          disptach(getPaketBesicFee(id));

          disptach(handleSuccess(message));
        },
        (error) => {
          setIsLoadingAction(false);
          disptach(handleErrorBE(error));
        }
      )
    );
  };

  const handleChange = (value, name) => {
    setDataBesicState({
      ...dataBesicState,
      [name]: value,
    });
  };

  return (
    <>
      <div className="border-t">
        <div className="flex flex-column flex-start px-4 py-6">
          <h1 className="text-[#000] text-lg font-semibold">Fee Dasar</h1>
        </div>

        <div className="grid grid-cols-4 gap-4 px-4">

          {auth.user?.access_data === 'all_branches' && (
            <>
              <div
                className={`rounded-bl-2xl rounded-br-2xl cursor-pointer ${
                  clickedButton === "CabangDaerah"
                    ? "highlight-shadow"
                    : "soft-shadow"
                } `}
                onClick={() => handleButtonClick("CabangDaerah")}
              >
                <div
                  className={`p-4 rounded-tl-2xl rounded-tr-2xl ${
                    clickedButton === "CabangDaerah" ? "bg-[#FFDCDC]" : "bg-white"
                  }`}
                >
                  <div className="bg-[#CCDCFF] px-4 py-[6px] rounded-lg w-fit">
                    <div className="font-semibold text-[#60308C]">Cabang</div>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="flex flex-row justify-between items-center px-4 py-6 w-full relative">
                  <div className="bg-[#FAAB3633]/[0.2] py-[2px] px-1 rounded-br-lg text-sm text-[#FAAB36] absolute top-0 left-0">
                    Fee DP
                  </div>
                  {dataBesicState?.is_fee_cabang_alumni_dp ? (
                    <div className="w-full h-[30px]  relative my-[8px]">
                      <InputsCurrency
                        id="test"
                        placeholder="Masukkan Harga"
                        name="fee_cabang_alumni_dp"
                        value={dataBesicState?.fee_cabang_alumni_dp}
                        onChange={(e) => handleChange(e, "fee_cabang_alumni_dp")}
                      />
                      <Buttons
                        text="Simpan"
                        customClass="btn-primary btn-small  absolute top-2 right-2 !w-fit"
                        loading={isLoadingAction}
                        onClick={() => handleUpdatedBesic(dataBesicState)}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="font-semibold text-[16px]">
                        Rp{" "}
                        {dataBesicState?.fee_cabang_alumni_dp &&
                          moneyFormatter(dataBesicState?.fee_cabang_alumni_dp)}
                      </div>
                      {permissions?.includes("update_package") && (
                        <>
                           <img
                              src={IconEdit}
                              alt="edit"
                              width={24}
                              height={24}
                              className="cursor-pointer"
                              onClick={() => handleEdit("fee_cabang_alumni_dp")}
                            />
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="divider"></div>
                <div className="flex flex-row justify-between items-center px-4 py-6 w-full relative">
                  <div className="bg-[#4590F43D]/[0.2] py-[2px] px-1 rounded-br-lg text-sm text-[#4590F4] absolute top-0 left-0">
                    Fee Pelunasan
                  </div>
                  {dataBesicState?.is_fee_cabang_alumni_full ? (
                    <div className="w-full h-[30px]  relative my-[8px]">
                      <InputsCurrency
                        id="test"
                        placeholder="Masukkan Harga"
                        name="fee_cabang_alumni_full"
                        value={dataBesicState?.fee_cabang_alumni_full}
                        onChange={(e) => handleChange(e, "fee_cabang_alumni_full")}
                      />
                      <Buttons
                        text="Simpan"
                        customClass="btn-primary btn-small  absolute top-2 right-2 !w-fit"
                        loading={isLoadingAction}
                        onClick={() => handleUpdatedBesic(dataBesicState)}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="font-semibold text-[16px]">
                        Rp{" "}
                        {dataBesicState?.fee_cabang_alumni_full &&
                          moneyFormatter(dataBesicState?.fee_cabang_alumni_full)}
                      </div>
                      {permissions?.includes("update_package") && (
                        <>
                           <img
                              src={IconEdit}
                              alt="edit"
                              width={24}
                              height={24}
                              className="cursor-pointer"
                              onClick={() => handleEdit("fee_cabang_alumni_full")}
                            />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div
                className={`rounded-bl-2xl rounded-br-2xl cursor-pointer ${
                  clickedButton === "AgenPusat" ? "highlight-shadow" : "soft-shadow"
                } `}
                onClick={() => handleButtonClick("AgenPusat")}
              >
                <div
                  className={`p-4 rounded-tl-2xl rounded-tr-2xl ${
                    clickedButton === "AgenPusat" ? "bg-[#FFDCDC]" : "bg-white"
                  }`}
                >
                  <div className="bg-[#FCDE9C] px-4 py-[6px] rounded-lg w-fit">
                    <div className="font-semibold text-[#FE9A00]">Agen Pusat</div>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="flex flex-row justify-between items-center px-4 py-6 w-full relative">
                  <div className="bg-[#FAAB3633]/[0.2] py-[2px] px-1 rounded-br-lg text-sm text-[#FAAB36] absolute top-0 left-0">
                    Fee DP
                  </div>
                  {dataBesicState?.is_fee_agent_pusat_alumni_dp ? (
                    <div className="w-full h-[30px] relative my-[8px]">
                      <InputsCurrency
                        id="test"
                        placeholder="Masukkan Harga"
                        name="fee_agent_pusat_alumni_dp"
                        value={dataBesicState?.fee_agent_pusat_alumni_dp}
                        onChange={(e) => handleChange(e, "fee_agent_pusat_alumni_dp")}
                      />
                      <Buttons
                        text="Simpan"
                        customClass="btn-primary btn-small absolute top-2 right-2 !w-fit"
                        loading={isLoadingAction}
                        onClick={() => handleUpdatedBesic(dataBesicState)}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="font-semibold text-[16px]">
                        Rp{" "}
                        {dataBesicState?.fee_agent_pusat_alumni_dp &&
                          moneyFormatter(dataBesicState?.fee_agent_pusat_alumni_dp)}
                      </div>
                      {permissions?.includes("update_package") && (
                        <>
                           <img
                            src={IconEdit}
                            alt="edit"
                            width={24}
                            height={24}
                            className="cursor-pointer"
                            onClick={() => handleEdit("fee_agent_pusat_alumni_dp")}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="divider"></div>
                <div className="flex flex-row justify-between items-center px-4 py-6 w-full relative">
                  <div className="bg-[#4590F43D]/[0.2] py-[2px] px-1 rounded-br-lg text-sm text-[#4590F4] absolute top-0 left-0">
                    Fee Pelunasan
                  </div>
                  {dataBesicState?.is_fee_agent_pusat_alumni_full ? (
                    <div className="w-full h-[30px] relative my-[8px]">
                      <InputsCurrency
                        id="test"
                        placeholder="Masukkan Harga"
                        name="fee_agent_pusat_alumni_full
                        "
                        value={dataBesicState?.fee_agent_pusat_alumni_full}
                        onChange={(e) => handleChange(e, "fee_agent_pusat_alumni_full")}
                      />
                      <Buttons
                        text="Simpan"
                        customClass="btn-primary btn-small absolute top-2 right-2 !w-fit"
                        loading={isLoadingAction}
                        onClick={() => handleUpdatedBesic(dataBesicState)}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="font-semibold text-[16px]">
                        Rp{" "}
                        {dataBesicState?.fee_agent_pusat_alumni_full &&
                          moneyFormatter(dataBesicState?.fee_agent_pusat_alumni_full)}
                      </div>
                      {permissions?.includes("update_package") && (
                        <>
                           <img
                            src={IconEdit}
                            alt="edit"
                            width={24}
                            height={24}
                            className="cursor-pointer"
                            onClick={() => handleEdit("fee_agent_pusat_alumni_full")}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          <div
            className={`rounded-bl-2xl rounded-br-2xl cursor-pointer ${
              clickedButton === "AgenCabangDaerah"
                ? "highlight-shadow"
                : "soft-shadow"
            } `}
            onClick={() => handleButtonClick("AgenCabangDaerah")}
          >
            <div
              className={`p-4 rounded-tl-2xl rounded-tr-2xl ${
                clickedButton === "AgenCabangDaerah"
                  ? "bg-[#FFDCDC]"
                  : "bg-white"
              }`}
            >
              <div className="bg-[#92E6A7] px-4 py-[6px] rounded-lg w-fit">
                <div className="font-semibold text-[#1A7431]">Agen Cabang</div>
              </div>
            </div>
            <div className="divider"></div>
            <div className="flex flex-row justify-between items-center px-4 py-6 w-full relative">
              <div className="bg-[#FAAB3633]/[0.2] py-[2px] px-1 rounded-br-lg text-sm text-[#FAAB36] absolute top-0 left-0">
                Fee DP
              </div>
              {dataBesicState?.is_fee_agent_cabang_alumni_dp ? (
                <div className="w-full h-[30px] relative my-[8px]">
                  <InputsCurrency
                    id="test"
                    placeholder="Masukkan Harga"
                    name="fee_agent_cabang_alumni_dp
                    "
                    value={dataBesicState?.fee_agent_cabang_alumni_dp}
                    onChange={(e) => handleChange(e, "fee_agent_cabang_alumni_dp")}
                  />
                  <Buttons
                    text="Simpan"
                    customClass="btn-primary btn-small absolute top-2 right-2 !w-fit"
                    loading={isLoadingAction}
                    onClick={() => handleUpdatedBesic(dataBesicState)}
                  />
                </div>
              ) : (
                <>
                  <div className="font-semibold text-[16px]">
                    Rp{" "}
                    {dataBesicState?.fee_agent_cabang_alumni_dp &&
                      moneyFormatter(dataBesicState?.fee_agent_cabang_alumni_dp)}
                  </div>
                  {auth.user?.access_data === 'all_branches' && (
                    <>
                      {permissions?.includes("update_package") && (
                        <>
                          <img
                            src={IconEdit}
                            alt="edit"
                            width={24}
                            height={24}
                            className="cursor-pointer"
                            onClick={() => handleEdit("fee_agent_cabang_alumni_dp")}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="divider"></div>
            <div className="flex flex-row justify-between items-center px-4 py-6 w-full relative">
              <div className="bg-[#4590F43D]/[0.2] py-[2px] px-1 rounded-br-lg text-sm text-[#4590F4] absolute top-0 left-0">
                Fee Pelunasan
              </div>
              {dataBesicState?.is_fee_agent_cabang_alumni_full ? (
                <div className="w-full h-[30px] relative my-[8px]">
                  <InputsCurrency
                    id="test"
                    placeholder="Masukkan Harga"
                    name="fee_agent_cabang_alumni_dp
                    "
                    value={dataBesicState?.fee_agent_cabang_alumni_full}
                    onChange={(e) => handleChange(e, "fee_agent_cabang_alumni_full")}
                  />
                  <Buttons
                    text="Simpan"
                    customClass="btn-primary btn-small absolute top-2 right-2 !w-fit"
                    loading={isLoadingAction}
                    onClick={() => handleUpdatedBesic(dataBesicState)}
                  />
                </div>
              ) : (
                <>
                  <div className="font-semibold text-[16px]">
                    Rp{" "}
                    {dataBesicState?.fee_agent_cabang_alumni_full &&
                      moneyFormatter(dataBesicState?.fee_agent_cabang_alumni_full)}
                  </div>
                  {auth.user?.access_data === 'all_branches' && (
                    <>
                      {permissions?.includes("update_package") && (
                        <>
                          <img
                            src={IconEdit}
                            alt="edit"
                            width={24}
                            height={24}
                            className="cursor-pointer"
                            onClick={() => handleEdit("fee_agent_cabang_alumni_full")}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          {auth.user?.access_data === 'all_branches' && (
            <>
              <div>
                <div
                className={`rounded-bl-2xl rounded-br-2xl cursor-not-allowed !h-auto ${
                  clickedButton === "AgenFreelancer"
                    ? "highlight-shadow"
                    : "soft-shadow"
                } `}
                // onClick={() => handleButtonClick("AgenFreelancer")}
              >
                <div
                  className={`p-4 rounded-tl-2xl rounded-tr-2xl ${
                    clickedButton === "AgenFreelancer" ? "bg-[#FFDCDC]" : "bg-white"
                  }`}
                >
                  <div className="bg-[#CFCCDB] px-4 py-[6px] rounded-lg w-fit">
                    <div className="font-semibold text-[#973AA8]">
                      Affiliate
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="flex flex-row justify-between items-center px-4 py-6 w-full">
                  {dataBesicState?.is_fee_agent_freelancer ? (
                    <div className="w-full !h-[30px] relative my-[8px] ">
                      <InputsCurrency
                        id="test"
                        customClass="w-full"
                        placeholder="Masukkan Harga"
                        name="fee_agent_freelancer"
                        value={dataBesicState?.fee_agent_freelancer}
                        onChange={(e) => handleChange(e, "fee_agent_freelancer")}
                      />
                      <Buttons
                        text="Simpan"
                        customClass="btn-primary btn-small absolute top-2 right-2 !w-fit"
                        loading={isLoadingAction}
                        onClick={() => handleUpdatedBesic(dataBesicState)}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="font-semibold text-[16px]">
                        Rp{" "}
                        {dataBesicState?.fee_agent_freelancer &&
                          moneyFormatter(dataBesicState?.fee_agent_freelancer)}
                      </div>
                      {permissions?.includes("update_package") && (
                        <>
                          <img
                            src={IconEdit}
                            alt="edit"
                            width={24}
                            height={24}
                            // className="cursor-no"
                            // onClick={() => handleEdit("fee_agent_freelancer")}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {getContent()}
    </>
  );
};

export default JamaahAlumni;
