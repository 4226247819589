import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import "../../styles/Fee.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PaketFeeAction, {
  addPaketFeeCabang,
  addPaketFeeCabangAlumnis,
  getPaketListCabangFEEAlumnis,
  getPaketListTabelCabangAlumni,
  updatedPaketFeeCabangAlumni,
} from "stores/actions/paket/fee";

import Inputs from "components/form/inputs";
import CardContent from "./CardContent";

import IconSearch from "assets/icons/search-normal.svg";
import IllusJamPasir from "assets/images/Jam-Pasir.svg";
import Popup from "components/popup";
import Switch from "components/form/switch";
import Buttons from "components/buttons";
import Badge from "components/badge";
import Scroll from "components/scroll";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import useDebounce from "utils/helpers/useDebounce";

const CabangDaerah = () => {
  const dispatch = useDispatch();
  const router = useHistory();

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const { data_list_cabang, data_list_cabang_alumnis, data_cabang_alumni, isLoading } = useSelector(
    (state) => state.paketFee
  );
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  let id = router.location.pathname.replace(/\D/g, "");
  const [search, setSearch] = useState("");
  const keyword = useDebounce(search, 1000);

  const [dataCustomeListCabangDaerah, setDataCustomeListCabangDaerah] =
    useState([]);
  const [listDataCabang, setListDataCabang] = useState([]);

  useEffect(() => {
    dispatch(
      getPaketListCabangFEEAlumnis(id, {
        keyword: "",
        per_page: 1000,
        page_number: 1,
        sort_column: "name",
        sort_order: "asc",
      })
    );
    dispatch(
      getPaketListTabelCabangAlumni(id, {
        keyword: keyword,
        per_page: 1000,
        page_number: 1,
        sort_column: "created_at",
        sort_order: "asc",
      })
    );
  }, [keyword]);

  useEffect(() => {
    if (data_list_cabang_alumnis) {
      let _resultMap = data_list_cabang_alumnis?.data?.map((item) => {
        return {
          ...item,
          checked: item?.is_added === 1 ? true : false,
        };
      });

      setListDataCabang(_resultMap);
    }

    if (data_cabang_alumni) {
      let _temp = data_cabang_alumni?.data?.map((item) => {
        return {
          ...item,
          is_edit_fee: false,
        };
      });

      setDataCustomeListCabangDaerah(_temp);
    }
  }, [data_list_cabang, data_cabang_alumni]);

  const [isPilihAgen, setIsPilihAgen] = useState(false);

  const handleChoiceCabang = () => {
    dispatch(
      getPaketListCabangFEEAlumnis(id, {
        keyword: "",
        per_page: 1000,
        page_number: 1,
        sort_column: "name",
        sort_order: "asc",
      })
    );

    setIsPilihAgen(true);
  };
  const handleAddCabang = () => {
    setIsLoadingAction(true);
    let _temp = listDataCabang
      ?.filter((item) => item.checked)
      ?.map((val) => val.id_cabang);

    const payload = {
      branches_id: _temp,
    };

    dispatch(
      addPaketFeeCabangAlumnis(
        id,
        payload,
        (message) => {
          setIsLoadingAction(false);
          setIsPilihAgen(false);

          dispatch(
            getPaketListTabelCabangAlumni(id, {
              keyword: "",
              per_page: 1000,
              page_number: 1,
              sort_column: "created_at",
              sort_order: "asc",
            })
          );

          dispatch(handleSuccess(message));
        },
        (error) => {
          setIsLoadingAction(false);

          setIsPilihAgen(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleEditFee = (key, idx) => {
    let _temp = [...dataCustomeListCabangDaerah];
    _temp[idx][key] = !_temp[idx][key];
    setDataCustomeListCabangDaerah(_temp);
  };

  const handleChangeRow = (val, name, index) => {
    let _temp = [...dataCustomeListCabangDaerah];
    _temp[index][`${name}`] = val;
    setDataCustomeListCabangDaerah(_temp);
  };

  const updatedPaketFee = (data) => {
    setIsLoadingAction(true);

    const payload = {
      id: data?.id,
      fee_dp: data?.fee_dp,
      fee_full: data?.fee_full,
    };

    dispatch(
      updatedPaketFeeCabangAlumni(
        id,
        payload,
        (message) => {
          setIsLoadingAction(false);

          dispatch(
            getPaketListTabelCabangAlumni(id, {
              keyword: "",
              per_page: 1000,
              page_number: 1,
              sort_column: "created_at",
              sort_order: "asc",
            })
          );

          dispatch(handleSuccess(message));
        },
        (error) => {
          setIsLoadingAction(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  return (
    <>
      <Popup
        open={isPilihAgen}
        title="Pilih Cabang"
        width="1020px"
        handleClose={() => setIsPilihAgen(false)}
      >
        <div className="border-solid border-[1px] border-[#E0E0E0]  rounded-[16px]">
          <div className="m-[16px]">
            <div className="h-[504px] ">
              <div class="grid grid-cols-4 gap-[16px]">
                {listDataCabang?.map((item, index) => (
                  <div
                    className={`p-[16px] flex flex-row justify-between items-center  @apply shadow-[0px_10px_16px_-6px_#0000000A] w-[227px] box-border ${
                      item.checked ? "!bg-[#FFDCDC] rounded-[12px]" : ""
                    } `}
                  >
                    <Badge
                      label={item?.name}
                      backgroundColor={item?.bg_color}
                      color={item?.text_color}
                      customClass="!uppercase font-semibold text-[12px] !px-[16px] !py-[4px]"
                    />
                    <Switch
                      checked={item.checked}
                      onChange={(e) => {
                        let _temp = [...listDataCabang];
                        _temp[index].checked = !_temp[index].checked;

                        setListDataCabang(_temp);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="divider"></div>
          <div className="flex flex-row justify-end p-[16px] mr-[16px]">
            <Buttons
              text="Batalkan"
              customClass="btn-outline btn-medium "
              onClick={() => setIsPilihAgen(false)}
            />
            <Buttons
              // disabled={
              //   listDataCabang?.filter((item) => item.checked === true)
              //     .length === 0
              // }
              loading={isLoadingAction}
              onClick={handleAddCabang}
              text="Simpan"
              customClass="btn-primary btn-medium !ml-[16px] "
            />
          </div>
        </div>
      </Popup>
      <div className="flex flex-row items-center justify-between p-5">
        <h1 className="text-[18px] text-[#000] font-semibold">
          Custom Fee Cabang Daerah
        </h1>
        <div className="flex flex-row justify-start">
          <Inputs
            id="test"
            placeholder="Search"
            containerClass="w-[491px]"
            iconPosition="left"
            icon={IconSearch}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {permissions?.includes('update_package') && (
            <>
              <Buttons
                text="Pilih Cabang"
                customClass="btn-outline btn-medium !ml-[16px]"
                onClick={() => handleChoiceCabang()}
              />
            </>
          )}
        </div>
      </div>
      <div className="divider"></div>
      <div className="flex flex-column justify-center items-center py-[20px] px-[20px] !pb-[100px]">
        {dataCustomeListCabangDaerah === null ||
        dataCustomeListCabangDaerah?.length === 0 ? (
          <div className="flex flex-col justify-center items-center gap-2 py-10">
            <img
              className="h-[260px] w-[260px]"
              src={IllusJamPasir}
              alt="empty"
            />
            <div>Belum ada agen, di bagian Cabang Daerah</div>
          </div>
        ) : (
          <Grid container spacing={1} rowSpacing={2}>
            {dataCustomeListCabangDaerah?.map((data, index) => (
              <Grid
                item
                md={3}
                sm={6}
                xs={12}
                className="card-container"
                key={index}
              >
                <CardContent
                  data={data}
                  handleEditFee={handleEditFee}
                  handleChangeRow={handleChangeRow}
                  updatedPaketFee={updatedPaketFee}
                  index={index}
                  isLoadingAction={isLoadingAction}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </>
  );
};

export default CabangDaerah;
