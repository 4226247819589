import React, { useEffect, useState } from "react";

import Inputs from "components/form/inputs";
import Selects from "components/form/selects";
import Buttons from "components/buttons";
import InputPhone from "components/form/inputPhone";

import Dropdowns, { closeDropdown } from "components/dropdowns";

import { useDispatch } from "react-redux";

import IconSearch from "assets/icons/search-normal.svg";

import { JamaahMemberAPI } from "utils/api/member";

import Quad from 'assets/icons/Quad.svg'
import Triple from 'assets/icons/Triple.svg'
import Double from 'assets/icons/Double.svg'
import Single from 'assets/icons/Single.svg'

import Scroll from "components/scroll";
import Badge from "components/badge";

import { getRoomPrices } from "utils/api/paket";

import './style.css'
import { IDRFormater, toCapital, toCurrencyFormat } from "utils/helpers";
import { handleError } from "stores/actions/errorGeneral";
import starting from "stores/reducers/datamaster/starting";
import Radio from "components/form/radio";
import Switch from "components/form/switch";
import { useFormik } from "formik";

import * as Yup from 'yup';
import Avatar from "components/avatar";
import useDebounce from "utils/helpers/useDebounce";
import DatePicker from "components/datepicker/DatePicker";
import moment from "moment";
import dayjs from 'dayjs'

import CMIcon from "assets/icons/cm.svg";
import KGIcon from "assets/icons/kg.svg";

import RadioIcon from "components/RadioIcon";


const Step2JamaahFormHaji = ({ index, bookingForm, formData, packageID, handleSaveOrderJamaah, packageSelected }) => {
  const dispatch = useDispatch()

  const [startingRoomPrices, setStartingRoomPrices] = useState([])
  const [startingSelected, setStartingSelected] = useState({})
  const [tempStartingRoomPrices, setTempStartingRoomPrices] = useState([])

  const [startingLabel, setStartingLabel] = useState('Pilih Starting')
  const [roomPriceLabel, setRoomPriceLabel] = useState('Pilih Harga Kamar')
  const [keywordStarting, setKeywordStarting] = useState("")
  const searchStartingDebounce = useDebounce(keywordStarting, 1000)

  const [keywordJamaahOld, setKeywordJamaahOld] = useState("")
  const [jamaahOldList, setJamaahOldList] = useState([])
  const [jamaahOldNextCursor, setJamaahOldNextCursor] = useState(null)
  const [selectedJamaahOld, setSelectedJamaahOld] = useState({})
  const [jamaahOldSelectorLabel, setJamaahOldSelectorLabel] = useState("Pilih Jamaah")
  const [isLoadingMore, setIsloadingMore] = useState(false)
  const searchDebounce = useDebounce(keywordJamaahOld, 1000)


  const [isBusinessClass, setIsBusinessClass] = useState(false)
  const [isOldJamaah, setIsOldJamaah] = useState(false)

  const [isMoreHair, setIsMoreHair] = useState(false)
  const [isMoreEyebrow, setIsMoreEyebrow] = useState(false)
  const [isMoreNose, setIsMoreNose] = useState(false)
  const [isMoreFace, setIsMoreFace] = useState(false)

  const [isValid, setIsValid] = useState(false)

  const [oldJamaahLabel, setOldJamaahLabel] = useState("Pilih Jamaah")

  const [allowAddJamaah, setAllowAddJamaah] = useState(true)

  const titleOptions = [
    {
      label: "Tuan",
      value: 'tn'
    },
    {
      label: "Nyonya",
      value: 'ny'
    },
    {
      label: "Nona",
      value: 'nn'
    }
  ]  

  const handleFillOptions = (value) => {
    
    if(value) {
      const title = titleOptions.find((item) => item.value === value.toLowerCase())

      return [title]
    }

    return []
    
  }

  useEffect(() => {
    if(packageID){
      handleRoomPrices()
    }
  }, [packageID])

  useEffect(() => {
    if(formData.values.order_kamars[index]) {
      jamaahFormik.setFieldValue(`jamaah_name`, formData.values.order_kamars[index].jamaah_name)
      jamaahFormik.setFieldValue(`jamaah_identity_number`, formData.values.order_kamars[index].jamaah_identity_number)
      jamaahFormik.setFieldValue(`jamaah_title`, formData.values.order_kamars[index].jamaah_title)
      jamaahFormik.setFieldValue(`jamaah_phone`, formData.values.order_kamars[index].jamaah_phone)
      jamaahFormik.setFieldValue(`jamaah_email`, formData.values.order_kamars[index].jamaah_email)
      
      formData.values.order_kamars[index].user_jamaah_id ? setIsOldJamaah(true) : setIsOldJamaah(false)

      // setTimeout(() => {
      //   jamaahFormik.setFieldTouched(`jamaah_name`, 1)
      //   jamaahFormik.setFieldTouched(`jamaah_identity_number`, 1)
      //   jamaahFormik.setFieldTouched(`jamaah_title`, 1)
      //   jamaahFormik.setFieldTouched(`jamaah_phone`, 1)
      //   jamaahFormik.setFieldTouched(`jamaah_email`, 1)
      // })

      formData.values.order_kamars[index].paket_kamar.kamar_name?.includes("_bussiness") &&
      setIsBusinessClass(true)
      
    }

    handleGetOldJamaah(true)

    
  }, [])

  useEffect(() => {

    if(Object.keys(selectedJamaahOld).length > 0) {
      setJamaahOldSelectorLabel(`${selectedJamaahOld.title ? `${selectedJamaahOld.title.toUpperCase()}.` : ''} ${selectedJamaahOld.name.toUpperCase()}`)
    } else {
      setJamaahOldSelectorLabel("Pilih Jamaah")
    }

  }, [selectedJamaahOld])

  useEffect(() => {
    handleGetOldJamaah(true)
  }, [searchDebounce])

  useEffect(() => {
    handleSearchStarting()
  }, [searchStartingDebounce])

  const handleRoomPrices = async () => {
    const response = await getRoomPrices(packageID)
    const { status, data } = response
    
    if(status == 200) {
      setStartingRoomPrices(data.data)

      setTempStartingRoomPrices(data.data)

      if(formData.values.order_kamars[index].starting.name) {
        
        const newStartingSelected = data.data.find((item) => item.starting.name === formData.values.order_kamars[index].starting.name)
        const roomName = formData.values.order_kamars[index].paket_kamar.kamar_name?.replace('_bussiness', '')

        // handleSetStarting(newStartingSelected)

        let roomDescription;

        if(roomName === 'quad') {
          roomDescription = '4 pax/room'
        }

        if(roomName === 'tripple') {
          roomDescription = '3 pax/room'
        }

        if(roomName === 'double') {
          roomDescription = '2 pax/room'
        }

        if(roomName === 'single') {
          roomDescription = '1 pax/room'
        }

        jamaahFormik.setFieldValue(`paket_kamar.id`, newStartingSelected.id_kamar)
        jamaahFormik.setFieldValue(`paket_kamar.kamar_name`, roomName)
        jamaahFormik.setFieldValue(`paket_kamar.kamar_description`, roomDescription)
        jamaahFormik.setFieldValue(`paket_kamar.price`, formData.values.order_kamars[index].paket_kamar.price)

        setTimeout(() => {
          jamaahFormik.setFieldTouched(`paket_kamar.id`, 1)
          jamaahFormik.setFieldTouched(`paket_kamar.kamar_name`, 1)
          jamaahFormik.setFieldTouched(`paket_kamar.kamar_description`, 1)
          jamaahFormik.setFieldTouched(`paket_kamar.price`, 1)
        })

        setStartingLabel(newStartingSelected.starting.name)
        setStartingSelected(newStartingSelected)

        jamaahFormik.setFieldValue(`starting`, {
          name: newStartingSelected.starting.name,
          bg_color: newStartingSelected.starting.bg_color,
          text_color: newStartingSelected.starting.text_color,
          departure: newStartingSelected.starting.departure,
          arrival: newStartingSelected.starting.return
        })

        setTimeout(() => {
          jamaahFormik.setFieldTouched(`starting`, 1)
        })

        const label = `${toCapital(roomName)} - ${toCurrencyFormat(formData.values.order_kamars[index].paket_kamar.price, packageSelected?.currency, false)}`
        setRoomPriceLabel(label)
      }

    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleSetStarting = (starting) => {
    setStartingSelected(starting)
       
    setStartingLabel(starting.starting.name)
    closeDropdown();
  }

  const handleSetRoomPrice = (roomID, roomName, roomDescription, price) => {
    jamaahFormik.setFieldValue(`paket_kamar.id`, roomID)
    jamaahFormik.setFieldValue(`paket_kamar.kamar_name`, `${roomName}${isBusinessClass ? '_bussiness' : ""}`)
    jamaahFormik.setFieldValue(`paket_kamar.kamar_description`, roomDescription)
    jamaahFormik.setFieldValue(`paket_kamar.price`, price)

    setTimeout(() => {
      jamaahFormik.setFieldTouched(`paket_kamar.id`, 1)
      jamaahFormik.setFieldTouched(`paket_kamar.kamar_name`, 1)
      jamaahFormik.setFieldTouched(`paket_kamar.kamar_description`, 1)
      jamaahFormik.setFieldTouched(`paket_kamar.price`, 1)
    })

    jamaahFormik.setFieldValue(`starting`, {
      name: startingSelected.starting.name,
      bg_color: startingSelected.starting.bg_color,
      text_color: startingSelected.starting.text_color,
      departure: startingSelected.starting.departure,
      arrival: startingSelected.starting.return
    })

    const label = `${toCapital(roomName)} - ${toCurrencyFormat(price, packageSelected?.currency, false)}`
    setRoomPriceLabel(label)
  }

  const handleChangeBusinnessClass = () => {
    setIsBusinessClass(!isBusinessClass);

    jamaahFormik.setFieldValue(`paket_kamar.id`, null)
    jamaahFormik.setFieldValue(`paket_kamar.kamar_name`, null)
    jamaahFormik.setFieldValue(`paket_kamar.kamar_description`, null)

    setRoomPriceLabel('Pilih Harga Kamar')
  }

  const handleSaveJamaah = () => {

    handleSaveOrderJamaah(jamaahFormik.values)
    
  }

  const handleSelectJamaahOld = (jamaah) => {
    setSelectedJamaahOld(jamaah)

    jamaahFormik.setFieldValue(`jamaah_title`, jamaah.title)
    jamaahFormik.setFieldValue(`jamaah_name`, jamaah.name)
    jamaahFormik.setFieldValue(`jamaah_identity_number`, jamaah.data_documents.ktp_nik)
    jamaahFormik.setFieldValue(`jamaah_phone`, jamaah.phone)
    jamaahFormik.setFieldValue(`jamaah_email`, jamaah.email) 
    jamaahFormik.setFieldValue(`user_jamaah_id`, jamaah.id)

    setTimeout(() => {
      jamaahFormik.setFieldTouched(`jamaah_title`, 1)
      jamaahFormik.setFieldTouched(`jamaah_name`, 1)
      jamaahFormik.setFieldTouched(`jamaah_identity_number`, 1)
      jamaahFormik.setFieldTouched(`jamaah_phone`, 1)
      jamaahFormik.setFieldTouched(`jamaah_email`, 1)
      jamaahFormik.setFieldTouched(`user_jamaah_id`, 1)
    })

    closeDropdown()
  }

  const jamaahFormik = useFormik({
    initialValues: {
      "is_available": false,
      "jamaah_title": "tn",
      "jamaah_name": null,
      "jamaah_passport_number": null,
      "jamaah_identity_number": null,
      "jamaah_phone": null,
      "jamaah_email": null,
      "user_jamaah_id": null,
      "paket_kamar": {
          "id": null,
          "kamar_name": null,
          "kamar_description": null,
          "price": null
      },
      "starting": {
        "name": null,
        "bg_color": null,
        "text_color": null,
        "departure": null,
        "return": null,
        "bg_color": null,
        "text_color": null,
        "departure": null,
        "return": null
      },
      "manifest_haji": {
        "family_father_name": null,
        "ktp_place_of_birth": null,
        "ktp_date_of_birth": null,
        "ktp_address": null,
        "self_job": null, // 
        "self_education": "SMA",
        "self_go_haji": "Belum Pernah",
        "with_mahrom": "Berangkat Sendiri",
        "family_married_status": "Belum Menikah",
        "self_mahrom_name": null,
        "self_mahrom_relationship": null,
        'physical_character_blood_group': "A",
        'physical_character_hair': null,
        'physical_character_eyebrow': null,
        'physical_character_face': null,
        'physical_character_nose': null,
        'physical_character_height': null,
        'physical_character_weight': null,
      }
    },
    validationSchema: Yup.object({
      "jamaah_title": Yup.string().required("Harus pilih salah satu title"),
      "jamaah_name": Yup.string().required("Nama Jamaah harus diisi"),
      "jamaah_identity_number": Yup.string().required("NIK harus diisi")
                                            .matches(/^[0-9]+$/, "NIK Harus berupa angka")
                                            .min(16, "NIK harus 16 digit")
                                            .max(16, "NIK harus 16 digit"),
      "paket_kamar": Yup.object({
          "id": Yup.number().required("Harus pilih starting & Tipekamar"),
          "kamar_name": Yup.string().required("Harus pilih starting & Tipekamar"),
      }),
      "starting": Yup.object({
        "name": Yup.string().required("Harus pilih starting"),
      }),
      "manifest_haji": Yup.object({
        "with_mahrom": Yup.string().required("Harus pilih mahrom"),
        "family_married_status": Yup.string().oneOf(["Sudah Menikah", "Belum Menikah", "Cerai Hidup", "Cerai Mati"]).required("Harus pilih status perkawinan"),
        "self_mahrom_name": Yup.string().nullable().test("required", "Nama mahrom harus diisi", (value, context) => {
          if(context.parent.with_mahrom === "Bersama Mahrom"){
            return value ? true : false
          } else {
            return true
          }
        }),
        "self_mahrom_relationship": Yup.string().nullable().test("required", "Hubungan mahrom harus dipilih", (value, context) => {
          if(context.parent.with_mahrom === "Bersama Mahrom"){
            return value ? true : false
          } else {
            return true
          }
        }),
        "self_job": Yup.string().required("Pekerjaan harus diisi"),
        "self_education": Yup.string().oneOf(["SD", "SMP", "SMA", "S1", "S2", "S3", "Lainnya"]).required("Pendidikan harus dipilih"),
        "self_go_haji": Yup.string().oneOf(["Belum Pernah", "Sudah Pernah"]).required("Berangkat Haji harus dipilih"),
        "family_father_name": Yup.string().required("Nama Ayah kandung harus diisi"),
        "ktp_place_of_birth": Yup.string().required("Tempat lahir harus diisi"),
        "ktp_date_of_birth": Yup.string().required("Tgl lahir harus diisi"),
        "ktp_address": Yup.string().required("Alamat harus diisi"),
        "physical_character_blood_group": Yup.string().required("Golongan darah harus diisi"),
        "physical_character_hair": Yup.string().required("Ciri rambut lainnya harus diisi"),
        "physical_character_eyebrow": Yup.string().required("Ciri muka lainnya harus diisi"),
        "physical_character_face": Yup.string().required("Ciri wajah lainnya harus diisi"),
        "physical_character_nose": Yup.string().required("Ciri telinga lainnya harus diisi"),
        "physical_character_height": Yup.number().typeError("Tinggi badan harus berupa angka").required("Tinggi badan harus diisi"),
        "physical_character_weight": Yup.number().typeError("Berat badan harus berupa angka").required("Berat badan harus diisi"),
      })
    }),
  })

  const handleGetOldJamaah = async (first = true) => {
    let params = {
      'paginate': 'cursor',
      'per_page': 10,
      'mode'  : 'options-with-document',
      'keyword': keywordJamaahOld
    }

    if(!first) {
      params['cursor'] = jamaahOldNextCursor
    }

    const response = await JamaahMemberAPI.getJamaahList(params)
    const { status, data }  = response

    if(status == 200) {
      if(first) {
        setJamaahOldList(data.data)
      } else {
        let newJamaahOldList = jamaahOldList

        newJamaahOldList.push.apply(newJamaahOldList, data.data)
        setJamaahOldList(newJamaahOldList)
      }

      if(formData.values.order_kamars[index].user_jamaah_id) {
        const newJamaahOldList = data.data.find((item) => item.id === formData.values.order_kamars[index].user_jamaah_id)
        setTimeout(() => {
          setSelectedJamaahOld(newJamaahOldList)
          jamaahFormik.setFieldValue(`user_jamaah_id`, newJamaahOldList.id)
        })
      }

      setJamaahOldNextCursor(data.next_cursor)
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleLoadMoreJamaahOld = async () => {
    setIsloadingMore(true)
    await handleGetOldJamaah(false)
    setIsloadingMore(false)
  }

  const handleSearchStarting = () => {
    const keyword = keywordStarting?.toLowerCase()
    
    const filterData = tempStartingRoomPrices.filter(item =>
      item.starting.name?.toLowerCase().includes(keyword) || 
      item.starting.return?.toLowerCase().includes(keyword) || 
      item.starting.departure?.toLowerCase().includes(keyword)
    )

    setStartingRoomPrices(filterData)
  }

  const handleSelecBloodGroup = (value) => {
    jamaahFormik.setFieldValue(`manifest_haji.physical_character_blood_group`, value)
  }

  const handleSelectHair = (value) => {
    setIsMoreHair(false)
    jamaahFormik.setFieldValue(`manifest_haji.physical_character_hair`, value)
  }

  const handleSelectEyebrow = (value) => {
    setIsMoreEyebrow(false)
    jamaahFormik.setFieldValue(`manifest_haji.physical_character_eyebrow`, value)
  }

  const handleSelectFace = (value) => {
    setIsMoreFace(false)
    jamaahFormik.setFieldValue(`manifest_haji.physical_character_face`, value)
  }

  const handleSelectNose = (value) => {
    setIsMoreNose(false)
    jamaahFormik.setFieldValue(`manifest_haji.physical_character_nose`, value)
  }



  useEffect(() => {
    console.log(jamaahFormik.errors, jamaahFormik.values)
  }, [jamaahFormik])

  return (
      <>
        <div className="bg-white p-4 mt-4 rounded-xl" id={`jamaah-order-form-${index}`}>
          
          <div className="mb-4">
            
            <div className="flex flex-row items-center mb-4">
              <Switch
                checked={isOldJamaah}
                onChange={() => {
                  setIsOldJamaah(!isOldJamaah);

                  if(!isOldJamaah) {
                    jamaahFormik.setFieldValue(`user_jamaah_id`, null)
                  }

                }}
              />
              <div className="text-sm font-semibold text-[#000] ml-2 w-full">
                Jamaah lama
              </div>
            </div>
            
            {isOldJamaah && (
              <>
                <Dropdowns
                  customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
                  placement="bottom"
                  labelDropdown={jamaahOldSelectorLabel}
                  dropdownArrow={true}
                  disabled={!packageID}
                >
                  <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
                    <div>
                      <Inputs
                        id="searchAdmin"
                        placeholder="Pencarian.."
                        customClass="!px-3 !py-3.5 !pl-[42px] text-sm my-2"
                        iconPosition="left"
                        value={keywordJamaahOld}
                        onChange={(e) =>
                          setKeywordJamaahOld(e.target.value)
                        }
                        icon={IconSearch}
                      />
                    </div>
                    <div>
                    <Scroll
                      height="350px"
                      customClass="pr-2"
                      >

                        {jamaahOldList && jamaahOldList.length !== 0 ? (
                            jamaahOldList.map((val, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="flex flex-row bg-white rounded-xl border-[0.5px] border-[#e0e0e0] p-4 mt-3 cursor-pointer items-center"
                                  onClick={() => {
                                    handleSelectJamaahOld(val);
                                    closeDropdown();
                                  }}
                                >
                                    <Avatar
                                      value={val}
                                      containerClass={`items-center justify-center w-[56px] h-[56px] rounded-[100%]  bg-slate-100`}
                                      customClass={`object-cover w-[56px] h-[56px] rounded-[100%]`}
                                    />
                                  <div className="ml-4">
                                    <div className="font-semibold text-[20px]">
                                      {val.title ? toCapital(val.title) + `.` : "" } {toCapital(val.name)}
                                    </div>
                                    <div className="flex gap-2 mt-2">
                                      <Badge 
                                        backgroundColor={val.color_bg_role} 
                                        color={val.color_text_role} 
                                        label={val.role_name} />
                                      {/* <Badge backgroundColor={val.color_bg_cabang} color={val.color_text_cabang} label={val.nama_cabang} /> */}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="mt-4">Jamaah tidak ditemukan.</div>
                          )}
                          {jamaahOldNextCursor && (
                            <>
                              <div className="flex justify-center items-center w-full m-4">
                                <Buttons
                                  onClick={() => handleLoadMoreJamaahOld()}
                                  loading={isLoadingMore}
                                  text="Lihat selengkapnya"
                                  customClass="btn-outline btn-secondary btn-sm"
                                  />
                              </div>
                            </>
                          )}

                    </Scroll>
                    </div>
                  </div>
                </Dropdowns>
              </>
            )}

          </div>

          <div className="flex gap-[10px] mb-4">
            <div className="w-[25%]">
              <Selects 
                label="Title"
                placeholder="Title"
                options={titleOptions}
                onChange={(e) => {
                  jamaahFormik.setFieldValue(`jamaah_title`, e[0]?.value)
                }}
                onBlur={jamaahFormik.handleBlur}
                values={handleFillOptions(jamaahFormik?.values?.jamaah_title)}
                // error={jamaahFormik?.touched?.jamaah_title && jamaahFormik?.errors?.jamaah_title}
                />
            </div>
            <div className="w-[75%]">
              <Inputs 
                id="jamaah_name"
                label="Nama"
                placeholder="Masukan nama"
                name={`jamaah_name`}
                onChange={(e) => {
                  jamaahFormik.setFieldValue(`jamaah_name`, e.target.value.toUpperCase())
                }}
                onBlur={jamaahFormik.handleBlur}
                value={jamaahFormik?.values?.jamaah_name?.toUpperCase()}
                error={jamaahFormik?.touched?.jamaah_name && jamaahFormik?.errors?.jamaah_name}
                />
            </div>
          </div>
          <div className="flex gap-[10px] mb-4">
            <div className="w-[25%]">
              <Inputs 
                value={"KTP"}
                label="Identitas"
                disabled
                />
            </div>
            <div className="w-[75%]">
              <Inputs 
                label="No. KTP"
                placeholder="Masukan No. KTP"
                name={`jamaah_identity_number`}
                maxLength={16}
                onChange={jamaahFormik.handleChange}
                onBlur={jamaahFormik.handleBlur}
                value={jamaahFormik?.values.jamaah_identity_number}
                error={jamaahFormik?.touched?.jamaah_identity_number && jamaahFormik?.errors?.jamaah_identity_number}
                />
            </div>
          </div>
          <div className="mb-4 mt-2"> 
          <div className="flex flex-row items-center">
            <Switch
              checked={isBusinessClass}
              onChange={(val) => {
                handleChangeBusinnessClass()
                // handleWACabang(isChecked);
              }}
            />
            <div className="text-sm font-semibold text-[#000] ml-2 w-full">
              Business Class
            </div>
          </div>
          </div>
          <div className="mt-4">
            <InputPhone 
              id="jamaah_phone"
              label="Nomor Telepon"
              placeholder="Masukan Nomor Telepon"
              name={`jamaah_phone`}
              onBlur={jamaahFormik.handleBlur}
              value={jamaahFormik?.values.jamaah_phone}
              onChange={(val) => {
                jamaahFormik.setFieldValue(`jamaah_phone`, val)
                jamaahFormik.setFieldTouched(`jamaah_phone`, true, true)
              }}
              error={jamaahFormik?.touched?.jamaah_phone && jamaahFormik?.errors?.jamaah_phone}
              />
          </div>
          <div className="mt-4">
            <Inputs 
              id="jamaah_email"
              label="Email"
              placeholder="Masukan Email"
              name={`jamaah_email`}
              onBlur={jamaahFormik.handleBlur}
              value={jamaahFormik?.values.jamaah_email}
              onChange={jamaahFormik.handleChange}
              error={jamaahFormik?.touched?.jamaah_email && jamaahFormik?.errors?.jamaah_email}
              />
          </div>
          <div className="mt-4">
            <Inputs 
              id="manifest_haji.family_father_name"
              label="Nama Ayah Kandung"
              placeholder="Masukan Nama Ayah Kandung"
              name={`manifest_haji.family_father_name`}
              onBlur={jamaahFormik.handleBlur}
              value={jamaahFormik?.values.manifest_haji.family_father_name}
              onChange={jamaahFormik.handleChange}
              error={jamaahFormik?.touched?.manifest_haji?.family_father_name && jamaahFormik?.errors?.manifest_haji?.family_father_name}
              />
          </div>
          <div className="mt-4">
            <Inputs 
              id="manifest_haji.ktp_place_of_birth"
              label="Tempat Lahir"
              placeholder="Masukan tempat lahir"
              name={`manifest_haji.ktp_place_of_birth`}
              onBlur={jamaahFormik.handleBlur}
              value={jamaahFormik?.values.manifest_haji.ktp_place_of_birth}
              onChange={jamaahFormik.handleChange}
              error={jamaahFormik?.touched?.manifest_haji?.ktp_place_of_birth && jamaahFormik?.errors?.manifest_haji?.ktp_place_of_birth}
              />
          </div>
          <div className="mt-4">
            <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
              Tanggal Lahir
            </div>
            <DatePicker
                label="Tanggal Lahir"
                containerClass="w-full"
                customClass="w-full"
                placeholder="Tentukan tanggal lahir"
                value={jamaahFormik.manifest_haji?.ktp_date_of_birth ? dayjs(moment(jamaahFormik.manifest_haji?.ktp_date_of_birth, "DD-MM-YYYY").format("YYYY-MM-DD"), "YYYY-MM-DD") : null}
                // placeholder={
                //   formInternational?.date_keberangkatan ??
                //   "Pilih Tanggal Keberangkatan"
                // }
                onChange={(tgl) => {
                    if(tgl !== 'Invalid date') {

                      tgl = moment(tgl, "DD-MM-YYYY").format("YYYY-MM-DD")

                      jamaahFormik.setFieldValue("manifest_haji.ktp_date_of_birth", tgl) 
                    } else  {
                      jamaahFormik.setFieldValue("manifest_haji.ktp_date_of_birth", null)
                    }

                    setTimeout(() => {
                      jamaahFormik.setFieldTouched(`manifest_haji.ktp_date_of_birth`, true, true)
                    });
                  }
                }
                onOpen={(status) => {
                  if(!status) {
                    jamaahFormik.setFieldTouched(`manifest_haji.ktp_date_of_birth`, true, true)
                  }
                }}
                error={jamaahFormik?.touched?.manifest_haji?.ktp_date_of_birth && jamaahFormik?.errors?.manifest_haji?.ktp_date_of_birth}
              />
          </div>
          <div className="mt-4">
            <Inputs 
              id="manifest_haji.ktp_address"
              label="Alamat sesuai KTP"
              placeholder="Masukan alamat sesuai KTP"
              name={`manifest_haji.ktp_address`}
              onBlur={jamaahFormik.handleBlur}
              value={jamaahFormik?.values.manifest_haji.ktp_address}
              onChange={jamaahFormik.handleChange}
              error={jamaahFormik?.touched?.manifest_haji?.ktp_address && jamaahFormik?.errors?.manifest_haji?.ktp_address}
              />
          </div>
          <div className="mt-4">
            <Inputs 
              id="manifest_haji.self_job"
              label="Pekerjaan"
              placeholder="Masukan pekerjaan"
              name={`manifest_haji.self_job`}
              onBlur={jamaahFormik.handleBlur}
              value={jamaahFormik?.values.manifest_haji.self_job}
              onChange={jamaahFormik.handleChange}
              error={jamaahFormik?.touched?.manifest_haji?.self_job && jamaahFormik?.errors?.manifest_haji?.self_job}
              />
          </div>
          <div className="mt-4">
            <Selects
              label="Pendidikan"
              placeholder="Pilih Pendidikan"
              iconPosition="left"
              options={[
                {
                  'value': 'SD',
                  'label': 'SD'
                },
                {
                  'value': 'SMP',
                  'label': 'SMP'
                },
                {
                  'value': 'SMA',
                  'label': 'SMA'
                },
                {
                  'value': 'D3',
                  'label': 'D3'
                },
                {
                  'value': 'S1',
                  'label': 'S1'
                },
                {
                  'value': 'S2',
                  'label': 'S2'
                },
                {
                  'value': 'S3',
                  'label': 'S3'
                },
                {
                  'value': 'Lainnya',
                  'label': 'Lainnya'
                }
              ]}
              create={true}
              values={[{value: jamaahFormik.values.manifest_haji.self_education, label: jamaahFormik.values.manifest_haji.self_education}]}
              onChange={(value) => jamaahFormik.setFieldValue('manifest_haji.self_education', value[0]?.value)}
            />
          </div>
          <div className="mt-4">
            <Selects
              label="Pergi Haji"
              placeholder="Pilih Pergi Haji"
              iconPosition="left"
              options={[
                {
                  'value': 'Belum Pernah',
                  'label': 'Belum Pernah'
                },
                {
                  'value': 'Sudah Pernah',
                  'label': 'Sudah Pernah'
                },
              ]}
              create={true}
              values={[{value: jamaahFormik.values.manifest_haji.self_go_haji, label: jamaahFormik.values.manifest_haji.self_go_haji}]}
              onChange={(value) => jamaahFormik.setFieldValue('manifest_haji.self_go_haji', value[0]?.value)}
            />
          </div>
          <div className="mt-4">
            <Selects
              label="Status Perkawinan"
              placeholder="Pilih Pergi Haji"
              iconPosition="left"
              options={[
                {
                  'value': 'Sudah Menikah',
                  'label': 'Sudah Menikah'
                },
                {
                  'value': 'Belum Menikah',
                  'label': 'Belum Menikah'
                },
                {
                  'value': 'Cerai Hidup',
                  'label': 'Cerai Hidup'
                },
                {
                  'value': 'Cerai Mati',
                  'label': 'Cerai Mati'
                },
              ]}
              create={true}
              values={[{value: jamaahFormik.values.manifest_haji.family_married_status, label: jamaahFormik.values.manifest_haji.family_married_status}]}
              onChange={(value) => jamaahFormik.setFieldValue('manifest_haji.family_married_status', value[0]?.value)}
            />
          </div>
          <div className="mt-4 p-4 border rounded-2xl">
            <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
              Mahrom
            </div>
            <Radio 
              defaultValue={jamaahFormik.values.with_mahrom}
              options={[
                {
                  value: "Berangkat Sendiri",
                  label: "Berangkat Sendiri",
                },
                {
                  value: "Bersama Mahrom",
                  label: "Bersama Mahrom",
                },
              ]} 
              onChange={(e) => {
                const value = e.target.value

                jamaahFormik.setFieldValue("manifest_haji.with_mahrom", value)

                if(value === "Bersama Mahrom") {
                  jamaahFormik.setFieldValue("manifest_haji.self_mahrom_name", "")
                  jamaahFormik.setFieldValue("manifest_haji.self_mahrom_relationship", "Orang Tua")
                } else {
                  jamaahFormik.setFieldValue("manifest_haji.self_mahrom_name", "")
                  jamaahFormik.setFieldValue("manifest_haji.self_mahrom_relationship", "")
                }

                
              }}
              />
            {jamaahFormik.values.manifest_haji.with_mahrom === "Bersama Mahrom" && (
              <>
                <div className="mt-4">
                  <Inputs 
                    id="manifest_haji.self_mahrom_name"
                    label="Nama Mahrom"
                    placeholder="Masukan nama mahrom"
                    name={`manifest_haji.self_mahrom_name`}
                    onBlur={jamaahFormik.handleBlur}
                    value={jamaahFormik?.values.manifest_haji.self_mahrom_name}
                    onChange={jamaahFormik.handleChange}
                    error={jamaahFormik?.touched?.manifest_haji?.self_mahrom_name && jamaahFormik?.errors?.manifest_haji?.self_mahrom_name}
                    />
                </div>
                <div className="mt-4">
                  <Selects
                    label="Hubungan Mahrom"
                    placeholder="Pilih Hubungan Mahrom"
                    iconPosition="left"
                    options={[
                      {
                        'value': 'Orang Tua',
                        'label': 'Orang Tua'
                      },
                      {
                        'value': 'Anak',
                        'label': 'Anak'
                      },
                      {
                        'value': 'Suami/Istri',
                        'label': 'Suami/Istri'
                      },
                      {
                        'value': 'Mertua',
                        'label': 'Mertua'
                      },
                      {
                        'value': 'Saudara Kandung',
                        'label': 'Saudara Kandung'
                      },
                    ]}
                    create={true}
                    values={[{value: jamaahFormik.values.manifest_haji.self_mahrom_relationship, label: jamaahFormik.values.manifest_haji.self_mahrom_relationship}]}
                    onChange={(value) => jamaahFormik.setFieldValue('manifest_haji.self_mahrom_relationship', value[0]?.value)}
                  />
                </div>
              </>
            )}
          </div>
          <div className="mt-4">
            <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
              Golongan Darah
            </div>
            <div className="inline-flex gap-4">
              <RadioIcon 
                value={'A'}
                label={'A'}
                checked={jamaahFormik.values.manifest_haji.physical_character_blood_group === 'A'}
                onChange={(val) => handleSelecBloodGroup(val)}
                />
              <RadioIcon 
                value={'B'}
                label={'B'}
                checked={jamaahFormik.values.manifest_haji.physical_character_blood_group === 'B'}
                onChange={(val) => handleSelecBloodGroup(val)}
                />
              <RadioIcon 
                value={'O'}
                label={'O'}
                checked={jamaahFormik.values.manifest_haji.physical_character_blood_group === 'O'}
                onChange={(val) => handleSelecBloodGroup(val)}
                />
              <RadioIcon 
                value={'AB'}
                label={'AB'}
                checked={jamaahFormik.values.manifest_haji.physical_character_blood_group === 'AB'}
                onChange={(val) => handleSelecBloodGroup(val)}
                />
            </div>
          </div>
          <div className="mt-4">
            <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
              Ciri Rambut
            </div>
            <div className="inline-flex gap-4">
              <RadioIcon 
                value={'Ikal'}
                label={'Ikal'}
                checked={jamaahFormik.values.manifest_haji.physical_character_hair === 'Ikal'}
                onChange={(val) => handleSelectHair(val)}
                />
              <RadioIcon 
                value={'Lurus'}
                label={'Lurus'}
                checked={jamaahFormik.values.manifest_haji.physical_character_hair === 'Lurus'}
                onChange={(val) => handleSelectHair(val)}
                />
              <RadioIcon 
                value={'Kriting'}
                label={'Kriting'}
                checked={jamaahFormik.values.manifest_haji.physical_character_hair === 'Kriting'}
                onChange={(val) => handleSelectHair(val)}
                />
            </div>
            <div className="mt-2">
              <RadioIcon 
                value={'Lainnya'}
                label={'Lainnya'}
                checked={isMoreHair}
                onChange={() => {
                  setIsMoreHair(true)
                  jamaahFormik.setFieldValue('manifest_haji.physical_character_hair', null)
                }}
                />
            </div>
            {isMoreHair && (
              <div className="mt-2">
                <Inputs 
                  id="manifest_haji.physical_character_hair"
                  placeholder="Masukan lainnya"
                  name={`manifest_haji.physical_character_hair`}
                  onChange={jamaahFormik.handleChange}
                  onBlur={jamaahFormik.handleBlur}
                  value={jamaahFormik?.values.manifest_haji.physical_character_hair}
                  error={jamaahFormik?.touched?.manifest_haji?.physical_character_hair && jamaahFormik?.errors?.manifest_haji?.physical_character_hair}
                  />
              </div>
            )}
          </div>
          <div className="mt-4">
            <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
              Ciri Alis
            </div>
            <div className="inline-flex gap-4">
              <RadioIcon 
                value={'Tipis'}
                label={'Tipis'}
                checked={jamaahFormik.values.manifest_haji.physical_character_eyebrow === 'Tipis'}
                onChange={(val) => handleSelectEyebrow(val)}
                />
              <RadioIcon 
                value={'Tebal'}
                label={'Tebal'}
                checked={jamaahFormik.values.manifest_haji.physical_character_eyebrow === 'Tebal'}
                onChange={(val) => handleSelectEyebrow(val)}
                />
            </div>
            <div className="mt-2">
              <RadioIcon 
                value={'Lainnya'}
                label={'Lainnya'}
                checked={isMoreEyebrow}
                onChange={() => {
                  setIsMoreEyebrow(true)
                  jamaahFormik.setFieldValue('manifest_haji.physical_character_eyebrow', null)
                }}
                />
            </div>
            {isMoreEyebrow && (
              <div className="mt-2">
                <Inputs 
                  id="manifest_haji.physical_character_eyebrow"
                  placeholder="Masukan lainnya"
                  name={`manifest_haji.physical_character_eyebrow`}
                  onChange={jamaahFormik.handleChange}
                  onBlur={jamaahFormik.handleBlur}
                  value={jamaahFormik?.values.manifest_haji.physical_character_eyebrow}
                  error={jamaahFormik?.touched?.manifest_haji?.physical_character_eyebrow && jamaahFormik?.errors?.manifest_haji?.physical_character_eyebrow}
                  />
              </div>
            )}
          </div>
          <div className="mt-4">
            <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
              Ciri Hidung
            </div>
            <div className="inline-flex gap-4">
              <RadioIcon 
                value={'Mancung'}
                label={'Mancung'}
                checked={jamaahFormik.values.manifest_haji.physical_character_nose === 'Mancung'}
                onChange={(val) => handleSelectNose(val)}
                />
              <RadioIcon 
                value={'Pesek'}
                label={'Pesek'}
                checked={jamaahFormik.values.manifest_haji.physical_character_nose === 'Pesek'}
                onChange={(val) => handleSelectNose(val)}
                />
            </div>
            <div className="mt-2">
              <RadioIcon 
                value={'Lainnya'}
                label={'Lainnya'}
                checked={isMoreNose}
                onChange={() => {
                  setIsMoreNose(true)
                  jamaahFormik.setFieldValue('manifest_haji.physical_character_nose', null)
                }}
                />
            </div>
            {isMoreNose && (
              <div className="mt-2">
                <Inputs 
                  id="manifest_haji.physical_character_nose"
                  placeholder="Masukan lainnya"
                  name={`manifest_haji.physical_character_nose`}
                  onChange={jamaahFormik.handleChange}
                  onBlur={jamaahFormik.handleBlur}
                  value={jamaahFormik?.values.manifest_haji.physical_character_nose}
                  error={jamaahFormik?.touched?.manifest_haji?.physical_character_nose && jamaahFormik?.errors?.manifest_haji?.physical_character_nose}
                  />
              </div>
            )}
          </div>
          <div className="mt-4">
            <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
              Ciri Wajah
            </div>
            <div className="inline-flex gap-4">
              <RadioIcon 
                value={'Oval'}
                label={'Oval'}
                checked={jamaahFormik.values.manifest_haji.physical_character_face === 'Oval'}
                onChange={(val) => handleSelectFace(val)}
                />
              <RadioIcon 
                value={'Kotak'}
                label={'Kotak'}
                checked={jamaahFormik.values.manifest_haji.physical_character_face === 'Kotak'}
                onChange={(val) => handleSelectFace(val)}
                />
              <RadioIcon 
                value={'Lonjong'}
                label={'Lonjong'}
                checked={jamaahFormik.values.manifest_haji.physical_character_face === 'Lonjong'}
                onChange={(val) => handleSelectFace(val)}
                />
              <RadioIcon 
                value={'Bulat'}
                label={'Bulat'}
                checked={jamaahFormik.values.manifest_haji.physical_character_face === 'Bulat'}
                onChange={(val) => handleSelectFace(val)}
                />
              <RadioIcon 
                value={'Segitiga'}
                label={'Segitiga'}
                checked={jamaahFormik.values.manifest_haji.physical_character_face === 'Segitiga'}
                onChange={(val) => handleSelectFace(val)}
                />
            </div>
            <div className="mt-2">
              <RadioIcon 
                value={'Lainnya'}
                label={'Lainnya'}
                checked={isMoreFace}
                onChange={() => {
                  setIsMoreFace(true)
                  jamaahFormik.setFieldValue('manifest_haji.physical_character_face', null)
                }}
                />
            </div>
            {isMoreFace && (
              <div className="mt-2">
                <Inputs 
                  id="manifest_haji.physical_character_face"
                  placeholder="Masukan lainnya"
                  name={`manifest_haji.physical_character_face`}
                  onChange={jamaahFormik.handleChange}
                  onBlur={jamaahFormik.handleBlur}
                  value={jamaahFormik?.values.manifest_haji.physical_character_face}
                  error={jamaahFormik?.touched?.manifest_haji?.physical_character_face && jamaahFormik?.errors?.manifest_haji?.physical_character_face}
                  />
              </div>
            )}
          </div>
          <div className="mt-4">
            <Inputs 
              id="manifest_haji.physical_character_height"
              label="Tinggi Badan"
              placeholder="Masukan tinggi badan"
              icon={CMIcon}
              iconPosition="right"
              name={`manifest_haji.physical_character_height`}
              onBlur={jamaahFormik.handleBlur}
              value={jamaahFormik?.values.manifest_haji.physical_character_height}
              onChange={jamaahFormik.handleChange}
              error={jamaahFormik?.touched?.manifest_haji?.physical_character_height && jamaahFormik?.errors?.manifest_haji?.physical_character_height}
              />
          </div>

          <div className="mt-4">
            <Inputs 
              id="manifest_haji.physical_character_weight"
              label="Berat Badan"
              placeholder="Masukan berat badan"
              icon={KGIcon}
              iconPosition="right"
              name={`manifest_haji.physical_character_weight`}
              onBlur={jamaahFormik.handleBlur}
              value={jamaahFormik?.values.manifest_haji.physical_character_weight}
              onChange={jamaahFormik.handleChange}
              error={jamaahFormik?.touched?.manifest_haji?.physical_character_weight && jamaahFormik?.errors?.manifest_haji?.physical_character_weight}
              />
          </div>
          <div className="mt-4">
            <div className="font-semibold text-sm mb-2">Starting</div>
            <Dropdowns
              customClass={`
                bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center
              `}
              placement="bottom"
              labelDropdown={startingLabel}
              dropdownArrow={true}
              disabled={!packageID}
              onClose={() => {
                jamaahFormik.setFieldTouched(`manifest_haji.starting_room_price_id`, true, true)
              }}
              error={jamaahFormik?.touched?.starting?.name && jamaahFormik?.errors?.starting?.name}
            >
              <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
                <div>
                  <Inputs 
                    name="searchStarting"
                    placeholder="Cari Tipe Kamar"
                    customClass="!px-3 !py-3.5 !pl-[42px] text-sm"
                    iconPosition="left"
                    icon={IconSearch}
                    onChange={(e) => setKeywordStarting(e.target.value)}
                    />
                </div>
                <Scroll height="350px" customClass="pr-2">
                  {startingRoomPrices && startingRoomPrices.map((room, idx) => {
                    return (
                      <>
                        <div 
                          onClick={() => handleSetStarting(room)}
                          className="p-4 cursor-pointer active:bg-[#FF9C9A] hover:bg-red-5 hover:border-red-5 rounded-2xl flex justify-between items-start border border-gray-4 mt-[18px]">
                          <div className="flex gap-11.5 justify-between w-[40%]">
                            <div>
                              <div className="text-xs text-think mb-1">
                                Berangkat
                              </div>
                              <div className="font-semibold">
                                {room?.starting?.departure}
                              </div>
                            </div>
                            <div>
                              <div className="text-xs text-think mb-1">
                                Pulang
                              </div>
                              <div className="font-semibold">
                                {room?.starting?.return}
                              </div>
                            </div>
                          </div>
                          <Badge 
                            label={room?.starting?.name}
                            backgroundColor={room?.starting?.bg_color}
                            color={room?.starting?.text_color}
                            />
                        </div>
                      </>
                    )
                  })}
                </Scroll>
              </div>
            </Dropdowns>
          </div>
          <div className="mt-4">
            <div className="font-semibold text-sm mb-2 mt-4">Tipe Kamar</div>
            <Dropdowns
              customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
              placement="bottom"
              labelDropdown={roomPriceLabel}
              dropdownArrow={true}
              disabled={Object.keys(startingSelected).length === 0}
              error={jamaahFormik?.touched?.paket_kamar?.id && jamaahFormik?.errors?.paket_kamar?.id}
            >
              <div className="bg-white w-full soft-shadow rounded-2xl overflow-hidden">
                <Scroll height="350px">
                  
                  {!!startingSelected.is_active_price_quad && (
                    <div 
                      onClick={() => {
                        handleSetRoomPrice(
                            startingSelected.id_kamar, 
                            `quad`, 
                            `4 pax/room`,
                            isBusinessClass ? startingSelected.price_quad_business : startingSelected.price_quad
                        )
                        closeDropdown()
                      }}
                      className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                      <div className="flex items-center gap-2 mb-2">
                        <img 
                          src={Quad}
                          />
                        <div className="font-base font-semibold">
                          Quad
                        </div>
                      </div>
                      <div className="green-money font-semibold text-xl">
                        {isBusinessClass ? toCurrencyFormat(startingSelected.price_quad_business, packageSelected?.currency, false) : toCurrencyFormat(startingSelected.price_quad, packageSelected?.currency, false)}
                      </div>
                    </div>
                  )}
                  
                  {!!startingSelected.is_active_price_triple && (
                    <>
                      <div 
                        onClick={() => {
                          handleSetRoomPrice(
                              startingSelected.id_kamar, 
                              `tripple`, 
                              `3 pax/room`,
                              isBusinessClass ? startingSelected.price_triple_business : startingSelected.price_triple
                          )
                          closeDropdown()
                        }}
                        className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                        <div className="flex items-center gap-2 mb-2">
                          <img 
                            src={Triple}
                            />
                          <div className="font-base font-semibold">
                            Triple
                          </div>
                        </div>
                        <div className="green-money font-semibold text-xl">
                          {isBusinessClass ? toCurrencyFormat(startingSelected.price_triple_business, packageSelected?.currency, false) : toCurrencyFormat(startingSelected.price_triple, packageSelected?.currency, false)}
                        </div>
                      </div>
                    </>
                  )}

                  {!!startingSelected.is_active_price_double && (
                    <div 
                      onClick={() => {
                        handleSetRoomPrice(
                          startingSelected.id_kamar, 
                          `double`, 
                          `2 pax/room`,
                          isBusinessClass ? startingSelected.price_double_business : startingSelected.price_double
                        )
                        closeDropdown()
                      }}
                      className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                      <div className="flex items-center gap-2 mb-2">
                        <img 
                          src={Double}
                          />
                        <div className="font-base font-semibold">
                          Double
                        </div>
                      </div>
                      <div className="green-money font-semibold text-xl">
                        {isBusinessClass ? toCurrencyFormat(startingSelected.price_double_business, packageSelected?.currency, false) : toCurrencyFormat(startingSelected.price_double, packageSelected?.currency, false)}
                      </div>
                    </div>
                  )}

                  {!!startingSelected.is_active_price_single && (
                    <div 
                      onClick={() => {
                        handleSetRoomPrice(
                          startingSelected.id_kamar, 
                          `single`, 
                          `1 pax/room`,
                          isBusinessClass ? startingSelected.price_single_business : startingSelected.price_single
                        )
                        closeDropdown()
                      }}
                      className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                      <div className="flex items-center gap-2 mb-2">
                        <img 
                          src={Single}
                          />
                        <div className="font-base font-semibold">
                          Single
                        </div>
                      </div>
                      <div className="green-money font-semibold text-xl">
                        {isBusinessClass ? toCurrencyFormat(startingSelected.price_single_business, packageSelected?.currency, false)  : toCurrencyFormat(startingSelected.price_single, packageSelected?.currency, false)}
                      </div>
                    </div>
                  )}
                </Scroll>
              </div>
            </Dropdowns>
          </div>
          <div className="mt-4">
            <Buttons 
              text="Simpan"
              customClass="w-full btn-primary btn-medium"
              onClick={handleSaveJamaah}
              disabled={!(jamaahFormik.isValid && jamaahFormik.dirty)}
              />
          </div>
        </div>
      </>
  )
}

export default React.memo(Step2JamaahFormHaji);