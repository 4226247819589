import React from "react";

import { useSelector } from "react-redux";

import Badge from "components/badge";
import Switch from "components/form/switch";
import Inputs from "components/form/inputs";
import Buttons from "components/buttons";
import { toCurrencyFormat } from "utils/helpers";
import IconEdit from "assets/icons/edit.svg";
import InputsCurrency from "components/form/inputsCurrency";

const CardContent = ({
  data,
  handleEditFee,
  handleChangeRow,
  index,
  updatedPaketFee,
  isLoadingAction = false,
}) => {
  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;
  return (
    <div className="rounded-xl soft-shadow box-border ">
      <div
        className={`center py-[20px] px-[16px] rounded-t-xl `}
        style={{ background: `${data?.bg_color}80` }}
      >
        <Badge
          label={data?.branch_name}
          backgroundColor={`${data?.bg_color}`}
          color={data?.text_color}
          customClass="w-[115px] h-[26px] !uppercase"
        />
      </div>
      <div className="flex flex-column justify-between items-center px-[16px] py-[24px] bg-[#fff] border-b-[1px] relative">
        <div className="bg-[#FAAB3633]/[0.2] py-[2px] px-1 rounded-br-lg text-sm text-[#FAAB36] absolute top-0 left-0">
          Fee DP
        </div>
        {data?.is_edit_fee_dp ? (
          <div className="w-full relative my-[8px]">
            <InputsCurrency
              id="test"
              placeholder="Masukkan Harga"
              name="fee_dp"
              value={data?.fee_dp}
              onChange={(e) => handleChangeRow(e, "fee_dp", index)}
            />
            <Buttons
              text="Simpan"
              customClass="btn-primary btn-small !w-fit absolute top-2 right-2"
              loading={isLoadingAction}
              onClick={() => updatedPaketFee(data)}
            />
          </div>
        ) : (
          <>
            <div className="font-semibold text-[16px]">
              Rp {toCurrencyFormat(data?.fee_dp)}
            </div>
            {permissions?.includes("update_package") && (
              <>
                <img
                  src={IconEdit}
                  alt="edit"
                  width={24}
                  height={24}
                  className="cursor-pointer"
                  onClick={() => handleEditFee("is_edit_fee_dp", index)}
                />
              </>
            )}
          </>
        )}
      </div>
      <div className="flex flex-column justify-between items-center px-[16px] py-[24px] bg-[#fff] border-b-[1px] relative">
        <div className="bg-[#4590F43D]/[0.2] py-[2px] px-1 rounded-br-lg text-sm text-[#4590F4] absolute top-0 left-0">
          Fee Pelunasan
        </div>
        {data?.is_edit_fee_full ? (
          <div className="w-full relative my-[8px]">
            <InputsCurrency
              id="test"
              placeholder="Masukkan Harga"
              name="fee_full"
              value={data?.fee_full}
              onChange={(e) => handleChangeRow(e, "fee_full", index)}
            />
            <Buttons
              text="Simpan"
              customClass="btn-primary btn-small !w-fit absolute top-2 right-2"
              loading={isLoadingAction}
              onClick={() => updatedPaketFee(data)}
            />
          </div>
        ) : (
          <>
            <div className="font-semibold text-[16px]">
              Rp {toCurrencyFormat(data?.fee_full)}
            </div>
            {permissions?.includes("update_package") && (
              <>
                <img
                  src={IconEdit}
                  alt="edit"
                  width={24}
                  height={24}
                  className="cursor-pointer"
                  onClick={() => handleEditFee("is_edit_fee_full", index)}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CardContent;
