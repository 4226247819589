import {
  ERROR_FETCHING_PAKET_FEE,
  START_FETCHING_PAKET_FEE,
  SUCCESS_FETCHING_PAKET_FEE,
  SET_LOADING,
  SET_PAGE_PAKET_FEE,
  SET_SIZE_PAKET_FEE,
  SET_SIZE_TOTAL_PAKET_FEE,
  SET_KEYWORD_PAKET_FEE,
  SET_PAKET_FEE_BESIC,
  SET_PAKET_FEE_LIST_AGENT,
  SET_PAKET_FEE_LIST_AGENT_ALUMNIS,
  SET_PAKET_FEE_LIST_CABANG,
  SET_PAKET_FEE_LIST_CABANG_ALUMNIS,
  SET_PAKET_FEE_DATA_AGENT,
  SET_PAKET_FEE_DATA_CABANG,
  SET_PAKET_FEE_DATA_CABANG_ALUMNI,
  SET_PAKET_FEE_DATA_AGENT_ALUMNIS
} from "stores/actions/paket/fee/actionTypes";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  isLoading: false,
  data: null,
  total: 100,
  per_page: 10,
  page_number: 1,
  sort_column: "",
  sort_order: "Desc",
  keyword: "",
  status: statuslist.idle,
  data_besic: {},
  data_list_agent: {},
  data_list_agent_alumnis: {},
  data_list_cabang: {},
  data_list_cabang_alumnis: {},
  data_agent: {},
  data_agent_alumnis: {},
  data_cabang: {},
  data_cabang_alumni: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_PAKET_FEE:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_PAKET_FEE:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_PAKET_FEE:
      // console.log("ACTION CHECK", action);
      return {
        ...state,
        data: action.payload,
        status: statuslist.success,
      };

    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_KEYWORD_PAKET_FEE:
      return { ...state, keyword: action.keyword };
    case SET_PAGE_PAKET_FEE:
      return { ...state, page_number: action.page };

    case SET_SIZE_PAKET_FEE:
      return { ...state, per_page: action.size };

    case SET_SIZE_TOTAL_PAKET_FEE:
      return { ...state, total: action.total };

    case SET_PAKET_FEE_BESIC:
      return {
        ...state,
        data_besic: action.payload,
      };
    case SET_PAKET_FEE_LIST_AGENT:
      return {
        ...state,
        data_list_agent: action.payload,
      };
    case SET_PAKET_FEE_LIST_AGENT_ALUMNIS:
      return {
        ...state,
        data_list_agent_alumnis: action.payload,
      };
    
    case SET_PAKET_FEE_LIST_CABANG:
      return {
        ...state,
        data_list_cabang: action.payload,
      };

    case SET_PAKET_FEE_LIST_CABANG_ALUMNIS:
      return {
        ...state,
        data_list_cabang_alumnis: action.payload,
      };

    case SET_PAKET_FEE_DATA_AGENT:
      return {
        ...state,
        data_agent: action.payload,
      };

    case SET_PAKET_FEE_DATA_AGENT_ALUMNIS:
      return {
        ...state,
        data_agent_alumnis: action.payload,
      };

    case SET_PAKET_FEE_DATA_CABANG:
      return {
        ...state,
        data_cabang: action.payload,
      };
    case SET_PAKET_FEE_DATA_CABANG_ALUMNI:
      return {
        ...state,
        data_cabang_alumni: action.payload,
      };
    default:
      return state;
  }
}
