import React, { useEffect, useState } from "react";

import Buttons from "components/buttons";
import Badge from "components/badge";
import PendapatanCabangHistory from "./components/History";

import { useDispatch } from "react-redux";

import { getFeeTotalListService } from "utils/api/fee";
import { handleError } from "stores/actions/errorGeneral";
import Spinner from "components/spinner";
import { IDRFormater } from "utils/helpers";


const PendapatanCabang = () => {

  const dispatch = useDispatch();

  const [history, setHistory] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [branchSelected, setBranchSelected] = useState();

  const [feeTotalList, setFeeTotalList] = useState([]);

  const handleGetFeeTotalList = async () => {
    setIsLoading(true);
    const payload = {
      per_page: 1000,
      recipient: "branch",
    };
    const response = await getFeeTotalListService(payload);
    if (response?.data?.data) {
      setFeeTotalList(response?.data?.data);
    } else {
      dispatch(handleError(response?.data?.message));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetFeeTotalList();
  }, []);

  return (
    <>
      {history ? (
        <PendapatanCabangHistory setHistory={setHistory} branch={branchSelected} />
      ) : (
        <>
          <div className="flex flex-row justify-between items-center p-4 mx-[-1rem]">
            <div className="text-xl font-semibold">
              Daftar Pendapatan Cabang
            </div>
          </div>
          {/* <div className="divider"></div> */}
          <div className="pt-4 border-t border-[#e0e0e0]">
           {isLoading && (
            <div className="flex justify-center items-center h-[600px]">
              <Spinner width="32px" height="32px" />
            </div>
           )}
           {!isLoading && (
              <div className="grid grid-cols-4 gap-4">
                {feeTotalList?.map(item => (
                  <div className="bg-white shadow-soft rounded-lg">
                    <div className={`p-4 rounded-tl-lg rounded-tr-lg flex justify-between items-center`} style={{ background: `${item?.color}80` }}>
                      <Badge label={item?.name?.toUpperCase()} color={item.color_text} backgroundColor={item.color} />
                      <Buttons
                        text="History"
                        customClass="btn-primary btn-small"
                        onClick={() => {
                          setHistory(true)
                          setBranchSelected(item)
                        }}
                      />
                    </div>
                    <div className="p-4">
                      <div>Total Pendapatan</div>
                      <div className="font-semibold mt-2">{IDRFormater(item?.total_saldo_fee)}</div>
                    </div>
                  </div>
                ))}
              </div>
           )}
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(PendapatanCabang);
