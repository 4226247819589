import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import Tab from "components/tab";

import JamaahOld from "./partials/JamaahOld";
import JamaahAlumni from "./partials/JamaahAlumni";

const Fee = () => {
  const [activeTab, setActiveTab] = useState(1);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  return (
    <>
      <div className="relative">
        <Tab 
            defaultActiveKey="1"
            onChange={(e) => setActiveTab(e)}
            items={[
              {
                label: (
                  <>
                    <div className="px-4 py-3">
                      Jamaah Baru
                    </div>
                  </>
                ),
                key: 1,
                onchange: (key) => {console.log(key)},
                children: (
                  <>
                    <JamaahOld />
                  </>
                )
              },
              {
                label: (
                  <>
                    <div className="px-4 py-3">
                      Jamaah Alumni
                    </div>
                  </>
                ),
                key: 2,
                onchange: (key) => {console.log(key)},
                children: (
                  <>
                    <JamaahAlumni />
                  </>
                )
              }
            ]}
            />
      </div>
    </>
  );
};

export default Fee;
