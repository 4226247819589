import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import { getMasterId } from "stores/actions/datamaster";
import { IDRFormater, USDFormater, clearHtml, truncateString } from "utils/helpers";

export const rupiah = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(number);
};

export const columns = (dispatch, permissions) => [
  {
    title: "Nama Additional",
    dataIndex: "name",
    render: (name) => <div className="text-sm">{name}</div>,
  },
  {
    title: "Harga Default IDR",
    dataIndex: "default_price_idr",
    render: (default_price_idr) => (
      <div className="text-sm">{IDRFormater(default_price_idr)}</div>
    ),
  },
  {
    title: "Harga Default USD",
    dataIndex: "default_price_usd",
    render: (default_price_usd) => (
      <div className="text-sm">{USDFormater(default_price_usd)}</div>
    ),
  },
  {
    title: "Deskripsi",
    dataIndex: "description",

    render: (description, data) => (
      <div className="w-52">
        <div className={`flex gap-x-4 items-center`}>
        {truncateString(clearHtml(description), 70)}
        </div>
        <div className="action">
          <div className="action-content">
            {permissions?.includes("delete_master-data") && (
              <div className="action-content-item">
                <Trash
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(getMasterId({ modal: true, data: data }))
                  }
                  size="16"
                  color="#141414"
                />
              </div>
            )}
            {permissions?.includes("create_master-data") && (
              <div className="action-content-item">
                <DocumentCopy
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(getMasterId({ popup: true, data: data }))
                  }
                  size="16"
                  color="#141414"
                />
              </div>
            )}
            {permissions?.includes("update_master-data") && (
              <div className="action-content-item">
                <Edit
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(getMasterId({ modalEdit: true, data: data }))
                  }
                  size="16"
                  color="#141414"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    ),
  },
];
