import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

export const getSaldoFeeService = () =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/fee/saldo`);

export const getHistoryFeeService = (payload, feeType) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/fee/${feeType}/histories`, payload)

export const getHistoryFeeStatisticsService = (feeType) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/fee/${feeType}/histories/statistics`)

export const getHistoryWithdrawFeeService = (payload, feeType) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/fee/${feeType}/withdraw/history`, payload)

export const getWithdrawBankAccountService = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/fee/withdraw/bank-accounts`, payload)

export const withdrawFe = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/fee/withdraw/account`, payload)

export const createAccountWithdrawService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/fee/withdraw/bank-accounts/create`, payload)

export const updateAccountWithdrawService = (accountID, payload) =>
  axios.put(`${BASE_URL_ENDPOINT}/admin/fee/withdraw/bank-accounts/${accountID}/update`, payload)

export const getWithdrawRequestService = (params) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/management-fee/withdraw/requests`, {params})

export const withdrawConfirmationService = (withdrawID, payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/management-fee/withdraw/${withdrawID}/confirmation`, payload)

export const getWithdrawHistoryService = (params) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/management-fee/withdraw/histories`, {params})

export const getFeeTotalListService = (params) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/management-fee/fee/recipient-fee-total-list`, {params}) 

export const getFeeTotalDetailService = (params) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/management-fee/fee/detail`, { params })

export const getWithdrawPackages = (payload, feeType) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/fee/${feeType}/withdraw/packages`, {params: payload})

export const getTotalWithdrawFeeService = (payload, feeType) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/fee/${feeType}/withdraw/total-fee`, {params: payload})