import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Cards from "../../../../components/cards";
import Inputs from "../../../../components/form/inputs";
import Segment from "../../../../components/segment";
import Selects from "../../../../components/form/selects";
import DatePicker from "../../../../components/datepicker/DatePicker";
import ImageField from "../../../../components/image_field";
import Textareas from "../../../../components/form/textarea";
import Buttons from "../../../../components/buttons";
import { listBank } from "../../../../configuration/listBank";

import { handleGetIndonesianBanks } from "stores/actions/indonesianBanks"; 

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addDataPembayaran,
  getDataRekening,
} from "../../../../stores/actions/tagihan";

import dayjs from "dayjs";
import moment from "moment";
import Dropdowns, { closeDropdown } from "components/dropdowns";
import Scroll from "components/scroll";

import * as Yup from "yup";
import InputsCurrency from "components/form/inputsCurrency";
import UploadArea from "components/Uploadarea";
import TimePicker from "components/datepicker/TimePicker";
import TimePickers from "components/timepickers";

import { handleStorePaymentAPI } from "utils/api/booking";
import { handleGetDetailOrder } from "stores/actions/booking"; 

import { handleSuccess, handleError } from "stores/actions/errorGeneral";

const AddTransaction = ({
  onChange
}) => {
  const { detailData, dataRekening } = useSelector((state) => state.tagihan);
  const params = useParams();

  const { indonesianBanks } = useSelector((state) => state.indonesianBanks);
  const [isLoading, setIsLoading] = useState(false);

  const { bookingDetail } = useSelector((state) => state.booking);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      type_transaction: "PAYMENT",
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm"),
      sender_name: "",
      sender_rekening: "",
      sender_bank: "",
      receipent_name: "",
      receipent_rekening: "",
      receipent_bank: "",
      currency: "IDR",
      amount: 0,
      verification_status: 0,
      payment_method: 2,
      receipent_account: null,
      sender_account: null,
      status_transaction: "pending",
      proof_url: "",
      note: "",
      with_unique_code: 1,
    },
    validationSchema: Yup.object().shape({
      type_transaction: Yup.string().required(),
      date: Yup.string().required(),
      time: Yup.string().required(),
      amount: Yup.number().required(),
      payment_method: Yup.string().required(),
      sender_name: Yup.string().when('type_transaction', {
        is: 'PAYMENT',
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      sender_rekening: Yup.string().when('type_transaction', {
        is: 'PAYMENT',
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      sender_bank: Yup.number().when('type_transaction', {
        is: 'PAYMENT',
        then: Yup.number().required(),
        otherwise: Yup.string(),
      }),
      receipent_account: Yup.number().when('type_transaction', {
        is: 'PAYMENT',
        then: Yup.number().required(),
        otherwise: Yup.number(),
      }),
      receipent_name: Yup.string().when('type_transaction', {
        is: "REFUND",
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      receipent_rekening: Yup.string().when('type_transaction', {
        is: "REFUND",
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      receipent_bank: Yup.number().when('type_transaction', {
        is: "REFUND",
        then: Yup.number().required(),
        otherwise: Yup.number(),
      }),
      sender_account: Yup.number().when('type_transaction', {
        is: "REFUND",
        then: Yup.number().required(),
        otherwise: Yup.number(),
      }),
      status_transaction: Yup.string().required(),
      proof_url: Yup.string(),
      note: Yup.string(),
      currency: Yup.string().required(),
    })
  });

  const [currency, setCurrency] = useState([
    {
      value: "IDR",
      label: "IDR",
    },
    {
      value: "USD",
      label: "USD",
    },
  ]);

  const handleGetbankOptions = () => {
    dispatch(handleGetIndonesianBanks());
  }

  const handleSelectSenderBank = (bank) => {
    formik.setFieldValue('sender_bank', bank.id)
    closeDropdown()
    // setTimeout(() => {
    //   formik.setTouched('sender_bank', 1)
    // })
  }


  const handleSelectReceipentBank = (bank) => {
    formik.setFieldValue('receipent_bank', bank.id)
    closeDropdown()
    // setTimeout(() => {
    //   formik.setTouched('receipent_bank', 1)
    // })
  }

  const handleSelectReceipentAccount = (rekening) => {
    formik.setFieldValue('receipent_account', rekening.id_rekening)
    closeDropdown()
    // setTimeout(() => {
    //   formik.setF('receipent_account', 1)
    // })
  }

  const handleSelectSenderAccount = (rekening) => {
    formik.setFieldValue('sender_account', rekening.id_rekening)
    closeDropdown()
    // setTimeout(() => {
    //   formik.setTouched('sender_account', 1)
    // })
  }


  const handleSave = async () => { 
    setIsLoading(true) 
    const response = await handleStorePaymentAPI(params.orderNumber, formik.values)
    setIsLoading(false)

    if(response.status === 200) {
      dispatch(handleSuccess(response))
      dispatch(handleGetDetailOrder(params.orderNumber))
      formik.resetForm()
      onChange()
    } else {
      dispatch(handleError(response.data))
    }

  };


  useEffect(() => {
    dispatch(
      getDataRekening({
        page_number: 1,
        per_page: 10,
        sort_column: "bank_name",
        sort_order: "ASC",
      })
    );
    handleGetbankOptions()
    // formik.setFieldTouched('type_transaction', 1)
  }, []);

  useEffect(() => {

  }, [dataRekening])

  useEffect(() => {
    if(bookingDetail?.payment_status === 4 || bookingDetail?.payment_status === 3) {
      formik.setFieldValue('type_transaction', 'REFUND')
    }
  }, [bookingDetail])

  useEffect(() => {
    if(formik.values.verification_status === 1) {
      formik.setFieldValue('status_transaction', 'success')

      // setTimeout(() => {
      //   formik.setTouched('status_transaction', 1)
      // })

    } else {
      formik.setFieldValue('status_transaction', 'pending')

      // setTimeout(() => {
      //   formik.setTouched('status_transaction', 1)
      // })

    }
  }, [formik.values.verification_status])


  return (
    <>
      <div className="border rounded-t-2xl p-4">
        <Scroll
          height="calc(100vh - 315px)"
          customClass="!mr-[-16px] pr-4"
          >
          <Segment
            label="Tipe Transaksi"
            containerClass="mb-4"
            customClass="mt-1"
            value={formik.values.type_transaction}
            disabled={bookingDetail?.payment_status === 4 || bookingDetail?.payment_status === 3}
            options={[
              {
                label: <div>Payment</div>,
                value: "PAYMENT",
              },
              {
                label: <div>Refund</div>,
                value: "REFUND",
              },
            ]}
            onChange={(values) => {
              if(bookingDetail?.payment_status === 4) {
                formik.setFieldValue("type_transaction", "REFUND")
              } else {
                formik.setFieldValue("type_transaction", values)
              }
            }}
          />

          {formik.values.type_transaction === "PAYMENT" && (
            <>
              <div className="mt-4">
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <Inputs
                      id="sender_name"
                      name="sender_name"
                      label="Akun Pengirim"
                      placeholder="Masukan Nama Akun Pengirim"
                      onChange={formik.handleChange}
                      value={formik.values.sender_name}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Inputs
                      id="sender_rekening"
                      name="sender_rekening"
                      label="Nomer Rekening"
                      placeholder="Masukan Nomor rekening penerima"
                      onChange={formik.handleChange}
                      value={formik.values.sender_rekening}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <div className="font-semibold text-sm">Pilih Bank</div>
                    <Dropdowns
                      customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
                      labelDropdown={
                        <>
                          <div className="line-clamp-1">
                            {formik.values.sender_bank !== "" ? (
                              <div>{indonesianBanks?.find((item) => item.id === formik.values.sender_bank)?.name}</div>
                            ) : (
                              "Pilih Bank"
                            )}
                          </div> 
                        </>
                      }
                      dropdownArrow={true}
                      children={
                        <>
                          <div className="bg-white w-full shadow-custom-1 rounded-2xl overflow-hidden">
                            <Scroll
                              height="350px"
                            >
                              {indonesianBanks.length && indonesianBanks.map((item, idx) => {
                                return (
                                  <>
                                    <div 
                                    onClick={() => handleSelectSenderBank(item)}
                                    className="p-4 hover:bg-[#FFDCDC] cursor-pointer">
                                      {item.name}
                                    </div>
                                  </>
                                )
                              })}
                            </Scroll>
                          </div>
                        </>
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            </>
          )}
          {formik.values.type_transaction === "REFUND" && (
            <>
              <div className="mt-4">
                <Grid item md={12}>
                  <div className="font-semibold text-sm">Akun Pengirim</div>
                  <Dropdowns 
                    customClass={`bg-white p-4 shadow-soft rounded-xl flex justify-between items-center`}
                    dropdownArrow={true}
                    labelDropdown={
                      <>
                        <div className="flex flex-col gap-1">
                          {formik.values.sender_account !== null ? (
                            <>
                              <span>{dataRekening?.find((item) => item.id_rekening === formik.values.sender_account)?.account_owner}</span>
                              <span className="orange-linear-text">
                                {dataRekening?.find((item) => item.id_rekening === formik.values.sender_account)?.bank_name} - {dataRekening?.find((item) => item.id_rekening === formik.values.sender_account)?.account_number}
                              </span>
                            </>
                          ) : (
                            <>
                              <span>Pilih Akun Pengirim</span>
                              <span className="orange-linear-text">-</span>
                            </>
                          )}
                        </div>
                      </>            
                    }
                    children={
                      <>
                        <div className="bg-white w-full shadow-custom-1 rounded-2xl overflow-hidden">
                          <Scroll
                            height="350px"
                            >
                              {dataRekening.length && dataRekening.map((item, idx) => {
                                return (
                                  <>
                                    <div 
                                      onClick={() => handleSelectSenderAccount(item)}
                                      className="p-4 hover:bg-[#FFDCDC] cursor-pointer flex flex-col gap-1">
                                      <div>{item.account_owner}</div>
                                      <div className="orange-linear-text">
                                        <div>{item.bank_name} - {item.account_number}</div>
                                      </div>
                                    </div>
                                  </>
                                )
                              })}
                            </Scroll>
                        </div>
                      </>
                    }
                    />
                </Grid>
              </div>
            </>
          )}

          <div className="mt-4">
            <Grid container spacing={2}>
              <Grid item md={3}>
                <Selects 
                  label="Mata Uang" 
                  disabled={bookingDetail?.paket?.tipe_paket != 1 && bookingDetail?.currency !== "USD"}
                  options={currency} 
                  values={[currency.find(x => x.value === formik.values.currency)]}
                  onChange={(currencySelected) => {
                    formik.setFieldValue('currency', currencySelected[0].value)
                  }}
                  />
              </Grid>
              <Grid item md={9}>
                <InputsCurrency
                  id="amount"
                  name="amount"
                  label="Nominal"
                  currency={formik.values.currency}
                  onChange={(val) => {
                    formik.setFieldValue('amount', val)

                    // setTimeout(() => {
                    //   formik.setTouched('amount', 1)
                    // })
                  }}
                  value={formik.values.amount}
                />
              </Grid>
            </Grid>
          </div>

          {formik.values.type_transaction === "PAYMENT" && (
            <>
              <Segment
                label="Kode Unik"
                containerClass="mt-4"
                customClass="mt-1"
                value={formik.values.with_unique_code}
                options={[
                  {
                    label: <div>Dengan Kode Unik</div>,
                    value: 1,
                  },
                  {
                    label: <div className="segment-label-error">Tanpa Kode Unik</div>,
                    value: 0,
                  },
                ]}
                onChange={(value) => {
                  formik.setFieldValue("with_unique_code", value)

                  // setTimeout(() => {
                  //   formik.setFieldTouched("payment_method", 1);
                  // })
                }}
              />
            </>
          )}

          {formik.values.type_transaction === "REFUND" && (
            <>
              <div className="mt-4">
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <Inputs
                      id="receipent_name"
                      name="receipent_name"
                      label="Akun Penerima"
                      placeholder="Masukan Nama Akun penerima"
                      onChange={formik.handleChange}
                      value={formik.values.receipent_name}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Inputs
                      id="receipent_rekening"
                      name="receipent_rekening"
                      label="Nomer Rekening"
                      placeholder="Masukan Nomor rekening penerima"
                      onChange={formik.handleChange}
                      value={formik.values.receipent_rekening}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <div className="font-semibold text-sm">Pilih Bank</div>
                    <Dropdowns
                      customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
                      labelDropdown={
                        <>
                          <div className="line-clamp-1">
                            {formik.values.receipent_bank !== null ? (
                              <div>{indonesianBanks?.find((item) => item.id === formik.values.receipent_bank)?.name}</div>
                            ) : (
                              "Pilih Bank"
                            )}
                          </div> 
                        </>
                      }
                      dropdownArrow={true}
                      children={
                        <>
                          <div className="bg-white w-full shadow-custom-1 rounded-2xl overflow-hidden">
                            <Scroll
                              height="350px"
                            >
                              {indonesianBanks.length && indonesianBanks.map((item, idx) => {
                                return (
                                  <>
                                    <div 
                                    onClick={() => handleSelectReceipentBank(item)}
                                    className="p-4 hover:bg-[#FFDCDC] cursor-pointer">
                                      {item.name}
                                    </div>
                                  </>
                                )
                              })}
                            </Scroll>
                          </div>
                        </>
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            </>
          )}
          {formik.values.type_transaction === "PAYMENT" && (
            <>
              <div className="mt-4">
                <Grid item md={12}>
                  <div className="font-semibold text-sm">Akun Penerima</div>
                  <Dropdowns 
                    customClass={`bg-white p-4 shadow-soft rounded-xl flex justify-between items-center`}
                    dropdownArrow={true}
                    labelDropdown={
                      <>
                        <div className="flex flex-col gap-1">
                          {formik.values.receipent_account !== null ? (
                            <>
                              <span>{dataRekening.length && dataRekening?.find((item) => item.id_rekening === formik.values.receipent_account)?.account_owner}</span>
                              <span className="orange-linear-text">
                                {dataRekening.length && dataRekening?.find((item) => item.id_rekening === formik.values.receipent_account)?.bank_name} - {dataRekening?.find((item) => item.id_rekening === formik.values.receipent_account)?.account_number}
                              </span>
                            </>
                          ) : (
                            <>
                              <span>Pilih Akun Penerima</span>
                              <span className="orange-linear-text">-</span>
                            </>
                          )}
                        </div>
                      </>            
                    }
                    children={
                      <>
                        <div className="bg-white w-full shadow-custom-1 rounded-2xl overflow-hidden">
                          <Scroll
                            height="350px"
                            >
                              {dataRekening.length && dataRekening.map((item, idx) => {
                                return (
                                  <>
                                    <div 
                                      onClick={() => handleSelectReceipentAccount(item)}
                                      className="p-4 hover:bg-[#FFDCDC] cursor-pointer flex flex-col gap-1">
                                      <div>{item.account_owner}</div>
                                      <div className="orange-linear-text">
                                        <div>{item.bank_name} - {item.account_number}</div>
                                      </div>
                                    </div>
                                  </>
                                )
                              })}
                            </Scroll>
                        </div>
                      </>
                    }
                    />
                </Grid>
              </div>
            </>
          )}

          <div className="flex gap-4">
            <div className="w-1/2">
              <h2 className="font-semibold mt-4 mb-2">Tanggal</h2>
              <DatePicker
                containerClass=""
                customClass="w-full"
                placeholder="Tanggal"
                value={dayjs(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}
                onChange={(val) => {
                  formik.setFieldValue("date", moment(val, "DD-MM-YYYY").format("YYYY-MM-DD"));
                  //   formik.setFieldTouched("date", 1);
                  // })

                }}
              />
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold mt-4 mb-2">Waktu</h2>
              <TimePickers
                containerClass=""
                customClass="w-full"
                placeholder="Tentukan Waktu"
                value={dayjs(moment().format("HH:mm"), "HH:mm")}
                onChange={(val) => {
                  formik.setFieldValue('time', moment(val.$d).format("HH:mm"))

                  // setTimeout(() => {
                  //   formik.setFieldTouched("time", 1);
                  // })

                }}
              />
            </div>
          </div>

          {/* <Segment
            label="Status Verifikasi"
            containerClass="mt-4"
            customClass="mt-1"
            value={formik.values.verification_status}
            options={[
              {
                label: <div>Terverifikasi</div>,
                value: 1,
              },
              {
                label: (
                  <div className="segment-label-error">Belum Terverifikasi</div>
                ),
                value: 0,
              },
            ]}
            onChange={(value) => formik.setFieldValue("verification_status", value)}
          /> */}

          <Segment
            label="Status Transaksi"
            containerClass="mt-4"
            customClass="mt-1"
            value={formik.values.status_transaction}
            options={
              formik.values.verification_status === 1
                ? [
                    {
                      label: <div>Sukses</div>,
                      value: "success",
                    },
                  ]
                : [
                    {
                      label: <div className="segment-label-yellow">Pending</div>,
                      value: "pending",
                    },
                    {
                      label: <div className="segment-label-error">Dibatalkan</div>,
                      value: "cancel",
                    },
                  ]
            }
            onChange={(value) => {
              formik.setFieldValue("status_transaction", value)

              // setTimeout(() => {
              //   formik.setFieldTouched("status_transaction", 1);
              // })

            }}
          />

          {formik.values.type_transaction === "PAYMENT" && (
            <>
              <Segment
                label="Diverifikasi Oleh"
                containerClass="mt-4"
                customClass="mt-1"
                value={formik.values.payment_method}
                options={[
                  {
                    label: <div>Manual</div>,
                    value: 1,
                  },
                  {
                    label: <div className="segment-label-blue">Sistem</div>,
                    value: 2,
                  },
                ]}
                onChange={(value) => {
                  formik.setFieldValue("payment_method", value)

                  // setTimeout(() => {
                  //   formik.setFieldTouched("payment_method", 1);
                  // })
                }}
              />
            </>
          )}

          <div className="mt-4">
            <UploadArea 
              label="Upload Struk"
              onChange={(val) => {
                formik.setFieldValue("proof_url", val)

                // setTimeout(() => {
                //   formik.setFieldTouched("proof_url", 1);
                // })

              }}
              />
          </div>
          <Textareas
            id="note"
            name="note"
            value={formik.values.note}
            placeholder="Masukan catatan"
            label="Catatan"
            containerClass="mt-4"
            onChange={formik.handleChange}
          />
        </Scroll>
      </div>
      <div className="border rounded-b-2xl border-t-0 p-4 flex justify-end">
        <Buttons
          text="Simpan"
          onClick={handleSave}
          // disabled={!(formik.dirty && !formik.isValid)}
          // containerClass="mt-6"
          customClass="btn-primary btn-large"
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default AddTransaction;
