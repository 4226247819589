import React from "react";
import PropTypes from "prop-types";
import { Select, theme } from "antd";
import SelectStyle from "./Select.Style";

import ArrowDown from "assets/icons/Down-Arrow.svg";
import Close from "assets/icons/Close.svg";
import Check from "assets/icons/Check.svg";
import Secondary from "./custom-type/Secondary";
import Badge from "./custom-type/Badge";
import BadgeCheck from "./custom-type/BadgeCheck";

const AntdSelect = ({
  containerClass,
  customClass,
  id,
  label,
  placeholder,
  icon,
  iconPosition,
  options,
  children,
  disabled,
  dropdownRender,
  success,
  error,
  type,
  onChange,
  defaultValue,
  onSearch,
  showSearch,
  value,
  theme,
  size,
  allowClear,
  ...props
}) => {
  const { Option } = Select
  return (
    <SelectStyle className={`${containerClass}`}>
      {label !== "" && (
        <div className={`select-label ${disabled && "select-label--disabled"}`}>
          {label}
        </div>
      )}
      <div className="flex flex-row relative">
        {icon && iconPosition === "left" && (
          <img src={icon} className="ic-left" alt="icon-left" />
        )}
          <Select
            labelInValue
            id={id}
            suffixIcon={
              <img src={ArrowDown} className="ic-right" alt="ic-right" />
            }
            className={`custom-select theme-${theme} ${
              icon !== "" && "select-padding--left"
            } size-${size} ${customClass}`}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            options={type ? undefined : options}
            onSearch={onSearch}
            showSearch={showSearch}
            allowClear={allowClear}
            defaultValue={defaultValue}
            {...(value !== undefined && { value: value })}
            filterOption={(input, option) => {
              const label = option?.label ?? "";
          
              // Ubah input dan label menjadi string untuk dibandingkan
              const inputStr = String(input).toLowerCase();
              const labelStr = String(label).toLowerCase();
          
              return labelStr.includes(inputStr);
            }}
            getPopupContainer={() => document.getElementById(`area-select`)}
          >
            
            {type === 'secondary' && options.map((item) => (
              <Option key={item.value} value={item.value} label={item.label} className="select-custom-option-secondary">
                <Secondary option={item} />
              </Option>
            ))}

            {type === 'badge' && options.map((item) => (
              <>
                <Option value={item.value} label={item.label} className="select-custom-option-badge">
                  <Badge option={item} value={value} />
                </Option>
              </>
            ))}

          {type === 'badge-check' && options.map((item) => (
              <>
                <Option value={item.value} label={item.label} className="select-custom-option-badge">
                  <BadgeCheck option={item} value={value} />
                </Option>
              </>
            ))}

            {type === 'custom' && (
              children
            )}

          </Select>
      </div>
      {success !== "" && (
        <div className="flex flex-row items-center">
          <img src={Check} alt="check" />
          <div className="select-success-msg">{success}</div>
        </div>
      )}
      {error !== "" && (
        <div className="flex flex-row items-center">
          <img src={Close} alt="close" />
          <div className="select-error-msg">{error}</div>
        </div>
      )}
      <div id="area-select" className="area-select"></div>
    </SelectStyle>
  );
};

AntdSelect.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.any,
  iconPosition: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  success: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  defaultValue: PropTypes.string,
  theme: PropTypes.string,
  allowClear: PropTypes.bool
};

AntdSelect.defaultProps = {
  containerClass: "",
  customClass: "",
  id: "",
  label: "",
  placeholder: "placeholder",
  icon: "",
  iconPosition: "left",
  options: [],
  disabled: false,
  success: "",
  error: "",
  onChange: () => {},
  onSearch: () => {},
  type: null,
  defaultValue: null,
  showSearch: false,
  value: undefined,
  theme: 'default', // default, light-border
  size: 'default',
  allowClear: false
};

export default React.memo(AntdSelect);